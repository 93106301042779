import React, { useEffect, useMemo, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  SubTitle,
  ContentWrapper,
  Paper,
  SettingsColumn,
  SettingsRow,
} from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import Button from '@components/Button';
import routes from '@routes';
import Select from '@components/Select';
import { editorFields } from '@containers/Users/constants';
import Checkbox from '@containers/ArticleSources/components/Checkbox';
import useBreakpoint from '@root/hooks/useBreakpoint';
import JSONInput from '@components/JSONInput';

const emptyUser = {
  email: '',
  first_name: '',
  last_name: '',
  title: '',
  company: '',
  password: '',
  password_confirmation: '',
  deactivated: false,
  kind: 'external',
  send_wraps: true,
  all_email_notifications_enabled: true,
  all_push_notifications_enabled: true,
  all_slack_notifications_enabled: false,
  links_in_content_publications_available: true,
  slack_webhook: '',
  dashboard_settings: {
    type: 'corporate',
    enabled: true,
  },
};

export const SelectContainer = styled.div`
  width: 500px;
`;

const UsersEditor = ({
  usersStore: {
    user,
    fetchUser,
    isLoading,
    validationErrors,
    setUser,
    updateUser,
    clearUser,
    companiesForUser,
  },
}) => {
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const shouldCreate = search.create === 'true';
  const cancelRoute = shouldCreate
    ? routes.users
    : `${routes.user}?id=${search.id}`;

  const fields = useMemo(
    () => editorFields(companiesForUser),
    [companiesForUser],
  );

  useEffect(() => {
    if (search.id) {
      fetchUser(search.id);
    } else {
      setUser(emptyUser);
    }
    return () => clearUser();
  }, []);

  useEffect(() => {
    if(shouldCreate) {
      if(user?.email?.includes('@teneo.com')) {
        user.kind = 'agency';
      } else if (!user.kind) {
          user.kind = 'external';
      }
    }
  }, [user.email]);

  if (isLoading) {
    return <Preloader overlay />;
  }

  const showField = (onlyCreate, onlyEdit) => {
    if (onlyCreate !== undefined) {
      return shouldCreate === onlyCreate;
    }
    if (onlyEdit === true && shouldCreate) {
      return false;
    }
    return true;
  };

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>User: {user.id}</Text>
      </SubTitle>
      <Paper>
        {fields.map(
          (
            {
              id,
              label,
              component,
              type,
              checkboxes,
              options,
              onlyCreate,
              onlyEdit,
            },
            index,
          ) =>
            showField(onlyCreate, onlyEdit) && (
              <Fragment key={`edit-field-${id || index}`}>
                {component === 'input' && (
                  <SettingsRow>
                    <Input
                      error={validationErrors[id]}
                      maxWidth={500}
                      value={user[id] || ''}
                      onChange={(value) => setUser({ [id]: value })}
                      label={label}
                      placeholder={`${label}...`}
                      inputType={type}
                    />
                  </SettingsRow>
                )}
                {component === 'select' && (
                  <SettingsRow>
                    <SelectContainer>
                      <Select
                        label={label}
                        placeholder={`${label}...`}
                        onChange={(value) => setUser({ [id]: value })}
                        value={user[id]}
                        options={options}
                      />
                    </SelectContainer>
                  </SettingsRow>
                )}
                {component === 'checkboxes' && isMobile && (
                  <SettingsColumn>
                    {checkboxes.map((checkbox) => (
                      <Checkbox
                        key={checkbox.id}
                        articleSource={user}
                        setArticleSource={setUser}
                        id={checkbox.id}
                        label={checkbox.label}
                      />
                    ))}
                  </SettingsColumn>
                )}
                {component === 'checkboxes' && !isMobile && (
                  <SettingsRow>
                    {checkboxes.map((checkbox) => (
                      <Checkbox
                        key={checkbox.id}
                        articleSource={user}
                        setArticleSource={setUser}
                        id={checkbox.id}
                        label={checkbox.label}
                      />
                    ))}
                  </SettingsRow>
                )}
                {component === 'json' && !isMobile && (
                  <JSONInput
                    label={label}
                    value={user[id]}
                    onChange={(value) => setUser({ [id]: value })}
                  />
                )}
              </Fragment>
            ),
        )}
      </Paper>
      <SettingsRow>
        <Button onClick={() => updateUser(shouldCreate)}>Save</Button>
        <Button onClick={() => navigate(cancelRoute)}>Cancel</Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('usersStore')(observer(UsersEditor));
