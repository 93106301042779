import styled from '@emotion/styled';

const ErrorMessage = styled.div`
  background-color: transparent;
  color: red;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  margin-left: auto;
  text-transform: none;

  &.left {
    margin-left: 0;
    margin-right: auto;
  }
`;

export default ErrorMessage;
