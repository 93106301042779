import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  ArrowBarToDown,
  ArrowBarToUp,
  ChevronDown,
  ChevronUp,
  X,
} from 'tabler-icons-react';
import Dialog from '@components/Modal/Dialog';
import {
  Header,
  ListContainer,
  NewspaperDialogContainer,
  ScrollableContent,
  SelectContainer,
} from '@containers/NewspapersDialog/style';
import Select from '@components/Select';
import { CheckListItem, Title } from '@components/CheckList/style';
import { SectionActions } from '@containers/Wraps/style';
import Button from '@components/Button';
import Text, { TAGS } from '@components/Text';

const NewspapersDialog = ({
  isOpen,
  closeHandler,
  wrapElement,
  wrapsStore: {
    wrapElements,
    setWrapElementPriorityNewspapers,
    newspapers,
    movePriorityNewspapers,
    copyNewspapers,
  },
}) => {
  const [wrapElementIdToCopy, setWrapElementIdToCopy] = useState(null);

  setWrapElementPriorityNewspapers = (wrapElementToChange, newspaperIds) => {
    wrapElementToChange.priorityNewspapers = newspaperIds;
  };

  movePriorityNewspapers = (priorityNewspapers, oldIndex, newIndex) => {
    if (newIndex >= 0) {
      const sectionsToMove = priorityNewspapers[oldIndex];
      priorityNewspapers.splice(oldIndex, 1);
      priorityNewspapers.splice(newIndex, 0, sectionsToMove);
    }
  };

  useEffect(() => {
    setWrapElementIdToCopy(null);
  }, []);

  const priorityIds = useMemo(
    () => wrapElement.priorityNewspapers.map(({ value }) => value),
    [wrapElement.priorityNewspapers],
  );
  const sortedNewspapers = newspapers
    .slice()
    .sort(
      ({ value: a }, { value: b }) =>
        priorityIds.indexOf(b) - priorityIds.indexOf(a),
    );

  const wrapElementsForSelect = wrapElements
    .filter(({ id, header }) => id !== wrapElement.id && header !== '')
    .map(({ id, header }) => ({ value: id, label: header }));

  return (
    <Dialog closeHandler={closeHandler} isOpen={isOpen}>
      <NewspaperDialogContainer>
        <Text tag={TAGS.H2}>Wrap element: {wrapElement.header}</Text>
        <Header className="first">
          <SelectContainer>
            <Select
              isMulti
              placeholder="Newspapers"
              options={sortedNewspapers}
              onChange={(values) =>
                setWrapElementPriorityNewspapers(wrapElement, values)
              }
              value={wrapElement.priorityNewspapers}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
            />
          </SelectContainer>
          <Button onClick={closeHandler} classes="right">
            Done
          </Button>
        </Header>
        <Header>
          <SelectContainer>
            <Select
              placeholder="Copy from another element"
              options={wrapElementsForSelect}
              onChange={setWrapElementIdToCopy}
              value={wrapElementIdToCopy}
            />
          </SelectContainer>
          <Button
            onClick={() => copyNewspapers(wrapElementIdToCopy, wrapElement)}
            classes="right"
            disabled={!wrapElementIdToCopy}
          >
            Copy
          </Button>
        </Header>
        <ScrollableContent
          autoHide
          autoHeight
          autoHeightMin={300}
          autoHeightMax="100%"
        >
          <ListContainer className="scrollable">
            {wrapElement.priorityNewspapers.map(({ value, label }, index) => (
              <CheckListItem key={value}>
                <Title>{label}</Title>
                <SectionActions>
                  <ChevronUp
                    aria-label="up"
                    onClick={() =>
                      movePriorityNewspapers(
                        wrapElement.priorityNewspapers,
                        index,
                        index - 1,
                      )
                    }
                  />
                  <ChevronDown
                    aria-label="down"
                    onClick={() =>
                      movePriorityNewspapers(
                        wrapElement.priorityNewspapers,
                        index,
                        index + 1,
                      )
                    }
                  />
                  <ArrowBarToUp
                    aria-label="top"
                    onClick={() =>
                      movePriorityNewspapers(
                        wrapElement.priorityNewspapers,
                        index,
                        0,
                      )
                    }
                  />
                  <ArrowBarToDown
                    aria-label="last"
                    onClick={() =>
                      movePriorityNewspapers(
                        wrapElement.priorityNewspapers,
                        index,
                        wrapElement.priorityNewspapers.length - 1,
                      )
                    }
                  />
                  <X
                    aria-label="up"
                    onClick={() =>
                      setWrapElementPriorityNewspapers(
                        wrapElement,
                        wrapElement.priorityNewspapers.filter(
                          ({ value: id }) => id !== value,
                        ),
                      )
                    }
                  />
                </SectionActions>
              </CheckListItem>
            ))}
          </ListContainer>
        </ScrollableContent>
      </NewspaperDialogContainer>
    </Dialog>
  );
};
export default inject('wrapsStore')(observer(NewspapersDialog));
