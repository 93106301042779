import React from 'react';
import { Menu2 } from 'tabler-icons-react';
import { TopBarIcon } from '@components/SideBar/styles';

const OpenCloseMenuMobile = ({ setMenuOpen, menuOpen }) => {
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    if (!window.history.state.menu) {
      window.history.pushState({ menu: !menuOpen }, '', '');
    }
  };

  if (menuOpen) {
    window.onpopstate = (event) => {
      if (event) {
        setMenuOpen(false);
      }
    };
  }

  if (window?.history?.state?.menu && !menuOpen) {
    window.history.back();
  }

  return (
    <TopBarIcon onClick={() => handleMenuOpen()}>
      <Menu2 />
    </TopBarIcon>
  );
};

export default OpenCloseMenuMobile;
