import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { SubTitle, ContentWrapper } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import routes from '@routes';
import Button from '@components/Button';
import { detailsFields } from '@containers/NlaOrganisations/constants';

const NlaOrganisation = ({
  nlaOrganisationsStore: {
    nlaOrganisation,
    fetchNlaOrganisation,
    isLoading,
    clearNlaOrganisation,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const navigate = useNavigate();
  const { search } = useCustomSearchParams();

  useEffect(() => {
    if (search.id) {
      fetchNlaOrganisation(search.id);
    }
    return () => clearNlaOrganisation();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>NLA organisation: {nlaOrganisation.id}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={nlaOrganisation} fields={detailsFields} big />
      ) : (
        <Details data={nlaOrganisation} fields={detailsFields} />
      )}
      <Button
        onClick={() => navigate(`${routes.nlaOrganisationEditor}?id=${search.id}`)}
      >
        Edit
      </Button>
    </ContentWrapper>
  );
};

export default inject('nlaOrganisationsStore')(observer(NlaOrganisation));
