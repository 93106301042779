export default {
  signIn: '/auth/sign_in',
  profile: 'terra_control/analysts/analyst',
  articleSources: {
    index: '/terra_control/article_sources.json',
    show: (id) => `/terra_control/article_sources/${id}.json`,
    availableLanguages:
      '/terra_control/article_sources/available_languages.json',
    availableLicensors:
      '/terra_control/article_sources/available_licensors.json',
  },
  switchArticleSources: `terra_control/switch_sources/make_a_switch.json`,
  switchASJobStatus: (id) =>
    `terra_control/switch_sources/check_job?delayed_job_id=${id}`,
  wrapRoutes: {
    recipients: 'terra_control/content_orders/users_in_content_order.json',
    companiesSections:
      'terra_control/content_orders/section_containers_in_content_order.json',
    wraps: 'terra_control/wraps.json',
    wrap: (id) => `terra_control/wraps/${id}.json`,
    wrapToggle: (id) => `terra_control/wraps/${id}/toggle_enabled.json`,
  },
  companies: {
    all: 'terra_control/companies/all_companies',
    sectors: 'terra_control/companies/sectors',
    companyTypes: 'terra_control/companies/company_types',
    company: 'terra_control/companies.json',
    companyUpdate: (id) => `terra_control/companies/${id}.json`,
  },
  articleSourceRequests: {
    requests: (id) =>
      id
        ? `/terra_control/article_source_requests/${id}.json`
        : '/terra_control/article_source_requests.json',
    newspapers: '/terra_control/newspapers.json',
    comply: (id) =>
      `/terra_control/article_source_requests/${id}/comply_request.json`,
  },
  subscriptions: {
    index: '/terra_control/contracts.json',
    show: (id) =>  `/terra_control/contracts/${id}.json`,
    removeUser: (id) =>  `/terra_control/contracts/${id}/remove_user.json`
  },
  subscriptionStates: '/terra_control/contracts/states.json',
  subscriptionPlans: '/terra_control/subscription_plans.json',
  admins: '/terra_control/admins.json',
  contentOrder: (id) => `/terra_control/content_orders/${id}.json`,
  contentOrders: '/terra_control/content_orders',
  contentOrdersAddUsers: (id) =>
    `/terra_control/content_orders/${id}/create_user_invitation_request.json`,
  cloneContentOrderArticleSources: `/terra_control/content_orders/clone_article_sources.json`,
  bulkAddSourcesToContentOrder: (id) =>
    `/terra_control/content_orders/${id}/bulk_add_sources.json`,
  briefingTypes: (id) =>
    id
      ? `/terra_control/briefing_types/${id}.json`
      : '/terra_control/briefing_types.json',
  briefingTypeFollow: '/terra_control/briefing_types/follow_by_all_users.json',
  briefingTypeEnableNotifications:
    '/terra_control/briefing_types/enable_content_container_notifications.json',
  delayedJobs: '/terra_control/delayed_jobs.json',
  company_relations: {
    relationTypes: '/terra_control/company_relations/relation_types.json',
    relation: (id) => `/terra_control/company_relations/${id}.json`,
    relations: '/terra_control/company_relations.json',
  },
  toolMessages: {
    index: 'terra_control/tool_messages.json',
    record: (id) => `/terra_control/tool_messages/${id}.json`,
  },
  skippedAuthorTerms: {
    index: 'terra_control/skipped_author_terms.json',
    record: (id) => `/terra_control/skipped_author_terms/${id}.json`,
  },
  users: {
    index: 'terra_control/users.json',
    record: (id) => `/terra_control/users/${id}.json`,
    impersonate: (id) => `terra_control/users/${id}/impersonate.json`,
  },
  userSettings: {
    index: 'terra_control/user_settings.json',
    bulkUpdate: 'terra_control/user_settings/bulk_update.json',
  },
  contractsForSelect: '/terra_control/contracts/for_select.json',
  userInvitations: {
    index: 'terra_control/user_invitations.json',
    record: (id) => `terra_control/user_invitations/${id}.json`,
    resendInvitation: (id) => `terra_control/user_invitations/${id}/resend_invitation.json`,
    resendWelcomeEmail: (id) => `terra_control/user_invitations/${id}/resend_welcome_email.json`,
    sendResetPasswordEmail: (id) => `terra_control/user_invitations/${id}/send_reset_password_email.json`,
  },
  nlaOrganisations: {
    index: 'terra_control/nla_organisations.json',
    record: (id) => `/terra_control/nla_organisations/${id}.json`,
  }
};
