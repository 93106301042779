import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import routes from '@routes';
import {
  filters as filtersFunc,
  tableColumns,
} from '@containers/UserSettings/constants';
import Button from '@components/Button';
import { ButtonContainer } from '@containers/Wraps/style';

const UserSettings = ({
  userSettingsStore: {
    fetchUserSettings,
    userSettings,
    isLoading,
    userSettingsCount,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const filters = filtersFunc();

  useEffect(() => {
    clear();
    fetchUserSettings();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchUserSettings({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchUserSettings({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery({});
    await fetchUserSettings({
      limit: rowsPerPage,
      page: 0,
      searchQuery: {},
    });
  };

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <FiltersContainer>
        <ButtonContainer>
          <Button onClick={() => navigate(routes.userSettingsUpdate)}>
            Update settings
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      <Table
        big
        rowsPerPageOptions={[10, 50, 100]}
        count={userSettingsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={userSettings}
      />
    </ContentWrapper>
  );
};

export default inject('userSettingsStore')(observer(UserSettings));
