import React from 'react';
import PropTypes from 'prop-types';
import PreloaderIcon from '@assets/images/icons/preloader.svg';
import HorizontalPreloader from '@assets/images/icons/horizontal-preloader.svg';

// eslint-disable-next-line import/no-cycle
import { Wrapper } from '@components/Preloader/style';

export const POSITIONS = {
  VERTICAL: 'vertical',
  HORIZONRAL: 'horizontal',
};

export const SIZES = {
  SMALL: 'small',
  NORMAL: 'normal',
};

const Preloader = ({ display, size, overlay }) => (
  <Wrapper className="preloader" size={size} overlay={overlay}>
    {display === POSITIONS.VERTICAL ? (
      <img src={PreloaderIcon} alt="loading" />
    ) : (
      <img src={HorizontalPreloader} alt="loading-vertical" />
    )}
  </Wrapper>
);

Preloader.propTypes = {
  display: PropTypes.oneOf(Object.values(POSITIONS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  overlay: PropTypes.bool,
};

Preloader.defaultProps = {
  display: POSITIONS.VERTICAL,
  size: SIZES.NORMAL,
  overlay: false,
};

export default Preloader;
