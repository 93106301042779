import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

export class CompaniesStore {
  companies = [];

  companiesToCreate = [];

  errors = [];

  updateFormErrors = {};

  sectors = [];

  companyTypes = [];

  companiesCount = 0;

  serverError = '';

  isLoading = false;

  isLoadingSectors = false;

  isLoadingCompanyTypes = false;

  companyToEdit = {};

  constructor() {
    makeAutoObservable(this);
  }

  setCompaniesToCreate = (companies) => {
    this.companiesToCreate = companies;
  };

  fetchCompanies = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;
    this.companies = [];

    const query = {
      limit: parseInt(limit ? limit.toString() : 10, 10),
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      company: searchQuery || '',
    };

    try {
      const response = await API.get(apiRoutes.companies.all, {
        params: query,
      });
      runInAction(() => {
        this.companies = response.data.companies;
        this.companiesCount = response.data.companiesCount;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchSectors = async () => {
    this.isLoadingSectors = true;
    this.sectors = [];

    try {
      const response = await API.get(apiRoutes.companies.sectors);
      this.sectors = response.data.sectors;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingSectors = false;
    }
  };

  fetchCompanyTypes = async () => {
    this.isLoadingCompanyTypes = true;
    this.companyTypes = [];

    try {
      const response = await API.get(apiRoutes.companies.companyTypes);
      this.companyTypes = response.data.company_types;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingCompanyTypes = false;
    }
  };

  createCompanies = async (companies) => {
    if (this.validateForm(companies)) {
      this.isLoading = true;
      this.serverError = '';
      try {
        const response = await API.post(apiRoutes.companies.company, {
          companies,
        });
        if (response.data.errors) {
          this.serverError = response.data.errors.message;
          this.isLoading = false;
          toast.error('Something went wrong!');
        } else {
          routerStore.push(routes.companies);
          toast.success(
            'Companies are being created. Please refresh the company list in a few minutes.',
          );
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }
  };

  updateCompany = async (company) => {
    if (this.validateCompany(company)) {
      this.isLoading = true;
      try {
        const response = await API.patch(
          apiRoutes.companies.companyUpdate(company.id),
          {
            company,
          },
        );
        if (response.data.errors) {
          this.serverError = response.data.errors.message;
          this.isLoading = false;
          toast.error('Something went wrong!');
        } else {
          routerStore.push(routes.companies);
          toast.success('Company successfully updated!');
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }
  };

  setCompanyToEdit = (company) => {
    this.companyToEdit = company;
    routerStore.push(routes.companiesEditor);
  };

  setErrors = async (errors) => {
    this.errors = errors;
  };

  validateCompany = (company) => {
    this.updateFormErrors.name =
      company.name === null || company.name === ''
        ? 'Name should not be empty'
        : undefined;
    this.updateFormErrors.url =
      company.url === null || company.url === ''
        ? 'URL should not be empty'
        : undefined;
    this.updateFormErrors.logo =
      company.logo.name !== null && !company.logo.type.includes('image')
        ? 'Logo should be a image'
        : undefined;
    this.updateFormErrors.svgLogo =
      company.svgLogo.name !== null && company.svgLogo.type !== 'image/svg+xml'
        ? 'SVG logo should be a SVG image'
        : undefined;
    return !(
      this.updateFormErrors.name ||
      this.updateFormErrors.url ||
      this.updateFormErrors.logo ||
      this.updateFormErrors.svgLogo
    );
  };

  validateForm = (companies) => {
    const requiredFields = ['name', 'sectorId', 'companyTypeId'];
    let isValid = true;
    companies.forEach((company, index) => {
      requiredFields.forEach((field) => {
        if (company[field] === '') {
          this.errors[index][field] = true;
          isValid = false;
        } else {
          this.errors[index][field] = false;
        }
      });
    });
    return isValid;
  };
}

export default new CompaniesStore();
