export const tableColumns = [
  {
    id: 'id',
    label: 'ID',
    maxWidth: 70,
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'actions',
    label: 'Actions',
    maxWidth: 100,
    align: 'left',
  },
];