import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  SubTitle,
  ContentWrapper,
  Paper,
  SettingsRow,
} from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import Button from '@components/Button';
import routes from '@routes';
import Select from '@components/Select';
import { editorFields } from '@containers/SkippedAuthorTerms/constants';

export const SelectContainer = styled.div`
  width: 500px;
`;

const SkippedAuthorTermsEditor = ({
  skippedAuthorTermsStore: {
    skippedAuthorTerm,
    fetchSkippedAuthorTerm,
    isLoading,
    validationErrors,
    setSkippedAuthorTerm,
    updateSkippedAuthorTerm,
    clearSkippedAuthorTerm,
  },
}) => {
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();

  const shouldCreate = search.create === 'true';

  useEffect(() => {
    if (search.id) {
      fetchSkippedAuthorTerm(search.id);
    } else {
      setSkippedAuthorTerm({
        term: '',
        enabled: true,
      });
    }
    return () => clearSkippedAuthorTerm();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>SkippedAuthorTerm: {skippedAuthorTerm.id}</Text>
      </SubTitle>
      <Paper>
        {editorFields.map(({ id, label, component, type, options }) => (
          <Fragment key={`edit-field-${id}`}>
            {component === 'input' && (
              <SettingsRow>
                <Input
                  error={validationErrors[id]}
                  maxWidth={500}
                  value={skippedAuthorTerm[id] || ''}
                  onChange={(value) => setSkippedAuthorTerm({ [id]: value })}
                  label={label}
                  placeholder={`${label}...`}
                  inputType={type}
                />
              </SettingsRow>
            )}
            {component === 'select' && (
              <SettingsRow>
                <SelectContainer>
                  <Select
                    label={label}
                    placeholder={`${label}...`}
                    onChange={(value) => setSkippedAuthorTerm({ [id]: value })}
                    value={skippedAuthorTerm[id]}
                    options={options}
                  />
                </SelectContainer>
              </SettingsRow>
            )}
          </Fragment>
        ))}
      </Paper>
      <SettingsRow>
        <Button onClick={() => updateSkippedAuthorTerm(shouldCreate)}>
          Save
        </Button>
        <Button onClick={() => navigate(routes.skippedAuthorTerms)}>
          Cancel
        </Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('skippedAuthorTermsStore')(
  observer(SkippedAuthorTermsEditor),
);
