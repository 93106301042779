import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { SubTitle, ContentWrapper } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import routes from '@routes';
import Button from '@components/Button';
import { detailsFields } from '@containers/ArticleSources/constants';

const ArticleSource = ({
  articleSourcesStore: {
    articleSource,
    fetchArticleSource,
    isLoadingArticleSource,
    fetchAvailableLanguages,
    availableLanguagesObj,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const navigate = useNavigate();
  const { search } = useCustomSearchParams();

  useEffect(() => {
    fetchAvailableLanguages();
    if (search.id) {
      fetchArticleSource(search.id);
    }
  }, []);

  if (isLoadingArticleSource) {
    return <Preloader overlay />;
  }

  const fields = Object.keys(availableLanguagesObj).length
    ? detailsFields(availableLanguagesObj)
    : [];

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>{articleSource.title}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={articleSource} fields={fields} big />
      ) : (
        <Details data={articleSource} fields={fields} />
      )}
      <Button
        onClick={() =>
          navigate(`${routes.articleSourceEditor}?id=${search.id}`)
        }
      >
        Edit
      </Button>
    </ContentWrapper>
  );
};

export default inject('articleSourcesStore')(observer(ArticleSource));
