export const copyToClipboard = async (text) => {
  if (navigator.clipboard === undefined || !window.isSecureContext) {
    const oldSelection = window.getSelection();
    const oldRanges = [];
    for(let idx = 0; idx < oldSelection.rangeCount; idx+=1) {
      oldRanges.push(oldSelection.getRangeAt(idx));
    }
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style = { position: 'absolute', top: '-30000px', left: '-30000px', opacity: '0' };
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((resolve, reject) => {
      if(document.execCommand('copy')) {
        resolve();
      } else {
        reject();
      }
      textArea.remove();
      const selection = window.getSelection();
      selection.removeAllRanges();
      for(let idx = 0; idx < oldRanges.length; idx+=1) {
        selection.addRange(oldRanges[idx]);
      }
    });
  }
  return navigator.clipboard.writeText(text);
};
