import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  SubTitle,
  ContentWrapper,
  Paper,
  SettingsRow,
} from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import Button from '@components/Button';
import routes from '@routes';
import { editorFields } from "@containers/NlaOrganisations/constants";

export const SelectContainer = styled.div`
  width: 500px;
`;

const NlaOrganisationsEditor = ({
  nlaOrganisationsStore: {
    nlaOrganisation,
    fetchNlaOrganisation,
    isLoading,
    validationErrors,
    setNlaOrganisation,
    updateNlaOrganisation,
    clearNlaOrganisation,
  },
}) => {
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();

  const shouldCreate = search.create === 'true';
  const cancelRoute = shouldCreate
    ? routes.nlaOrganisations
    : `${routes.nlaOrganisation}?id=${search.id}`;

  useEffect(() => {
    if (search.id) {
      fetchNlaOrganisation(search.id);
    } else {
      setNlaOrganisation({
        name: '',
        content_container_ids: []
      });
    }

    return () => clearNlaOrganisation();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>NLA organisation: {nlaOrganisation.id}</Text>
      </SubTitle>
      <Paper>
        {editorFields.map(
          ({
            id,
            label,
            component,
            type,
          }) => (
            <Fragment key={`edit-field-${id}`}>
              {component === 'input' && (
                <SettingsRow>
                  <Input
                    error={validationErrors[id]}
                    maxWidth={500}
                    value={nlaOrganisation[id] || ''}
                    onChange={(value) => setNlaOrganisation({ [id]: value })}
                    label={label}
                    placeholder={`${label}...`}
                    inputType={type}
                  />
                </SettingsRow>
              )}
            </Fragment>
          ),
        )}
      </Paper>
      <SettingsRow>
        <Button onClick={() => updateNlaOrganisation(shouldCreate)}>Save</Button>
        <Button onClick={() => navigate(cancelRoute)}>Cancel</Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('nlaOrganisationsStore')(observer(NlaOrganisationsEditor));
