import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import {
  Container,
  ErrorMessage,
  InputIcon,
  Label,
  ICON_POSITION,
  StyledInput,
  THEMES,
} from '@components/Input/styles';

export { ICON_POSITION, THEMES };

export const INPUT_TYPES = {
  TEXT: 'text',
  EMAIL: 'email',
  NUMBER: 'number',
  PASSWORD: 'password',
  FILE: 'file',
};

function Input({
  label,
  inputType,
  onChange,
  value,
  name,
  error,
  textArea,
  placeholder,
  onFocus,
  onBlur,
  min,
  onKeyDown,
  icon,
  iconPosition,
  noMargin,
  maxWidth,
  inputTheme,
  inputRef,
  readOnly,
}) {
  const inputId = useMemo(
    () => `input_${name}-${inputType}`,
    [name, inputType],
  );
  const isTextArea = textArea ? 'textarea' : 'input';

  return (
    <Container
      noMargin={noMargin}
      maxWidth={maxWidth}
      className={`input-container input-container--${inputType}`}
    >
      {label ? <Label className="custom-input-label">{label}</Label> : null}
      {icon ? <InputIcon placement={iconPosition}>{icon}</InputIcon> : null}
      <StyledInput
        ref={inputRef}
        autoComplete="off"
        {...(min ? { min } : {})}
        className={`input input--${inputType}${error ? ' invalid' : ''}`}
        id={inputId}
        type={inputType}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onChange={(e) =>
          inputType === INPUT_TYPES.FILE
            ? onChange(e.target.files[0])
            : onChange(e.target.value)
        }
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        error={error && error.message && error.message !== ''}
        as={isTextArea}
        noLabel={!label}
        iconPosition={icon && iconPosition}
        inputTheme={inputTheme}
        readOnly={readOnly}
      />
      {error && <ErrorMessage>{error.message || error}</ErrorMessage>}
    </Container>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  inputType: PropTypes.oneOf(Object.values(INPUT_TYPES)),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  error: PropTypes.object,
  autoComplete: PropTypes.bool,
  textArea: PropTypes.bool,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  min: PropTypes.number,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(Object.values(ICON_POSITION)),
  noMargin: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputTheme: PropTypes.oneOf(Object.values(THEMES)),
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  error: undefined,
  autoComplete: null,
  textArea: false,
  placeholder: '',
  onBlur: null,
  onFocus: null,
  min: null,
  label: null,
  iconPosition: ICON_POSITION.LEFT,
  noMargin: false,
  icon: null,
  inputType: INPUT_TYPES.TEXT,
  maxWidth: 'unset',
  inputTheme: THEMES.SECONDARY,
  name: '',
  readOnly: false,
};

export default Input;
