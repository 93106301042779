import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

export class SkippedAuthorTermsStore {
  isLoading = false;

  skippedAuthorTerms = [];

  skippedAuthorTermsCount = 0;

  skippedAuthorTerm = {};

  validationErrors = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchSkippedAuthorTerms = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.skippedAuthorTerms.index, {
        params: query,
      });
      runInAction(() => {
        this.skippedAuthorTerms = response.data.skipped_author_terms;
        this.skippedAuthorTermsCount = response.data.skipped_author_terms_count;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchSkippedAuthorTerm = async (id) => {
    this.isLoading = true;
    try {
      const response = await API.get(apiRoutes.skippedAuthorTerms.record(id));
      runInAction(() => {
        this.skippedAuthorTerm = response.data;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  setSkippedAuthorTerm = (valueObj) => {
    this.skippedAuthorTerm = { ...this.skippedAuthorTerm, ...valueObj };
  };

  validateSkippedAuthorTerm = () => {
    this.validationErrors.term =
      this.skippedAuthorTerm.term === null ||
      this.skippedAuthorTerm.term.length === 0
        ? 'Description should not be empty'
        : undefined;
  };

  updateSkippedAuthorTerm = async (create) => {
    this.isLoading = true;
    this.validateSkippedAuthorTerm();
    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }
    let response = null;
    try {
      if (create) {
        response = await API.post(apiRoutes.skippedAuthorTerms.index, {
          skipped_author_term: this.skippedAuthorTerm,
        });
      } else {
        response = await API.patch(
          apiRoutes.skippedAuthorTerms.record(this.skippedAuthorTerm.id),
          {
            skipped_author_term: this.skippedAuthorTerm,
          },
        );
      }

      if (response.data.message) {
        routerStore.push(routes.skippedAuthorTerms);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.error);
      }
    } catch (e) {
      this.error = e;
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }

    this.isLoading = false;
  };

  removeSkippedAuthorTerm = async (id) => {
    try {
      await API.delete(apiRoutes.skippedAuthorTerms.record(id));
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      await this.fetchSkippedAuthorTerms({});
    }
  };

  clearSkippedAuthorTerm = () => {
    this.skippedAuthorTerm = {};
  };
}

export default new SkippedAuthorTermsStore();
