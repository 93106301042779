import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import {
  ContentWrapper,
  StyledTooltip,
  FiltersContainer,
} from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Button from '@components/Button';
import { ButtonContainer } from '@containers/Wraps/style';
import routes from '@routes';
import ConfirmationDialog from '@components/ConfirmationDialog';
import {
  actions,
  columnsFunc,
} from '@containers/ArticleSourceRequests/constants';

const ArticleSourceRequests = ({
  articleSourceRequestsStore: {
    fetchArticleSourceRequests,
    articleSourceRequests,
    articleSourceRequestsCount,
    isLoading,
    editArticleSourceRequest,
    clearArticleSourceRequestToEdit,
    removeArticleSourceRequest,
    createArticleSource,
    extractMeta,
    fetchNewspapers,
    newspapersObject,
    rowsPerPage,
    setRowsPerPage,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [rowIdToRemove, setRowIdToRemove] = useState(null);
  const navigate = useNavigate();

  const columnActions = {
    title: (row) => editArticleSourceRequest(row),
  };

  const clear = async () => {
    setPage(0);
    await fetchArticleSourceRequests({
      limit: rowsPerPage,
      page: 0,
    });
  };

  const removeArticleSourceRequestWithDialog = (rowId) => {
    setIsConfirmationDialogOpen(true);
    setRowIdToRemove(rowId);
  };

  const tableActions = useMemo(
    () =>
      actions(removeArticleSourceRequestWithDialog, createArticleSource, clear),
    [],
  );

  useEffect(() => {
    clear();
    clearArticleSourceRequestToEdit();
    fetchNewspapers();
  }, []);

  useEffect(() => {}, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchArticleSourceRequests({ limit: rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchArticleSourceRequests({
      limit: value,
      page: 0,
    });
  };

  const columns = columnsFunc(newspapersObject, extractMeta);

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <ConfirmationDialog
        onConfirmation={(rowId) => {
          removeArticleSourceRequest(rowId);
          setIsConfirmationDialogOpen(false);
          clear();
        }}
        isDialogOpen={isConfirmationDialogOpen}
        setIsDialogOpen={setIsConfirmationDialogOpen}
        rowId={rowIdToRemove}
        dialogText="Delete this item?"
      />
      <StyledTooltip />
      <FiltersContainer>
        <ButtonContainer>
          <Button onClick={() => navigate(routes.articleSourceRequestCreator)}>
            Create article source request
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      <Table
        big
        rowsPerPageOptions={[10, 50, 100]}
        count={articleSourceRequestsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={columns}
        data={articleSourceRequests}
        actions={tableActions}
        columnActions={columnActions}
      />
    </ContentWrapper>
  );
};

export default inject('articleSourceRequestsStore')(
  observer(ArticleSourceRequests),
);
