import React from 'react';
import { Trash } from 'tabler-icons-react';
import { Color, CompanyImage } from '@containers/Companies/style';

export const bulkCreateColumns = (sectors, companyTypes) => [
  {
    id: 'name',
    label: 'Name',
    type: 'input',
  },
  {
    id: 'url',
    label: 'URL',
    type: 'input',
  },
  {
    id: 'color',
    label: 'Colour',
    type: 'color',
  },
  {
    id: 'ticker',
    label: 'Ticker',
    type: 'input',
  },
  {
    id: 'companyTypeId',
    label: 'Company type',
    type: 'select',
    selectOptions: companyTypes,
  },
  {
    id: 'sectorId',
    label: 'Sector',
    type: 'select',
    selectOptions: sectors,
  },
  {
    id: 'actions',
    label: '',
    maxWidth: 50,
    format: (value) => value.toString(),
  },
];

export const bulkCreateActions = (remove) => [
  {
    icon: () => <Trash strokeWidth={1} size={24} />,
    onClick: (index) => remove(index),
  },
];

export const createColumns = (sectors, companyTypes) => [
  {
    id: 'name',
    label: 'Name',
    type: 'input',
  },
  {
    id: 'url',
    label: 'URL',
    type: 'input',
  },
  {
    id: 'color',
    label: 'Colour',
    type: 'color',
  },
  {
    id: 'ticker',
    label: 'Ticker',
    type: 'input',
  },
  {
    id: 'companyTypeId',
    label: 'Company type',
    type: 'select',
    selectOptions: companyTypes,
  },
  {
    id: 'sectorId',
    label: 'Sector',
    type: 'select',
    selectOptions: sectors,
  },
];

export const emptyCompanyFields = {
  name: '',
  url: '',
  color: '#000000',
  ticker: '',
  companyTypeId: '',
  sectorId: '',
};

export const emptyCompanyErrors = {
  name: null,
  sectorId: null,
  companyTypeId: null,
  ticker: null,
  url: null,
};

export const listColumns = (darkMode) => [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'name',
    label: 'Name',
    maxWidth: 250,
  },
  {
    id: 'meta.website_url',
    minWidth: 100,
    label: 'URL',
  },
  {
    id: 'meta.color',
    label: 'Colour',
    maxWidth: 130,
    format: (value) => <Color color={value}>{value}</Color>,
  },
  {
    id: 'meta.icon',
    label: 'Logo',
    maxWidth: 230,
    format: (value) => (
      <CompanyImage darkMode={darkMode} alt="logo" src={value} />
    ),
  },
  {
    id: 'meta.svg_icon',
    label: 'Logo SVG',
    maxWidth: 230,
    format: (value) => (
      <CompanyImage darkMode={darkMode} alt="logo" src={value} />
    ),
    hide: true,
  },
];
