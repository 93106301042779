import React, { useState } from 'react';
import Select from '@components/Select';
import {
  Chevron,
  CollapsibleSelectContainer,
  SelectContainer,
} from '@components/CollapsibleSelect/style';

const CollapsibleSelect = ({ options, label, value, onChange, error, closeMenuOnSelect=true, hideSelectedOptions=true, isLoading=false }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <CollapsibleSelectContainer>
      <SelectContainer>
        <Select
          hideSelectedOptions={hideSelectedOptions}
          closeMenuOnSelect={closeMenuOnSelect}
          isMulti
          error={error}
          collapsed={isCollapsed}
          label={label}
          options={options}
          onChange={onChange}
          value={value}
          isLoading={isLoading}
        />
      </SelectContainer>
      <Chevron
        isOpened={isCollapsed}
        onClick={() => setIsCollapsed(!isCollapsed)}
      />
    </CollapsibleSelectContainer>
  );
};

export default CollapsibleSelect;
