import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import { Provider as Rollbar, ErrorBoundary } from '@rollbar/react';
import { BrowserRouter, history } from '@app/history';

import App from '@root/App';
import * as stores from '@stores';
import * as serviceWorker from '@root/serviceWorker';
import { KEYS } from '@utils/cache';

const email = localStorage.getItem(KEYS.EMAIL) || 'anonymous';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  payload: {
    environment: process.env.REACT_APP_ENV,
    person: {
      email,
    },
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Rollbar config={rollbarConfig}>
    <ErrorBoundary>
      <Provider {...stores}>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </Rollbar>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
