import styled from '@emotion/styled';

export const JsonContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  overflow: scroll;
  padding: 10px;
  border-radius: 3px;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.inputLabel};
  margin-bottom: 10px;
`;
