import styled from '@emotion/styled';

export const SelectContainer = styled.div`
  min-width: 150px;
  height: 65px;

  &.big {
    min-width: 300px;
  }
`;

export const UserItem = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;
