import React from 'react';
import { CheckBoxContainer } from '@containers/Wraps/style';
import Control from '@components/Control';

const Checkbox = ({ label, id, articleSource, setArticleSource }) => (
  <CheckBoxContainer>
    <Control
      name={id}
      type="checkbox"
      label={label}
      value={articleSource[id]}
      checked={articleSource[id]}
      onChange={(item) => setArticleSource({ [id]: item.target.checked })}
    />
  </CheckBoxContainer>
);

export default Checkbox;
