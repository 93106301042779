import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import {
  SubTitle,
  ContentWrapper,
  Paper,
  SettingsColumn,
  SettingsRow,
} from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import Button from '@components/Button';
import routes from '@routes';
import { updateFields } from '@containers/UserSettings/constants';
import Checkbox from '@containers/ArticleSources/components/Checkbox';
import useBreakpoint from '@root/hooks/useBreakpoint';

const emptyUserSettings = {
  content_container_id: '',
  content_order_id: '',
  follow: false,
  email_notification: false,
  push_notification: false,
  slack_notification: false,
};

const UserSettingsUpdate = ({
  userSettingsStore: {
    userSetting,
    isLoading,
    validationErrors,
    setUserSetting,
    updateUserSetting,
    clearUserSetting,
  },
}) => {
  const navigate = useNavigate();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const fields = updateFields();

  useEffect(() => {
    setUserSetting(emptyUserSettings);
    return () => clearUserSetting();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>User settings</Text>
      </SubTitle>
      <Paper>
        {fields.map(({ id, label, component, type, checkboxes }, index) => (
          <Fragment key={`field-${id || index}`}>
            {component === 'input' && (
              <SettingsRow>
                <Input
                  error={validationErrors[id]}
                  maxWidth={500}
                  value={userSetting[id] || ''}
                  onChange={(value) => setUserSetting({ [id]: value })}
                  label={label}
                  placeholder={`${label}...`}
                  inputType={type}
                />
              </SettingsRow>
            )}
            {component === 'checkboxes' && isMobile && (
              <SettingsColumn>
                {checkboxes.map((checkbox) => (
                  <Checkbox
                    key={checkbox.id}
                    articleSource={userSetting}
                    setArticleSource={setUserSetting}
                    id={checkbox.id}
                    label={checkbox.label}
                  />
                ))}
              </SettingsColumn>
            )}
            {component === 'checkboxes' && !isMobile && (
              <SettingsRow>
                {checkboxes.map((checkbox) => (
                  <Checkbox
                    key={checkbox.id}
                    articleSource={userSetting}
                    setArticleSource={setUserSetting}
                    id={checkbox.id}
                    label={checkbox.label}
                  />
                ))}
              </SettingsRow>
            )}
          </Fragment>
        ))}
      </Paper>
      <SettingsRow>
        <Button onClick={() => updateUserSetting()}>Save</Button>
        <Button onClick={() => navigate(routes.userSettings)}>Cancel</Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('userSettingsStore')(observer(UserSettingsUpdate));
