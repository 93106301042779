import React from 'react';
import { get } from 'lodash';
import { Field, Label, Value } from '@components/DetailsMobile/styles';
import { Paper } from '@styles/globalStyles';

const formatLabel = (label) => `${label.split('_').join(' ')}:`;

const DetailsMobile = ({ fields, data, big }) => (
  <Paper noShadow>
    {fields &&
      data &&
      fields.map(({ id, format, label }) => {
        const value = get(data, id) || '(none)';
        return (
          <Field key={id} big={big}>
            <Label>{label || formatLabel(id)}</Label>
            <Value>
              {format && value !== '(none)' ? format(value) : value}
            </Value>
          </Field>
        );
      })}
  </Paper>
);

export default DetailsMobile;
