import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Controller, useForm } from 'react-hook-form';
import Switch from '@components/Switch';
import Wrapper from '@components/DarkModeSwitch/styles';

const DarkModeSwitch = ({
  authStore: { darkMode, handleDarkMode },
  offColor,
}) => {
  const { control, handleSubmit, setValue } = useForm();
  const theme = useTheme();

  useEffect(() => {
    setValue('dark-mode-switch', darkMode);
  }, []);

  return (
    <Wrapper>
      <Controller
        name="dark-mode-switch"
        defaultValue={darkMode}
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <Switch
            onChange={onChange}
            name={name}
            value={value}
            offColor={offColor}
            onHandleColor={theme.colors.secondary}
            offHandleColor={theme.colors.secondary}
            onColor={theme.colors.sidebar}
            checkId={() => handleDarkMode(!value)}
            handleSubmit={handleSubmit}
            noGap
          />
        )}
      />
    </Wrapper>
  );
};

DarkModeSwitch.propTypes = {
  offColor: PropTypes.string,
};

DarkModeSwitch.defaultProps = {
  offColor: '#29235C',
};

export default inject('authStore')(observer(DarkModeSwitch));
