import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Bell, BellOff, Trash } from 'tabler-icons-react';
import { useNavigate } from 'react-router';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Input from '@components/Input';
import Select from '@components/Select';
import Button from '@components/Button';
import Table from '@components/Table';
import useDebounce from '@root/hooks/useDebounce';
import Preloader from '@components/Preloader';
import routes from '@routes';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import { TableTitle } from '@containers/ContentOrders/style';
import ConfirmationDialog from '@components/ConfirmationDialog';
import { ButtonContainer, SelectContainerIndex } from '@containers/Wraps/style';

const columns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 50,
    align: 'left',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    id: 'contract_name',
    label: 'Subscription',
    align: 'left',
  },
  {
    id: 'enabled',
    label: 'Enabled',
    maxWidth: 80,
    align: 'center',
    format: (value) =>
      value ? (
        <Bell color="green" strokeWidth={1} size={24} />
      ) : (
        <BellOff color="red" strokeWidth={1} size={24} />
      ),
  },
  {
    id: 'actions',
    label: 'Actions',
    maxWidth: 100,
    align: 'left',
    format: (value) => value.toString(),
  },
];

const actions = (remove, changeEnabled, clear) => [
  {
    icon: () => <Trash strokeWidth={1} size={24} />,
    onClick: (row) => {
      remove(row.id);
    },
  },
  {
    icon: ({ enabled }) =>
      enabled ? (
        <BellOff strokeWidth={1} size={24} />
      ) : (
        <Bell strokeWidth={1} size={24} />
      ),
    onClick: (row) => {
      clear();
      changeEnabled(row);
    },
  },
];

const enabledSelectOptions = [
  { label: 'True', value: true },
  { label: 'False', value: false },
  { label: 'All', value: 'all' },
];

const Wraps = ({
  wrapsStore: {
    fetchWraps,
    wraps,
    isLoading,
    wrapsLength,
    removeWrap,
    changeWrapEnabled,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [page, setPage] = useState(0);
  const [enabled, setEnabled] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [contractSearchQuery, setContractSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [rowIdToRemove, setRowIdToRemove] = useState(null);
  const navigate = useNavigate();
  const { search } = useCustomSearchParams();

  const columnActions = {
    id: (row) => navigate(`${routes.wrapsEditor}?wrapId=${row.id}`),
  };

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchWraps({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
      enabled,
      contractSearchQuery,
      id: search.contentOrderId,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchWraps({
      limit: value,
      page: 0,
      searchQuery,
      enabled,
      contractSearchQuery,
      id: search.contentOrderId,
    });
  };

  const clear = async () => {
    if (search.contentOrderId) {
      navigate(routes.wraps);
    }
    setSearchQuery('');
    setPage(0);
    setContractSearchQuery('');
    setEnabled('all');
    await fetchWraps({
      limit: rowsPerPage,
      page: 0,
      searchQuery: '',
      enabled: 'all',
      contractSearchQuery: '',
    });
  };

  const removeWrapWithDialog = (rowId) => {
    setIsConfirmationDialogOpen(true);
    setRowIdToRemove(rowId);
  };

  const tableActions = useMemo(
    () => actions(removeWrapWithDialog, changeWrapEnabled, clear),
    [],
  );

  useDebounce(searchQuery, 300, () => {
    handleChangePage(0);
  });

  useDebounce(contractSearchQuery, 300, () => {
    handleChangePage(0);
  });

  useEffect(() => {
    handleChangePage(0);
  }, [enabled, search.contentOrderId]);

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <ConfirmationDialog
        onConfirmation={(rowId) => {
          removeWrap(rowId);
          setIsConfirmationDialogOpen(false);
          clear();
        }}
        isDialogOpen={isConfirmationDialogOpen}
        setIsDialogOpen={setIsConfirmationDialogOpen}
        rowId={rowIdToRemove}
        dialogText="Delete this item?"
      />
      <FiltersContainer>
        <Input
          onChange={setSearchQuery}
          value={searchQuery}
          maxWidth={250}
          label="Name"
          placeholder="Name..."
        />
        <Input
          onChange={setContractSearchQuery}
          value={contractSearchQuery}
          maxWidth={250}
          label={isMobile ? 'Subscription' : 'Subscription name'}
          placeholder="Active subscription name..."
        />
        <SelectContainerIndex>
          <Select
            label="Enabled"
            placeholder="Enabled"
            onChange={setEnabled}
            value={enabled}
            options={enabledSelectOptions}
          />
        </SelectContainerIndex>
        <ButtonContainer>
          <Button classes="no-min" onClick={clear}>
            {isMobile ? <Trash strokeWidth={1} size={24} /> : 'Clear'}
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      {search.contentOrderId && (
        <TableTitle>
          Showing results for content order: {search.contentOrderId}
        </TableTitle>
      )}
      <Table
        columnActions={columnActions}
        rowsPerPageOptions={[10, 50, 100]}
        count={wrapsLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={columns}
        data={wraps}
        actions={tableActions}
      />
    </ContentWrapper>
  );
};

export default inject('wrapsStore')(observer(Wraps));
