const defaultTheme = {
  defaults: {
    fontFamily: { primary: 'Nunito' },
    fontWeight: {
      light: 300,
      regular: 400,
      bold: 700,
    },
    fontSizeXXL: 22,
    fontSizeXL: 16,
    fontSize: 14,
    mediumFontSize: 12,
    smallFontSize: 10,
    desktopMenuCollapsedWidth: 96,
    desktopMenuWidth: 230,
    smallDesktopMenuWidth: 220,
    mobileMenuHeight: 56,
    menuItemHeight: 56,
  },
  defaultsBig: {
    fontFamily: { primary: 'Nunito' },
    fontWeight: {
      light: 300,
      regular: 400,
      bold: 700,
    },
    fontSizeXXL: 24,
    fontSizeXL: 18,
    fontSize: 16,
    mediumFontSize: 14,
    smallFontSize: 12,
    desktopMenuCollapsedWidth: 96,
    desktopMenuWidth: 280,
    smallDesktopMenuWidth: 220,
    mobileMenuHeight: 56,
    menuItemHeight: 72,
  },
};

export default defaultTheme;
