import React from 'react';
import { get } from 'lodash';
import { Field, Label, Value } from '@components/DetailsTable/styles';
import { Paper } from '@styles/globalStyles';

const formatLabel = (label) => `${label.split('_').join(' ')}:`;

const DetailsTable = ({ fields, data, actions, big }) => (
  <Paper noShadow>
    {fields &&
      data &&
      fields.map(({ id, format, label }) => {
        const value =
          get(data, id) !== null && get(data, id) !== undefined
            ? get(data, id)
            : '(none)';
        const action = get(actions, id);
        return (
          <Field
            clickable={!!action || undefined}
            key={id}
            big={big}
            onClick={() => (action ? action(data) : null)}
          >
            <Label>{label || formatLabel(id)}</Label>
            <Value>
              {format && value !== '(none)' ? format(value) : value}
            </Value>
          </Field>
        );
      })}
  </Paper>
);

export default DetailsTable;
