export const boolSelect = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'term',
    label: 'Term',
  },
  {
    id: 'enabled',
    label: 'Enabled',
    format: (value) => value.toString(),
  },
  {
    label: 'Actions',
    id: 'actions',
    maxWidth: 80,
  },
];

export const editorFields = [
  {
    id: 'term',
    label: 'Term',
    component: 'input',
    type: 'text',
  },
  {
    id: 'enabled',
    label: 'Enabled',
    component: 'select',
    options: boolSelect,
  },
  {
    startDateId: 'valid_from',
    endDateId: 'valid_to',
    showTime: true,
    label: 'Valid',
    component: 'dateRange',
  },
];

export const filters = [
  { id: 'term', label: 'Term', type: 'input' },
  {
    id: 'enabled',
    label: 'Enabled',
    type: 'select',
    options: boolSelect,
    isClearable: true,
  },
];
