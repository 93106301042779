const routes = {
  auth: {
    logout: '/',
  },
  wraps: '/wraps',
  wrapsEditor: '/wrapsEditor',
  companies: '/companies',
  companiesCreator: '/companiesCreator',
  articleSourceRequests: '/articleSourceRequests',
  articleSourceRequestCreator: '/articleSourceRequestCreator',
  companiesEditor: '/companiesEditor',
  subscriptions: '/subscriptions',
  subscription: '/subscription',
  contentOrder: '/contentOrder',
  contentOrderEditor: '/contentOrderEditor',
  briefingTypes: '/briefingTypes',
  briefingType: '/briefingType',
  briefingTypeCreator: '/briefingTypeCreator',
  delayedJobs: '/delayedJobs',
  companyCreator: '/companyCreator',
  switchArticleSources: '/switchArticleSources',
  companyRelations: '/companyRelations',
  companyRelationsEditor: '/companyRelationEditor',
  articleSources: '/articleSources',
  articleSource: '/articleSource',
  articleSourceEditor: '/articleSourceEditor',
  toolMessages: '/toolMessages',
  toolMessage: '/toolMessage',
  toolMessageEditor: '/toolMessageEditor',
  skippedAuthorTerms: '/skippedAuthorTerms',
  skippedAuthorTermsEditor: '/skippedAuthorTermsEditor',
  users: '/users',
  user: '/user',
  userEditor: '/userEditor',
  userSettings: '/userSettings',
  userSettingsUpdate: '/userSettingsUpdate',
  subscriptionUsers: '/subscriptionUsers',
  userInvitations: '/userInvitations',
  userInvitationCreator: '/userInvitationCreator',
  nlaOrganisations: '/nlaOrganisations',
  nlaOrganisation: '/nlaOrganisation',
  nlaOrganisationEditor: '/nlaOrganisationEditor',
  contentOrderAddUsers: '/contentOrderAddUsers',
};

export default routes;
