import React, {Fragment, useEffect, useMemo, useRef} from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from "@emotion/styled";
import {
  SubTitle,
  ContentWrapper,
  Paper,
  SettingsColumn,
  SettingsRow,
} from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import Button from '@components/Button';
import routes from '@routes';
import { addUsersEditorFieldsFunc } from '@containers/ContentOrders/constants';
import Checkbox from '@containers/ArticleSources/components/Checkbox';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Select from "@components/Select";

const emptyContentOrderUsers = {
  id: 0,
  content_container_id: 0,
  follow_for_companies: false,
  push_notifications_for_companies: false,
  email_notifications_for_companies: false,
  follow_for_briefing_types: false,
  push_notifications_for_briefing_types: false,
  email_notifications_for_briefing_types: false,
};
const formatLabel = (label) => `${label.split('_').join(' ')}`;

export const SelectContainer = styled.div`
  width: 500px;
`;

const ContentOrderAddUsers = ({
  contentOrdersStore: {
    isLoadingAddUsers,
    validationErrorsAddUsers,
    contentOrderUsers,
    setContentOrderUsers,
    usersFile,
    setUsersFile,
    createUsers,
    companies,
    fetchCompanies,
    isLoadingCompanies,
  },
}) => {
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const hiddenFileInputRef = useRef(null);

  const cancelRoute = `${routes.contentOrder}?id=${search.id}`;

  useEffect(() => {
    setUsersFile(null)
    if(!companies.length) {
      fetchCompanies();
    }
    if(search.id) {
      setContentOrderUsers({ ...emptyContentOrderUsers, id: search.id })
    } else {
      setContentOrderUsers(emptyContentOrderUsers)
    }
    return () => {
      setUsersFile(null)
      setContentOrderUsers(emptyContentOrderUsers)
    };
  }, [search.id]);

  const handleHiddenInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileInput = async (file) => {
    setUsersFile(file);
  };

  const fields = useMemo(() => addUsersEditorFieldsFunc(companies, isLoadingCompanies), [companies, isLoadingCompanies]);

  if (isLoadingAddUsers) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>Content order: {search.id}</Text>
      </SubTitle>
      <Paper>
        {fields.map(
          (
            {
              id,
              label,
              component,
              type,
              checkboxes,
              readOnly,
              options,
              isLoading,
            },
            index,
          ) =>
            <Fragment key={`edit-field-${id || index}`}>
                {component === 'input' && (
                  <SettingsRow>
                    <Input
                      error={validationErrorsAddUsers[id]}
                      maxWidth={500}
                      value={contentOrderUsers[id] || ''}
                      onChange={(value) => setContentOrderUsers({ [id]: value })}
                      label={label}
                      placeholder={`${label}...`}
                      inputType={type}
                      readOnly={readOnly}
                    />
                  </SettingsRow>
                )}
              {component === 'select' && (
                <SettingsRow>
                  <SelectContainer>
                    <Select
                      label={label}
                      placeholder={`${label}...`}
                      onChange={(value) => setContentOrderUsers({[id]: value})}
                      value={contentOrderUsers[id]}
                      options={options}
                      isLoading={isLoading}
                    />
                  </SelectContainer>
                </SettingsRow>
              )}
                {component === 'checkboxes' && isMobile && (
                  <SettingsColumn>
                    {checkboxes.map((checkbox) => (
                      <Checkbox
                        key={checkbox.id}
                        articleSource={contentOrderUsers}
                        setArticleSource={setContentOrderUsers}
                        id={checkbox.id}
                        label={checkbox.label || formatLabel(checkbox.id)}
                      />
                    ))}
                  </SettingsColumn>
                )}
                {component === 'checkboxes' && !isMobile && (
                  <SettingsRow>
                    {checkboxes.map((checkbox) => (
                      <Checkbox
                        key={checkbox.id}
                        articleSource={contentOrderUsers}
                        setArticleSource={setContentOrderUsers}
                        id={checkbox.id}
                        label={checkbox.label || formatLabel(checkbox.id)}
                      />
                    ))}
                  </SettingsRow>
                )}
                {component === 'fileInput' && (
                  <SettingsRow>
                    <Button onClick={() => handleHiddenInput(hiddenFileInputRef)}>
                      {label}
                      {usersFile ? `: ${usersFile.name}` : ''}
                    </Button>
                    <Input
                      inputRef={hiddenFileInputRef}
                      error={validationErrorsAddUsers[id]}
                      maxWidth={500}
                      onChange={handleFileInput}
                      placeholder={`${label}...`}
                      inputType={type}
                    />
                  </SettingsRow>
                )}
            </Fragment>
        )}
      </Paper>
      <SettingsRow>
        <Button onClick={createUsers}>Save</Button>
        <Button onClick={() => navigate(cancelRoute)}>Cancel</Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('contentOrdersStore')(observer(ContentOrderAddUsers));
