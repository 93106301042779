import React, { forwardRef, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Search } from 'tabler-icons-react';
import { useTheme } from '@emotion/react';
import { Draggable } from 'react-beautiful-dnd';
import { FixedSizeList } from 'react-window';
import { TrashIcon, ButtonContainer } from '@containers/Wraps/style';
import Input from '@components/Input';
import { Paper, SettingsRow } from '@styles/globalStyles';
import { FixedListContainer } from '@components/CheckList/style';
import WrapElementSection from '@containers/Wraps/components/WrapElementSection';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Button from '@components/Button';
import NewspapersDialog from '@containers/NewspapersDialog';

const WrapElement = forwardRef(
  (
    {
      wrapElement,
      index,
      wrapsStore: {
        removeWrapElement,
        changeWrapElementHeader,
        isSectionChanging,
        changeSectionChecked,
        moveSection,
      },
      sidebarStore: { menuCollapsed },
    },
    ref,
  ) => {
    const [search, setSearch] = useState('');
    const [header, setHeader] = useState(wrapElement.header);
    const [sections, setSections] = useState(wrapElement.sections);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();

    const isMobile = menuCollapsed
      ? matchBreakpoint(BREAKPOINTS.MD)
      : matchBreakpoint(BREAKPOINTS.XL);

    const filter = (allSections, searchParam) =>
      allSections.filter((section) =>
        section.name.toLowerCase().includes(searchParam.toLowerCase()),
      );

    useEffect(() => {
      if (search.trim() !== '') {
        setSections(filter(wrapElement.sections, search));
      } else {
        setSections(wrapElement.sections);
      }
    }, [search, wrapElement.sections]);

    const theme = useTheme();

    return (
      <div ref={ref}>
        <NewspapersDialog
          closeHandler={() => setIsDialogOpen(!isDialogOpen)}
          isOpen={isDialogOpen}
          wrapElement={wrapElement}
        />
        <Draggable draggableId={wrapElement.id} index={index}>
          {(p) => (
            <Paper
              ref={p.innerRef}
              {...p.draggableProps}
              {...p.dragHandleProps}
              style={{
                userSelect: 'none',
                ...p.draggableProps.style,
              }}
            >
              <SettingsRow>
                <Input
                  value={header}
                  onChange={(value) => {
                    setHeader(value);
                    changeWrapElementHeader(wrapElement, value);
                  }}
                  maxWidth={250}
                  label="Header"
                  placeholder="Header"
                />
                <TrashIcon
                  color={theme.font.default}
                  onClick={() => removeWrapElement(wrapElement.id)}
                />
                <ButtonContainer className="right">
                  <Button onClick={() => setIsDialogOpen(true)}>
                    Priority newspapers
                  </Button>
                </ButtonContainer>
              </SettingsRow>
              <SettingsRow>
                <Input
                  icon={<Search stroke={theme.colors.secondary} />}
                  value={search}
                  onChange={setSearch}
                  maxWidth={200}
                  label="Find companies"
                  placeholder="Find companies..."
                />
              </SettingsRow>
              {!isSectionChanging && sections.length > 0 && (
                <FixedListContainer>
                  <FixedSizeList
                    height={200}
                    itemSize={isMobile ? 60 : 40}
                    itemCount={sections.length}
                    overscanCount={5}
                    itemData={{
                      wrapElement,
                      changeSectionChecked,
                      moveSection,
                      sections,
                      isMobile,
                    }}
                  >
                    {WrapElementSection}
                  </FixedSizeList>
                </FixedListContainer>
              )}
            </Paper>
          )}
        </Draggable>
      </div>
    );
  },
);

export default inject('wrapsStore', 'sidebarStore')(observer(WrapElement));
