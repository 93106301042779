import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

const ProtectedRoute = inject('authStore')(
  observer(({ authStore, children }) => {
    if (authStore.isAuthenticated()) {
      return children;
    }
    window.location.href = `${process.env.REACT_APP_SSO_URL}/?origin=${window.location.origin}/wraps`;
    return null;
  }),
);

ProtectedRoute.propTyose = {
  authStore: PropTypes.shape({ isAuthenticated: PropTypes.bool }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ProtectedRoute;
