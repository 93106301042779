import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ContentWrapper, StyledTooltip } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Text, { TAGS } from '@components/Text';

const columns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => moment(value).format('LLL'),
    maxWidth: 130,
  },
  {
    id: 'handler',
    label: 'Handler',
    className: 'text-cut',
    minWidth: 300,
  },
  {
    id: 'last_error',
    label: 'Last error',
    format: (value) => (
      <div data-tip={value || '( no errors )'} data-place="bottom">
        {value ? value.substring(0, 100) : '( no errors )'}
      </div>
    ),
    maxWidth: 100,
  },
];

const DelayedJobs = ({
  delayedJobsStore: {
    fetchDelayedJobs,
    delayedJobs,
    isLoading,
    delayedJobsCount,
  },
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    clear();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchDelayedJobs({
      limit: rowsPerPage,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchDelayedJobs({
      limit: value,
      page: 0,
    });
  };

  const clear = async () => {
    await fetchDelayedJobs({
      limit: rowsPerPage,
      page: 0,
    });
  };

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      {delayedJobs.length > 0 ? null : <Text tag={TAGS.H2}>No data</Text>}
      <StyledTooltip />
      {delayedJobs.length !== 0 && (
        <Table
          big
          rowsPerPageOptions={[10, 50, 100]}
          count={delayedJobsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          columns={columns}
          data={delayedJobs}
          mobileBig
        />
      )}
    </ContentWrapper>
  );
};

export default inject('delayedJobsStore')(observer(DelayedJobs));
