import { makeAutoObservable, runInAction } from 'mobx';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

export class DelayedJobsStore {
  delayedJobs = [];

  delayedJobsCount = 0;

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchDelayedJobs = async ({ limit, page }) => {
    this.isLoading = true;
    this.delayedJobs = [];

    const query = {
      limit: parseInt(limit ? limit.toString() : 10, 10),
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
    };

    try {
      const response = await API.get(apiRoutes.delayedJobs, {
        params: query,
      });
      runInAction(() => {
        this.delayedJobs = response.data.delayedJobs;
        this.delayedJobsCount = response.data.delayedJobsCount;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export default new DelayedJobsStore();
