import React from 'react';
import DatePicker from 'react-datepicker';
import {
  DateRangeSelectorContainer,
  Label,
  Wrapper,
} from '@components/DateRangeSelector/styles';

const DateRangeSelector = ({
  label,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  showTimeSelect,
}) => (
  <DateRangeSelectorContainer>
    <Label>{label}</Label>
    <Wrapper showTime={showTimeSelect}>
      <DatePicker
        showTimeSelect={showTimeSelect}
        dateFormat={showTimeSelect ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
        placeholderText="From..."
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        showTimeSelect={showTimeSelect}
        dateFormat={showTimeSelect ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
        placeholderText="To..."
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </Wrapper>
  </DateRangeSelectorContainer>
);

export default DateRangeSelector;
