import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@emotion/react';
import Switch from '@components/Switch';
import Wrapper from '@components/DarkModeSwitch/styles';

const ChangeAppSize = ({ authStore: { appSize, handleAppSize } }) => {
  const isBig = appSize === 'big';
  const theme = useTheme();
  return (
    <Wrapper>
      <Switch
        offColor="#ffffff"
        onHandleColor={theme.colors.secondary}
        offHandleColor={theme.colors.secondary}
        onColor={theme.colors.sidebar}
        onChange={() => {
          if (isBig) {
            handleAppSize('small');
          } else {
            handleAppSize('big');
          }
        }}
        label="Large fonts"
        name="appSize"
        value={isBig}
      />
    </Wrapper>
  );
};

export default inject('authStore')(observer(ChangeAppSize));
