import React from 'react';
import moment from 'moment';
import { DialogItem } from '@components/TableDialog/styles';
import { boolSelect } from '@containers/ArticleSources/constants';

const userKind = [
  { label: 'Internal', value: 'internal' },
  { label: 'External', value: 'external' },
  { label: 'Agency', value: 'agency' },
]

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'first_name',
    label: 'First name',
    hide: true,
  },
  {
    id: 'last_name',
    label: 'Last name',
    hide: true,
  },
  {
    id: 'deactivated',
    label: 'Deactivated',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
  {
    id: 'kind',
    label: 'User type',
    hide: true,
  },
  {
    id: 'all_email_notifications_enabled',
    label: 'Email notifications',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
  {
    id: 'all_push_notifications_enabled',
    label: 'Push notifications',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
  {
    id: 'all_slack_notifications_enabled',
    label: 'Slack notifications',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => moment(value).format('LLL').toString(),
    hide: true,
  },
  {
    id: 'last_email_opened_at',
    label: 'Last email opened at',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
    hide: true,
  },
  {
    id: 'subscription_plan',
    label: 'Subscription plan',
    maxWidth: 200,
  },
  {
    id: 'active_subscription',
    label: 'Active subscription',
    maxWidth: 110,
    format: (values) =>
      values.length ? <DialogItem>Show ({values.length})</DialogItem> : 0,
  },
  {
    id: 'active_content_order',
    label: 'Active content order',
    maxWidth: 110,
    format: (values) =>
      values.length ? <DialogItem>Show ({values.length})</DialogItem> : 0,
  },
  {
    id: 'last_sign_in_at',
    label: 'Last sign in at',
    format: (value) => (value ? moment(value).format('LLL').toString() : '-'),
    hide: true,
  },
];

export const detailsFields = (companies) => [
  { id: 'email' },
  { id: 'first_name' },
  { id: 'last_name' },
  { id: 'title' },
  { id: 'company' },
  { id: 'kind', label: 'User type' },
  {
    id: 'primary_company_container_id',
    label: 'Primary company',
    format: (value) => companies[value],
  },
  {
    id: 'created_at',
    format: (value) => moment(value).format('LLL').toString(),
  },
  {
    id: 'last_sign_in_at',
    format: (value) => moment(value).format('LLL').toString(),
  },
  {
    id: 'last_email_opened_at',
    format: (value) => moment(value).format('LLL').toString(),
  },
  {
    id: 'all_email_notifications_enabled',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'all_push_notifications_enabled',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'all_slack_notifications_enabled',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'deactivated',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  { id: 'slack_webhook' },
  {
    id: 'links_in_content_publications_available',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'send_wraps',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'remove_briefcase_news_from_email_subject_for_coverages',
    format: (value) => (value === true ? 'Yes' : 'No'),
  },
  {
    id: 'dashboard_settings',
    format: (value) => JSON.stringify(value, null, 2),
  },
];

export const editorFields = (companiesForUser) => [
  {
    id: 'email',
    label: 'Email',
    component: 'input',
    type: 'text',
  },
  {
    id: 'password',
    label: 'Password',
    component: 'input',
    type: 'password',
    onlyCreate: true,
  },
  {
    id: 'password_confirmation',
    label: 'Password confirmation',
    component: 'input',
    type: 'password',
    onlyCreate: true,
  },
  {
    id: 'first_name',
    label: 'First name',
    component: 'input',
    type: 'text',
  },
  {
    id: 'last_name',
    label: 'Last name',
    component: 'input',
    type: 'text',
  },
  {
    id: 'title',
    label: 'Title',
    component: 'input',
    type: 'text',
  },
  {
    id: 'company',
    label: 'Company',
    component: 'input',
    type: 'text',
  },
  {
    id: 'slack_webhook',
    label: 'Slack webhook',
    component: 'input',
    type: 'text',
  },
  {
    id: 'primary_company_container_id',
    label: 'Primary company container',
    component: 'select',
    options: companiesForUser,
    onlyEdit: true,
  },
  {
    id: 'kind',
    label: 'User type',
    component: 'select',
    options: userKind,
  },
  {
    checkboxes: [
      { id: 'deactivated', label: 'Deactivated' },
      { id: 'send_wraps', label: 'Send wraps' },
      { id: 'remove_briefcase_news_from_email_subject_for_coverages', label: 'Remove Briefcase.news from email subject (for coverages)' },
      {
        id: 'links_in_content_publications_available',
        label: 'Links in content publications',
      },
    ],
    component: 'checkboxes',
  },
  {
    checkboxes: [
      {
        id: 'all_email_notifications_enabled',
        label: 'Email notifications',
      },
      {
        id: 'all_push_notifications_enabled',
        label: 'Push notifications',
      },
      {
        id: 'all_slack_notifications_enabled',
        label: 'Slack notifications',
      },
    ],
    component: 'checkboxes',
  },
  {
    id: 'dashboard_settings',
    label: 'Dashboard settings',
    component: 'json',
  },
];

export const filters = (
  contentOrders,
  isLoadingContentOrders,
  contracts,
  isLoadingContracts,
  subscriptionPlans,
  isLoadingSubscriptionPlans,
) => [
  {
    id: 'email',
    label: 'Email',
    type: 'input',
  },
  {
    id: 'first_name',
    label: 'First name',
    type: 'input',
  },
  {
    id: 'last_name',
    label: 'Last name',
    type: 'input',
  },
  {
    id: 'deactivated',
    label: 'Deactivated',
    options: boolSelect,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'is_initial_preferences_setup_visible',
    label: 'Is initial preferences setup',
    placeholder: 'Visible',
    options: boolSelect,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'all_email_notifications_enabled',
    label: 'Email notifications enabled',
    placeholder: 'Enabled',
    options: boolSelect,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'all_push_notifications_enabled',
    label: 'Push notifications enabled',
    placeholder: 'Enabled',
    options: boolSelect,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'all_slack_notifications_enabled',
    label: 'Slack notifications enabled',
    placeholder: 'Enabled',
    options: boolSelect,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'links_in_content_publications_available',
    label: 'Links in content publications',
    placeholder: 'Available',
    options: boolSelect,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'send_wraps',
    label: 'Send wraps',
    placeholder: 'Send',
    options: boolSelect,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'kind',
    label: 'Kind',
    options: userKind,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'subscription_plan_ids',
    label: 'Subscription plans',
    options: subscriptionPlans,
    isLoading: isLoadingSubscriptionPlans,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'contract_ids',
    label: 'Contracts',
    options: contracts,
    isLoading: isLoadingContracts,
    type: 'select',
    isClearable: true,
  },
  {
    id: 'content_order_id',
    label: 'Content orders',
    options: contentOrders,
    isLoading: isLoadingContentOrders,
    type: 'select',
    isClearable: true,
  },
  {
    startDateId: 'created_at_from',
    endDateId: 'created_at_to',
    label: 'Created at',
    type: 'dateRange',
  },
  {
    startDateId: 'current_sign_in_at_from',
    endDateId: 'current_sign_in_at_to',
    label: 'Current sign in at',
    type: 'dateRange',
  },
  {
    startDateId: 'last_email_opened_at_from',
    endDateId: 'last_email_opened_at_to',
    label: 'Last email opened at',
    type: 'dateRange',
  },
];
