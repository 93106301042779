import React, { Fragment, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
  ContentWrapper,
  OnlyMobilePaper,
  SettingsRow,
  StyledTooltip,
} from '@styles/globalStyles';
import Input from '@components/Input';
import Control from '@components/Control';
import Button from '@components/Button';
import ColorPicker from '@components/ColorPicker';
import { Label } from '@containers/Companies/style';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import { InfoCircleIcon } from '@containers/BriefingTypes/style';
import useBreakpoint from '@root/hooks/useBreakpoint';

const BriefingTypeCreator = ({
  briefingsStore: {
    validationErrors,
    newBriefingType,
    changeNewBriefingTypeField,
    save,
    briefingTypeFields,
    clear,
    fetchBriefingType,
  },
}) => {
  const { search } = useCustomSearchParams();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  useEffect(() => {
    if (search.id) {
      fetchBriefingType(search.id, true);
    }
    return clear();
  }, []);

  const followAndEnableCheckboxes = (
    <>
      <Control
        name="follow_by_all_users"
        value={newBriefingType.should_follow}
        checked={newBriefingType.should_follow}
        onChange={(event) => {
          changeNewBriefingTypeField('should_follow', event.target.checked);
        }}
        type="checkbox"
        label="Follow by all users"
      />
      <Control
        name="enable_email_push_notifications"
        value={newBriefingType.enable_notifications}
        checked={newBriefingType.enable_notifications}
        onChange={(event) => {
          changeNewBriefingTypeField(
            'enable_notifications',
            event.target.checked,
          );
        }}
        type="checkbox"
        label="Enable email/push notifications for all users"
      />
      <InfoCircleIcon
        size={22}
        data-tip="These actions include all users with enabled briefings"
        data-place="bottom"
      />
    </>
  );

  return (
    <ContentWrapper>
      <OnlyMobilePaper>
        <StyledTooltip />
        {briefingTypeFields.map(({ label, id, type }) => (
          <Fragment key={`edit-field-${id}`}>
            {type === 'input' && (
              <SettingsRow key={`container_${id}`}>
                <Input
                  maxWidth={300}
                  key={`field_${id}`}
                  error={validationErrors[id]}
                  value={newBriefingType[id]}
                  onChange={(value) => {
                    changeNewBriefingTypeField(id, value);
                  }}
                  label={label}
                  placeholder={label}
                />
              </SettingsRow>
            )}
            {type === 'checkbox' && (
              <SettingsRow
                key={`container_${id}`}
                className={
                  id === 'always_available' && search.id ? null : 'no-margin'
                }
              >
                <Control
                  name={`briefing_type_${id}`}
                  value={newBriefingType[id]}
                  checked={newBriefingType[id]}
                  onChange={(event) => {
                    changeNewBriefingTypeField(id, event.target.checked);
                  }}
                  type="checkbox"
                  label={label}
                />
              </SettingsRow>
            )}
            {type === 'color' && (
              <>
                <Label>Colour</Label>
                <SettingsRow key={`container_${id}`} className="no-margin">
                  <ColorPicker
                    color={newBriefingType[id]}
                    onChange={(value) => {
                      changeNewBriefingTypeField(id, value);
                    }}
                  />
                </SettingsRow>
              </>
            )}
          </Fragment>
        ))}
        {!search.id && (
          <SettingsRow
            className={isMobile ? 'checkbox-column' : null}
            key="container_should_follow"
          >
            {followAndEnableCheckboxes}
          </SettingsRow>
        )}
        <Button onClick={() => save(search.id)}>Save</Button>
      </OnlyMobilePaper>
    </ContentWrapper>
  );
};

export default inject('briefingsStore')(observer(BriefingTypeCreator));
