/* eslint-disable class-methods-use-this */

class ArticleSourceRequestsStorageController {
  getItem(key) {
    const item = window.localStorage.getItem(key);
    let store = {};
    if (item) {
      store = JSON.parse(item);
    }
    return store;
  }

  removeItem(key) {
    window.localStorage.removeItem(key);
  }

  setItem(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export default ArticleSourceRequestsStorageController;
