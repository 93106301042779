import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

export class BriefingsStore {
  isLoading = false;

  briefingTypes = [];

  briefingTypesCount = 0;

  briefingType = {};

  briefingTypeToEdit = {};

  newBriefingType = {};

  briefingTypeFields = [
    { label: 'Name', id: 'name', type: 'input' },
    { label: 'Description', id: 'description', type: 'input' },
    { label: 'tags', id: 'tags', type: 'input' },
    { label: 'Color', id: 'color', type: 'color' },
    {
      label: 'Disabled',
      id: 'disabled',
      type: 'checkbox',
    },
    { label: 'Demo', id: 'demo', type: 'checkbox' },
    {
      label: 'Include analyst in email',
      id: 'include_analyst_in_email',
      type: 'checkbox',
    },
    { label: 'Always available', id: 'always_available', type: 'checkbox' },
  ];

  validationErrors = {};

  constructor() {
    makeAutoObservable(this);
    this.briefingTypeFields.forEach(({ id, type }) => {
      if (type === 'color') {
        this.newBriefingType[id] = '#000';
      } else {
        this.newBriefingType[id] = type === 'checkbox' ? false : '';
      }
    });
  }

  changeNewBriefingTypeField = (key, value) => {
    this.newBriefingType[key] = value;
  };

  fetchBriefingTypes = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;
    this.companies = [];

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      name: searchQuery || '',
    };

    try {
      const response = await API.get(apiRoutes.briefingTypes(), {
        params: query,
      });

      runInAction(() => {
        this.briefingTypes = response.data.briefingTypes;
        this.briefingTypesCount = response.data.briefingTypesCount;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchBriefingType = async (id, toEdit) => {
    this.isLoading = true;

    this.briefingType = {};

    try {
      const response = await API.get(apiRoutes.briefingTypes(id));
      runInAction(() => {
        if (toEdit) {
          this.briefingTypeToEdit = response.data;
          this.fillForm();
        } else {
          this.briefingType = response.data;
        }
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };

  validateForm = () => {
    this.validationErrors.name =
      this.newBriefingType.name === null ||
      this.newBriefingType.name.length === 0
        ? 'Name should not be empty'
        : undefined;

    this.validationErrors.description =
      this.newBriefingType.description === null ||
      this.newBriefingType.description.length === 0
        ? 'Description should not be empty'
        : undefined;

    return !(this.validationErrors.name || this.validationErrors.description);
  };

  save = async (id) => {
    if (this.validateForm()) {
      this.validateForm();
    }

    const meta = {
      description: this.newBriefingType.description,
      color: this.newBriefingType.color,
      always_available: this.newBriefingType.always_available,
      demo: this.newBriefingType.demo,
      include_analyst_in_email: this.newBriefingType.include_analyst_in_email,
    };

    if (this.newBriefingType.tags) {
      meta.tags = this.newBriefingType.tags.split(',');
    }
    const briefingTypeWithMeta = {
      name: this.newBriefingType.name,
      disabled: this.newBriefingType.disabled,
      meta,
    };
    if (!id) {
      briefingTypeWithMeta.should_follow = this.newBriefingType.should_follow;
      briefingTypeWithMeta.enable_notifications =
        this.newBriefingType.enable_notifications;
    }

    try {
      let response;
      if (id) {
        response = await API.patch(apiRoutes.briefingTypes(id), {
          briefing_type: briefingTypeWithMeta,
        });
      } else {
        response = await API.post(apiRoutes.briefingTypes(), {
          briefing_type: briefingTypeWithMeta,
        });
      }

      if (response.data.errors) {
        this.serverError = response.data.errors.message;
        this.isLoading = false;
        toast.error('Something went wrong!');
      } else {
        routerStore.push(routes.briefingTypes);
        toast.success(
          'Briefing type successfully saved! Changes will be available in a few minutes.',
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  followByAllUsers = async (id) => {
    try {
      this.isLoading = true;

      const response = await API.post(apiRoutes.briefingTypeFollow, { id });

      if (response.data.errors) {
        this.serverError = response.data.errors.message;
        this.isLoading = false;
        toast.error('Something went wrong!');
      } else {
        routerStore.push(routes.briefingTypes);
        toast.success('Changes will be available in a few minutes.');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  enableNotificationsForAllUsers = async (id) => {
    try {
      this.isLoading = true;
      const response = await API.post(
        apiRoutes.briefingTypeEnableNotifications,
        { id },
      );

      if (response.data.errors) {
        this.serverError = response.data.errors.message;
        this.isLoading = false;
        toast.error('Something went wrong!');
      } else {
        routerStore.push(routes.briefingTypes);
        toast.success('Changes will be available in a few minutes.');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  fillForm = () => {
    this.newBriefingType.name = this.briefingTypeToEdit.name;
    this.newBriefingType.disabled = this.briefingTypeToEdit.disabled;
    this.newBriefingType.color = this.briefingTypeToEdit.meta.color;
    this.newBriefingType.description = this.briefingTypeToEdit.meta.description;
    this.newBriefingType.tags =
      this.briefingTypeToEdit.meta.tags &&
      this.briefingTypeToEdit.meta.tags.join(',');
    this.newBriefingType.demo = this.briefingTypeToEdit.meta.demo;
    this.newBriefingType.include_analyst_in_email =
      this.briefingTypeToEdit.meta.include_analyst_in_email;
    this.newBriefingType.always_available =
      this.briefingTypeToEdit.meta.always_available;
  };

  clear = () => {
    this.isLoading = false;
    this.briefingTypes = [];
    this.briefingTypesCount = 0;
    this.briefingType = {};
    this.newBriefingType = {};
    this.validationErrors = {};
    this.briefingTypeFields.forEach(({ id, type }) => {
      if (type === 'color') {
        this.newBriefingType[id] = '#000';
      } else {
        this.newBriefingType[id] = type === 'checkbox' ? false : '';
      }
    });
    this.briefingTypeToEdit = {};
  };
}

export default new BriefingsStore();
