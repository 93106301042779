import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { ContentWrapper, Paper, SubTitle } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Text, { TAGS } from '@components/Text';
import Table from '@components/Table';
import routes from '@routes';
import Details from '@components/Details';
import DetailsMobile from '@components/DetailsMobile';
import useBreakpoint from '@root/hooks/useBreakpoint';
import {ButtonContainer} from "@containers/ContentOrders/style";
import Button from "@components/Button";

const fields = [
  { id: 'id' },
  { id: 'name' },
  { id: 'display_name' },
  { id: 'external_id' },
  { id: 'subscription_plan' },
  {
    id: 'start_date',
    format: (value) => moment(value).format('LL').toString(),
  },
  { id: 'end_date', format: (value) => moment(value).format('LL').toString() },
  { id: 'cancel_on_expiration' },
  { id: 'transferable', format: (value) => value.toString() },
  { id: 'state' },
  {
    id: 'users',
    format: (value) =>
      value ? value.map(({ email }) => email).join(' ') : '(none)',
  },
  { id: 'invitations' },
  { id: 'account_manager' },
  {
    id: 'send_email_warnings_to',
    format: (value) => (value ? value.join(' ') : '(none)'),
  },
];

const columns = [
  {
    id: 'id',
    label: 'ID',
    maxWidth: 70,
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'is_active',
    label: 'Is active',
    format: (value) => (value ? value.toString() : 'false'),
    maxWidth: 70,
  },
  {
    id: 'is_default',
    label: 'Is default',
    format: (value) => (value ? value.toString() : 'false'),
    maxWidth: 70,
  },
  {
    id: 'has_briefings',
    label: 'Has briefings',
    format: (value) => (value ? value.toString() : 'false'),
    maxWidth: 80,
  },
];

const Subscription = ({
  subscriptionsStore: { fetchSubscription, subscription, isLoading },
}) => {
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const columnActions = {
    id: (row) => navigate(`${routes.contentOrder}?id=${row.id}`),
  };

  useEffect(() => {
    if (search.id) {
      fetchSubscription(search.id);
    }
  }, []);

  if (isLoading && !subscription) {
    return <Preloader overlay />;
  }

  if (!subscription) {
    if (search.id) {
      fetchSubscription(search.id);
    }
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>{subscription.name}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={subscription} fields={fields} />
      ) : (
        <Details data={subscription} fields={fields} />
      )}
      <SubTitle>
        <Text tag={TAGS.H2}>Content orders</Text>
      </SubTitle>
      <Paper>
        {!isLoading &&
          subscription &&
          subscription.content_orders &&
          subscription.content_orders.length && (
            <Table
              columnActions={columnActions}
              columns={columns}
              data={subscription.content_orders}
              disablePagination
            />
          )}
      </Paper>
      <ButtonContainer className={isMobile ? 'column' : null}>
        <Button onClick={() => navigate(`${routes.subscriptionUsers}?id=${subscription.id}`)}>Manage users</Button>
      </ButtonContainer>
    </ContentWrapper>
  );
};

export default inject('subscriptionsStore')(observer(Subscription));
