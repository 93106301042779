import styled from '@emotion/styled';

export const SelectContainer = styled.div`
  min-width: 300px;
  margin-right: ${(props) => (props.isMobile ? 'unset' : '16px')};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 21px;
  width: ${(props) => (props.isMobile ? '100%' : 'auto')};
`;

export const Flex = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: ${(props) =>
    props.isMobile ? 'space-around' : 'flex-start'};
  flex-wrap: ${(props) => (props.isMobile ? 'wrap' : 'no-wrap')};
`;
