import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import { Navigate, useLocation } from 'react-router';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import UserInvitationsCreator from '@containers/UserInvitations/create';
import NotFound from '@containers/NotFound';

import ProtectedRoute from '@components/ProtectedRoute';
import Layout from '@components/Layout';

import GlobalStyle from '@styles/globalStyles';
import ThemesProvider from '@styles/theme';
import Wraps from '@containers/Wraps';
import routes from '@routes';
import { PAGE_LABELS } from '@root/constants/common';
import WrapsEditor from '@containers/Wraps/edit';
import Companies from '@containers/Companies';
import CompaniesCreator from '@containers/Companies/bulkCreate';
import ArticleSourceRequests from '@containers/ArticleSourceRequests';
import ArticleSourceRequestCreator from '@containers/ArticleSourceRequests/edit';
import CompanyEditor from '@containers/Companies/edit';
import Subscriptions from '@containers/Subscriptions';
import Subscription from '@containers/Subscriptions/details';
import ContentOrder from '@containers/ContentOrders/details';
import BriefingTypes from '@containers/BriefingTypes';
import BriefingType from '@containers/BriefingTypes/details';
import SwitchArticleSources from '@containers/SwitchArticleSources';
import BriefingTypeCreator from '@containers/BriefingTypes/edit';
import DelayedJobs from '@containers/DelayedJobs';
import CompanyCreator from '@containers/Companies/create';
import CompanyRelations from '@containers/CompanyRelations';
import CompanyRelationsEditor from '@containers/CompanyRelations/edit';
import ContentOrderEditor from '@containers/ContentOrders/edit';
import ArticleSources from '@containers/ArticleSources';
import ArticleSource from '@containers/ArticleSources/details';
import ArticleSourceEditor from '@containers/ArticleSources/edit';
import ToolMessages from '@containers/ToolMessages';
import ToolMessage from '@containers/ToolMessages/details';
import ToolMessagesEditor from '@containers/ToolMessages/edit';
import Users from '@containers/Users';
import User from '@containers/Users/details';
import UsersEditor from '@containers/Users/edit';
import SkippedAuthorTermsEditor from '@containers/SkippedAuthorTerms/edit';
import SkippedAuthorTerms from '@containers/SkippedAuthorTerms';
import UserSettings from '@containers/UserSettings';
import UserSettingsUpdate from '@containers/UserSettings/update';
import SubscriptionUsers from "@containers/SubscriptionUsers";
import UserInvitations from '@containers/UserInvitations';
import NlaOrganisations from "@containers/NlaOrganisations";
import NlaOrganisation from "@containers/NlaOrganisations/details";
import NlaOrganisationsEditor from "@containers/NlaOrganisations/edit";
import ContentOrderAddUsers from "@containers/ContentOrders/addUsers";


moment.updateLocale('en', {
  relativeTime: {
    ss: '%ds',
    mm: '%dm',
    hh: '%dh',
    dd: '%dd',
  },
});

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
      ReactGA.initialize(TRACKING_ID);
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);
  return (
    <ThemesProvider>
      <GlobalStyle />
      <>
        <Routes>
          <Route
            exact
            path="/"
            element={<Navigate to={routes.wraps} replace />}
          />
          <Route
            exact
            path={routes.wraps}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.wraps]}>
                  <Wraps />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.wrapsEditor}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.wrapsEditor]}>
                  <WrapsEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.companies}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.companies]}>
                  <Companies />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.companiesCreator}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.companiesCreator]}>
                  <CompaniesCreator />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.companyRelations}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.companyRelations]}>
                  <CompanyRelations />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.companyRelationsEditor}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.companyRelationsEditor]}
                >
                  <CompanyRelationsEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.companiesEditor}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.companiesEditor]}>
                  <CompanyEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          {process.env.REACT_APP_ENABLE_SOURCE_CREATOR && (
            <Route
              exact
              path={routes.articleSourceRequests}
              element={
                <ProtectedRoute>
                  <Layout
                    mobileLabel={PAGE_LABELS[routes.articleSourceRequests]}
                  >
                    <ArticleSourceRequests />
                  </Layout>
                </ProtectedRoute>
              }
            />
          )}
          {process.env.REACT_APP_ENABLE_SOURCE_CREATOR && (
            <Route
              exact
              path={routes.articleSourceRequestCreator}
              element={
                <ProtectedRoute>
                  <Layout
                    mobileLabel={
                      PAGE_LABELS[routes.articleSourceRequestCreator]
                    }
                  >
                    <ArticleSourceRequestCreator />
                  </Layout>
                </ProtectedRoute>
              }
            />
          )}
          <Route
            exact
            path={routes.switchArticleSources}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.switchArticleSources]}>
                  <SwitchArticleSources />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.subscriptions}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.subscriptions]}>
                  <Subscriptions />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.subscription}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.subscription]}>
                  <Subscription />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.contentOrder}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.contentOrder]}>
                  <ContentOrder />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.contentOrderEditor}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.contentOrderEditor]}>
                  <ContentOrderEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.briefingTypes}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.briefingTypes]}>
                  <BriefingTypes />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.briefingType}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.briefingType]}>
                  <BriefingType />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.briefingTypeCreator}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.briefingTypeCreator]}>
                  <BriefingTypeCreator />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.delayedJobs}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.delayedJobs]}>
                  <DelayedJobs />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.companyCreator}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.companyCreator]}>
                  <CompanyCreator />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.articleSources}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.articleSources]}>
                  <ArticleSources />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.articleSource}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.articleSource]}>
                  <ArticleSource />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.articleSourceEditor}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.articleSourceEditor]}>
                  <ArticleSourceEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.toolMessages}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.toolMessages]}>
                  <ToolMessages />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.toolMessage}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.toolMessage]}>
                  <ToolMessage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.toolMessageEditor}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.toolMessageEditor]}>
                  <ToolMessagesEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.users}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.users]}>
                  <Users />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.user}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.user]}>
                  <User />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.userEditor}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.userEditor]}>
                  <UsersEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.userSettings}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.userSettings]}>
                  <UserSettings />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.userSettingsUpdate}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.userSettingsUpdate]}>
                  <UserSettingsUpdate />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.skippedAuthorTerms}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.skippedAuthorTerms]}>
                  <SkippedAuthorTerms />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.skippedAuthorTermsEditor}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.skippedAuthorTermsEditor]}
                >
                  <SkippedAuthorTermsEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.subscriptionUsers}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.subscriptionUsers]}
                >
                  <SubscriptionUsers />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.userInvitations}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.userInvitations]}
                >
                  <UserInvitations />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.userInvitationCreator}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.userInvitationCreator]}
                >
                  <UserInvitationsCreator />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.nlaOrganisations}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.nlaOrganisations]}>
                  <NlaOrganisations />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.nlaOrganisation}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.nlaOrganisation]}>
                  <NlaOrganisation />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.nlaOrganisationEditor}
            element={
              <ProtectedRoute>
                <Layout mobileLabel={PAGE_LABELS[routes.nlaOrganisationEditor]}>
                  <NlaOrganisationsEditor />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.contentOrderAddUsers}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel={PAGE_LABELS[routes.contentOrderAddUsers]}
                >
                  <ContentOrderAddUsers />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Layout mobileLabel="404 Not found">
                  <NotFound />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<div>404 Not found</div>} />
        </Routes>
        <ToastContainer
          autoClose={8000}
          position="bottom-right"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
      </>
    </ThemesProvider>
  );
};

export default App;
