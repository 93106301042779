import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';
import { Trash} from "tabler-icons-react";
import { ContentWrapper, Paper, SubTitle } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Text, { TAGS } from '@components/Text';
import Table from '@components/Table';
import {tableColumns} from "@containers/SubscriptionUsers/constants";
import ConfirmationDialog from "@components/ConfirmationDialog";



const SubscriptionUsers = ({
  subscriptionsStore: { fetchSubscription, subscription, isLoading, removeUserFromSubscription },
}) => {
  const { search } = useCustomSearchParams();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [rowIdToRemove, setRowIdToRemove] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const actions = () => [
    {
      icon: () => <Trash strokeWidth={1} size={24} />,
      onClick: (row) => {
        setIsConfirmationDialogOpen(true);
        setRowIdToRemove(row.id);
      },
    },
  ];

  useEffect(() => {
    if (search.id) {
      fetchSubscription(search.id);
    }
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  if (!subscription) {
    if (search.id) {
      fetchSubscription(search.id);
    }
  }

  const tableActions = actions();
  let paginatedData = []
  if(subscription.users && subscription.users.length) {
    const currentStart = page * rowsPerPage
    paginatedData = subscription.users.slice(currentStart, currentStart + rowsPerPage)
  }

  return (
    <ContentWrapper>
      <ConfirmationDialog
        onConfirmation={(rowId) => {
          removeUserFromSubscription(rowId);
          setIsConfirmationDialogOpen(false);
          setPage(0)
        }}
        isDialogOpen={isConfirmationDialogOpen}
        setIsDialogOpen={setIsConfirmationDialogOpen}
        rowId={rowIdToRemove}
        dialogText="Delete this item?"
      />
      <SubTitle>
        <Text tag={TAGS.H2}>{subscription.name} - Users</Text>
      </SubTitle>
      <Paper>
        {!isLoading &&
          subscription &&
          subscription.users &&
          subscription.users.length && (
            <Table
              columns={tableColumns}
              data={paginatedData}
              actions={tableActions}
              rowsPerPageOptions={[10, 50, 100]}
              count={subscription.users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={setPage}
              onChangeRowsPerPage={(value) => {
                setPage(0);
                setRowsPerPage(value);
              }}
            />
          )}
      </Paper>
    </ContentWrapper>
  );
};

export default inject('subscriptionsStore')(observer(SubscriptionUsers));
