import styled from '@emotion/styled';

export const Container = styled.div`
  cursor: pointer;

  & > svg {
    color: ${({ theme }) => theme.inputs.secondary.borderColor};
    margin: 0 4px -5px 4px;
  }
`;

export const Text = styled.span`
  border: 1px solid;
  color: ${({ theme }) => theme.inputs.secondary.text};
  background: transparent;
  border-color: ${({ theme }) => theme.inputs.secondary.borderColor};
  border-radius: 3px;
  padding: 8px 16px;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-weight: 300;
  display: inline-block;
`;