import React from 'react';
import { inject, observer } from 'mobx-react';
import Select from '@components/Select';
import { SelectContainer } from '@containers/Wraps/style';

export const MODES = {
  frequency: 'frequency',
  sendAtHours: 'sendAtHours',
  sendAtMinutes: 'sendAtMinutes',
  sendAtDayOfWeek: 'sendAtDayOfWeek',
  sendAtDayOfMonth: 'sendAtDayOfMonth',
};

const frequencyValues = [
  { value: 'daily', label: 'daily' },
  { value: 'weekly', label: 'weekly' },
  { value: 'monthly', label: 'monthly' },
  { value: 'twice_a_day', label: 'twice a day' },
];

const monthValues = () =>
  [...Array(31).keys()].map((i) => ({ value: i + 1, label: i + 1 }));

const hourValues = () =>
  [...Array(24).keys()].map((i) => ({ value: i, label: i }));

const daysValues = () => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days.map((day, index) => ({ value: index, label: day }));
};
const minuteValues = [
  { label: '00', value: 0 },
  { label: '30', value: 30 },
];

const FrequencySelector = ({
  wrapsStore: {
    frequency,
    sendAtHours,
    sendAtMinutes,
    sendAtDayOfWeek,
    sendAtDayOfMonth,
    setFrequency,
    setSendAtHours,
    setSendAtMinutes,
    setSendAtDayOfWeek,
    setSendAtDayOfMonth,
    validationErrors,
  },
  mode,
  label,
  sendAtHourIndex,
  sendAtMinutesIndex,
  addNoneOption = true,
}) => {
  let items = [];
  let setValue;
  let value;

  if (mode === MODES.frequency) {
    items = frequencyValues;
    setValue = setFrequency;
    value = frequency;
  } else if (mode === MODES.sendAtHours) {
    items = hourValues();
    setValue = (newValue) => setSendAtHours(newValue, sendAtHourIndex);
    value = sendAtHours[sendAtHourIndex];
  } else if (mode === MODES.sendAtDayOfWeek) {
    items = daysValues();
    setValue = setSendAtDayOfWeek;
    value = sendAtDayOfWeek;
  } else if (mode === MODES.sendAtDayOfMonth) {
    items = monthValues();
    setValue = setSendAtDayOfMonth;
    value = sendAtDayOfMonth;
  } else if (mode === MODES.sendAtMinutes) {
    items = minuteValues;
    setValue = (newValue) => setSendAtMinutes(newValue, sendAtMinutesIndex);
    value = sendAtMinutes[sendAtMinutesIndex];
  }
  if (addNoneOption) {
    items = [{ value: '', label: 'None' }, ...items];
  }

  return (
    <SelectContainer>
      <Select
        label={label}
        options={items}
        onChange={setValue}
        value={value}
        error={!!validationErrors[mode]}
      />
    </SelectContainer>
  );
};

export default inject('wrapsStore')(observer(FrequencySelector));
