import moment from 'moment';

export const fetchTypeOptions = [
  { label: 'Lexis Nexis', value: 'LEXIS_NEXIS' },
];

export const defaultPreviewOptions = [
  { label: 'Link', value: 'link' },
  { label: 'Tool', value: 'tool' },
];

export const defaultPreviewModeOptions = [
  { label: 'Raw', value: 'raw' },
  { label: 'Plain text', value: 'plain_text' },
];

export const mediaTypes = [
  { label: 'Online', value: 'online' },
  { label: 'Printing', value: 'printing' },
  { label: 'Broadcast', value: 'broadcast' },
  { label: 'Social/Blog', value: 'social_or_blog' },
];

export const priorities = [
  {
    value: 'red',
    label: 'Red',
  },
  {
    value: 'orange',
    label: 'Orange',
  },
  {
    value: 'yellow',
    label: 'Yellow',
  },
  {
    value: 'green',
    label: 'Green',
  },
];

export const categoryOptions = [
  { label: 'Briefings', value: 'BRIEFINGS' },
  { label: 'Consumer', value: 'CONSUMER' },
  { label: 'International', value: 'INTERNATIONAL' },
  { label: 'Local', value: 'LOCAL' },
  { label: 'National', value: 'NATIONAL' },
  { label: 'Newswires', value: 'NEWSWIRES' },
  { label: 'Social', value: 'SOCIAL' },
  { label: 'Trade', value: 'TRADE' },
];

export const boolSelect = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'display_title',
    label: 'Display title',
    hide: true,
  },
  {
    id: 'newspaper_title',
    label: 'Newspaper',
    maxWidth: 150,
  },
  {
    id: 'region',
    label: 'Region',
    maxWidth: 150,
    hide: true,
  },
  {
    id: 'category',
    label: 'Category',
    maxWidth: 150,
    hide: true,
  },
  {
    id: 'disabled',
    label: 'Disabled',
    format: (value) => (value ? 'true' : 'false'),
    maxWidth: 100,
  },
  {
    id: 'url',
    label: 'Url',
    hide: true,
  },
  {
    id: 'url_for_analysts',
    label: 'Url for analyst',
  },
  {
    id: 'position',
    label: 'Position',
    maxWidth: 90,
    hide: true,
  },
  {
    id: 'fetch_type',
    label: 'Fetch type',
    maxWidth: 150,
    hide: true,
  },
  {
    id: 'media_type',
    label: 'Media type',
    maxWidth: 150,
    hide: true,
  },
  {
    id: 'public_article_links_in_briefings',
    label: 'Public article links in briefings',
    format: (value) => (value ? 'true' : 'false'),
    maxWidth: 200,
    hide: true,
  },
  {
    id: 'licensor',
    label: 'Licensor',
    maxWidth: 150,
    hide: true,
  },
  {
    id: 'created_at',
    label: 'Created at',
    format: (value) => moment(value).format('LL').toString(),
    hide: true,
  },
  {
    id: 'updated_at',
    label: 'Updated at',
    format: (value) => moment(value).format('LL').toString(),
    hide: true,
  },
];

export const editorFields = (newspapers, availableLanguages, availableLicensors) => [
  {
    id: 'title',
    label: 'Title',
    component: 'input',
    type: 'text',
  },
  {
    id: 'display_title',
    label: 'Display title',
    component: 'input',
    type: 'text',
  },
  {
    id: 'url',
    label: 'Url',
    component: 'input',
    type: 'text',
  },
  {
    id: 'url_for_analysts',
    label: 'Url for analysts',
    component: 'input',
    type: 'text',
  },
  {
    id: 'newspaper_id',
    label: 'Newspaper',
    component: 'select',
    options: newspapers,
  },
  {
    id: 'lang',
    label: 'Language',
    component: 'select',
    options: availableLanguages,
    isClearable: true,
  },
  {
    id: 'media_type',
    label: 'Media type',
    component: 'select',
    options: mediaTypes,
  },
  {
    id: 'licensor',
    label: 'Licensor',
    component: 'select',
    options: availableLicensors,
    isClearable: true,
  },
  {
    id: 'pls_code',
    label: 'PLS Code',
    component: 'input',
    type: 'text',
    condition: (item) => item.licensor === 'CLA'
  },
  {
    id: 'default_preview',
    label: 'Default preview',
    component: 'select',
    options: defaultPreviewOptions,
  },
  {
    id: 'default_preview_mode',
    label: 'Default preview mode',
    component: 'select',
    options: defaultPreviewModeOptions,
  },
  {
    checkboxes: [
      { id: 'disabled', label: 'Disabled' },
      { id: 'v3', label: 'V3' },
      { id: 'disabled_scraping', label: 'Disabled scraping' },
      { id: 'send_alert_on_update', label: 'Send alert on update' },
      { id: 'pubdate_without_time', label: 'Pubdate without time' },
    ],
    component: 'checkboxes',
  },
  {
    id: 'schedule_expression',
    label: 'Schedule expression',
    component: 'input',
    type: 'text',
  },
  {
    id: 'position',
    label: 'Position',
    component: 'input',
    type: 'number',
  },
  {
    id: 'weekday_warning_time_in_hours',
    label: 'Weekday warning time in hours',
    component: 'input',
    type: 'number',
  },
  {
    id: 'weekend_warning_time_in_hours',
    label: 'Weekend warning time in hours',
    component: 'input',
    type: 'number',
  },
  {
    id: 'weekday_error_time_in_hours',
    label: 'Weekday error time in hours',
    component: 'input',
    type: 'number',
  },
  {
    id: 'weekend_error_time_in_hours',
    label: 'Weekend error time in hours',
    component: 'input',
    type: 'number',
  },
  {
    id: 'fetch_type',
    label: 'Fetch type',
    component: 'input',
    type: 'text',
  },
  {
    id: 'ft_tag',
    label: 'FT tag',
    component: 'input',
    type: 'text',
  },
  {
    id: 'priority',
    label: 'Priority',
    component: 'select',
    options: priorities,
  },
  {
    checkboxes: [
      {
        id: 'full_article_content_permitted',
        label: 'Full article content permitted',
      },
      {
        id: 'monitor_for_missing_articles',
        label: 'Monitor for missing articles',
      },
    ],
    component: 'checkboxes',
  },
  {
    checkboxes: [
      {
        id: 'enabled_for_b2b_content_orders',
        label: 'Enabled for B2B Content Orders',
      },
      {
        id: 'direct_ln_links_allowed',
        label: 'Direct Lexis Nexis links allowed',
      },
      {
        id: 'public_article_links_in_briefings',
        label: 'Public article links in briefings',
      },
    ],
    component: 'checkboxes',
  },
  {
    checkboxes: [
      { id: 'hide_sentiments', label: 'Hide sentiments' },
      { id: 'warnings_enabled', label: 'Warnings enabled' },
      { id: 'translations_enabled', label: 'Translations enabled' },
    ],
    component: 'checkboxes',
  },
  {
    id: 'meta',
    label: 'Meta',
    component: 'json',
  },
];

export const detailsFields = (availableLanguagesObj) => [
  { id: 'title' },
  { id: 'display_title' },
  { id: 'url_for_analysts' },
  { id: 'url' },
  { id: 'fetch_type' },
  { id: 'disabled', format: (value) => value.toString() },
  { id: 'disabled_scraping', format: (value) => value.toString() },
  {
    id: 'created_at',
    format: (value) => moment(value).format('LLL').toString(),
  },
  {
    id: 'updated_at',
    format: (value) => moment(value).format('LLL').toString(),
  },
  { id: 'region' },
  { id: 'country' },
  { id: 'category' },
  { id: 'schedule_expression' },
  { id: 'position' },
  { id: 'meta', format: (value) => JSON.stringify(value) },
  { id: 'newspaper_title' },
  { id: 'weekday_warning_time_in_hours' },
  { id: 'weekend_warning_time_in_hours' },
  { id: 'weekday_error_time_in_hours' },
  { id: 'weekend_error_time_in_hours' },
  { id: 'send_alert_on_update', format: (value) => value.toString() },
  { id: 'priority' },
  { id: 'pubdate_without_time', format: (value) => value.toString() },
  { id: 'pubdate_format_type', format: (value) => value.toString() },
  { id: 'full_article_content_permitted', format: (value) => value.toString() },
  { id: 'v3', format: (value) => value.toString() },
  { id: 'data_source_type' },
  { id: 'audience_type' },
  { id: 'ft_tag' },
  { id: 'warnings_enabled', format: (value) => value.toString() },
  { id: 'default_preview' },
  { id: 'monitor_for_missing_articles', format: (value) => value.toString() },
  {
    id: 'public_article_links_in_briefings',
    format: (value) => value.toString(),
  },
  { id: 'translations_enabled', format: (value) => value.toString() },
  { id: 'default_preview_mode' },
  { id: 'media_type' },
  { id: 'licensor' },
  { id: 'pls_code', label: 'PLS Code' },
  { id: 'enabled_for_b2b_content_orders', format: (value) => value.toString() },
  { id: 'hide_sentiments', format: (value) => value.toString() },
  { id: 'direct_ln_links_allowed', format: (value) => value.toString() },
  { id: 'calculate_statistics', format: (value) => value.toString() },
  { id: 'lang', format: (value) => availableLanguagesObj[value] || value },
];

export const filtersFunc = (newspapers, isLoadingNewspapers, availableLicensors, isLoadingAvailableLicensors) => [
  { id: 'title', label: 'Title', type: 'input' },
  { id: 'display_title', label: 'Display title', type: 'input' },
  { id: 'fetch_type', label: 'Fetch type', type: 'input' },
  {
    id: 'newspaper_id',
    label: 'Newspaper',
    type: 'select',
    options: newspapers,
    isLoading: isLoadingNewspapers,
    isClearable: true,
  },
  {
    id: 'media_type',
    label: 'Media type',
    type: 'select',
    options: mediaTypes,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'disabled',
    label: 'Disabled',
    type: 'select',
    options: boolSelect,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'warnings_enabled',
    label: 'Warnings enabled',
    placeholder: 'Enabled...',
    type: 'select',
    options: boolSelect,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'monitor_for_missing_articles',
    label: 'Monitor for missing articles',
    placeholder: 'Monitor...',
    type: 'select',
    options: boolSelect,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'send_alert_on_update',
    label: 'Send alert on update',
    placeholder: 'Send alert...',
    type: 'select',
    options: boolSelect,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'full_article_content_permitted',
    label: 'Full article content permitted',
    placeholder: 'Full content...',
    type: 'select',
    options: boolSelect,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'public_article_links_in_briefings',
    label: 'Public article links in briefings',
    placeholder: 'Links...',
    type: 'select',
    options: boolSelect,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'direct_ln_links_allowed',
    label: 'Direct LN links allowed',
    placeholder: 'Direct LN...',
    type: 'select',
    options: boolSelect,
    isLoading: false,
    isClearable: true,
  },
  {
    id: 'licensor',
    label: 'Licensor',
    placeholder: 'Licensor...',
    type: 'select',
    options: availableLicensors,
    isLoading: isLoadingAvailableLicensors,
    isClearable: true,
  },
];
