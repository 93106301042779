import styled from '@emotion/styled';

export const SelectContainer = styled.div`
  width: 300px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -27px;
`;
