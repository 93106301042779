import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars';
import { breakpoint } from '@styles/breakpoints';

export const DialogContainer = styled.div`
  margin: 0 10px 20px 10px;
`;

export const ScrollableContent = styled(Scrollbars)`
  .scrollable {
    overflow-x: hidden !important;
  }
`;

export const ListContainer = styled.div`
  height: 300px;

  .text {
    line-height: 25px;
  }

  @media screen and (${breakpoint.md}) {
    height: calc(100vh - 150px);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;

  @media screen and (${breakpoint.md}) {
    width: 100%;
  }
`;

export const DialogItem = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;
