import { makeAutoObservable } from 'mobx';
import Cookie from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import API, { setApiHeaders } from '@app/api';
import { getItem, KEYS, setItem } from '@utils/cache';
import apiRoutes from '@app/apiRoutes';

export class AuthStore {
  isLoading = false;

  isLoadingUser = false;

  darkMode = false;

  suffix = process.env.REACT_APP_COOKIE_SUFFIX;

  user = null;

  appSize = 'small';

  constructor() {
    makeAutoObservable(this);

    const email = Cookie.get(`analyst_email${this.suffix}`);
    const token = Cookie.get(`analyst_token${this.suffix}`);

    if (email && token) {
      setApiHeaders(token, email);
      this.fetchUser(email);
    }

    const appSize = getItem(KEYS.APP_SIZE);
    if (appSize) {
      this.appSize = appSize;
    }
  }

  isAuthenticated = () => {
    const email = Cookie.get(`analyst_email${this.suffix}`);
    const token = Cookie.get(`analyst_token${this.suffix}`);
    if (token && email) {
      setApiHeaders(token, email);
      this.fetchUser(email);
    }

    return email && token;
  };

  fetchUser = async (email) => {
    if (this.user !== null) {
      return;
    }
    this.isLoadingUser = true;
    try {
      const {
        data: { data: profile },
      } = await API.get(apiRoutes.profile, { params: { email } });
      const { first_name: firstName, last_name: lastName } = profile;
      this.user = {
        fullName: `${firstName} ${lastName}`,
        firstName,
        lastName,
        email,
      };
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingUser = false;
    }
  };

  logout = async () => {
    setItem(KEYS.DARKMODE, false);

    Cookie.remove(`analyst_email${this.suffix}`, {
      domain: '.briefcase.news',
    });

    Cookie.remove(`analyst_token${this.suffix}`, {
      domain: '.briefcase.news',
    });

    Cookie.remove(`analyst_id${this.suffix}`, {
      domain: '.briefcase.news',
    });

    window.location.href = `${process.env.REACT_APP_SSO_URL}/?signout=true&origin=${window.location.origin}`;
  };

  handleDarkMode = (value) => {
    this.darkMode = value;
    setItem(KEYS.DARKMODE, value);
  };

  handleAppSize = (value) => {
    this.appSize = value;
    setItem(KEYS.APP_SIZE, value);
  };
}

export default new AuthStore();
