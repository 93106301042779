import React, { useMemo, useRef } from 'react';
import { ChevronDown } from 'tabler-icons-react';
import { useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';

import Preloader, { POSITIONS } from '@components/Preloader';
import {
  CollapseIcon,
  MenuItemIcon,
  MenuItemLabel,
  StyledMenuItem,
  SubMenuItem,
  SubMenu,
} from '@components/SideBar/styles';

const MenuItemToggable = ({
  label,
  submenuItemsKey,
  icon,
  parent,
  sidebarStore: {
    submenusItems,
    expandedItem,
    toggleSubmenu,
    isLoading,
    setMobileMenuOpened,
  },
}) => {
  const submenu = useRef();
  const submenuItems = useMemo(
    () => submenusItems[submenuItemsKey],
    [submenusItems, submenuItemsKey],
  );
  const { pathname } = useLocation();
  const isExpanded = useMemo(
    () => expandedItem?.includes(parent),
    [expandedItem],
  );

  const isMainItemChosen = useMemo(
    () => parent && pathname.includes(`${parent}/`),
    [parent, pathname],
  );

  return (
    <div>
      <StyledMenuItem
        className={
          isMainItemChosen
            ? 'sidebar-item sidebar-item--active'
            : 'sidebar-item'
        }
        onClick={() => toggleSubmenu(parent, submenu)}
      >
        <MenuItemIcon>{icon}</MenuItemIcon>
        <MenuItemLabel>
          {label}
          {isLoading ? <Preloader display={POSITIONS.HORIZONRAL} /> : null}
          {submenuItemsKey && (
            <CollapseIcon isExpanded={isExpanded}>
              <ChevronDown size={16} strokeWidth={1} />
            </CollapseIcon>
          )}
        </MenuItemLabel>
      </StyledMenuItem>
      <SubMenu ref={submenu} isExpanded={isExpanded} data-toggable="true">
        {submenuItems?.map(({ label: subLabel, path: subPath }) => (
          <SubMenuItem
            key={`submenu-item_${subLabel}`}
            to={subPath}
            onClick={() => setMobileMenuOpened(false)}
          >
            {subLabel}
          </SubMenuItem>
        ))}
      </SubMenu>
    </div>
  );
};

export default inject('sidebarStore')(observer(MenuItemToggable));
