import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';
import { Trash, UserPlus, Mail, Password } from "tabler-icons-react";
import {useNavigate} from "react-router";
import {ContentWrapper, FiltersContainer, StyledTooltip, SubTitle} from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Text, { TAGS } from '@components/Text';
import Table from '@components/Table';
import { tableColumns, filters } from "@containers/UserInvitations/constants";
import ConfirmationDialog from "@components/ConfirmationDialog";
import Filters from "@components/Filters";
import {ButtonContainer} from "@containers/ToolMessages/styles";
import Button from "@components/Button";
import routes from "@routes";

const UserInvitations = ({
 userInvitationsStore: {
   fetchUserInvitations,
   isLoading,
   userInvitations,
   userInvitationsCount,
   contentOrderName,
   doAction,
   isLoadingAction,
   removeUserFromContentOrder
 },
}) => {
  const { search } = useCustomSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [rowIdToRemove, setRowIdToRemove] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const navigate = useNavigate();

  const actions = () => [
    {
      icon: () => <Mail data-tip="Resend welcome email" data-place="bottom" strokeWidth={1} size={24} />,
      onClick: (row) => {
        doAction(row.id, 'resendWelcomeEmail')
      },
    },
    {
      icon: () => <UserPlus data-tip="Resend invitation email" data-place="bottom" strokeWidth={1} size={24} />,
      onClick: (row) => {
        doAction(row.id, 'resendInvitation')
      },
    },
    {
      icon: () => <Trash data-tip="Remove from content order" data-place="bottom" strokeWidth={1} size={24} />,
      onClick: (row) => {
        setIsConfirmationDialogOpen(true);
        setRowIdToRemove(row.id);
      },
    },
    {
      icon: (row) => row.user_id ? <Password data-tip="Send reset password instructions" data-place="bottom" strokeWidth={1} size={24} /> : null,
      onClick: (row) => {
        doAction(row.id, 'sendResetPasswordEmail')
      },
    },
  ];

  const columnActions = {
    user_id: (row) => row.user_id ? navigate(`${routes.user}?id=${row.user_id}`) : undefined,
  }

  useEffect(() => {
    if (search.id) {
      fetchUserInvitations({
        page,
        limit: rowsPerPage,
        searchQuery: { content_order_id: search.id }
      });
    }
  }, [search.id]);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    if (search.id) {
      await fetchUserInvitations({
        limit: rowsPerPage,
        page: newPage,
        searchQuery: { content_order_id: search.id, ...searchQuery }
      });
    }
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    if (search.id) {
      await fetchUserInvitations({
        limit: value,
        page: 0,
        searchQuery: {content_order_id: search.id, ...searchQuery}
      });
    }
  };

  const clear = async () => {
    setSearchQuery({});
    setPage(0);
    if (search.id) {
      await fetchUserInvitations({
        limit: rowsPerPage,
        page: 0,
        searchQuery: {content_order_id: search.id}
      });
    }
  };

  if (isLoading) {
    return <Preloader overlay />;
  }
  const tableActions = actions();

  return (
    <ContentWrapper>
      <StyledTooltip />
      { isLoadingAction ? <Preloader overlay/> : null }
      <ConfirmationDialog
        onConfirmation={(rowId) => {
          removeUserFromContentOrder(rowId, clear);
          setIsConfirmationDialogOpen(false);
        }}
        isDialogOpen={isConfirmationDialogOpen}
        setIsDialogOpen={setIsConfirmationDialogOpen}
        rowId={rowIdToRemove}
        dialogText="Delete this item?"
      />
      <SubTitle>
        <Text tag={TAGS.H2}>{contentOrderName} - User invitations</Text>
      </SubTitle>
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <FiltersContainer>
        <ButtonContainer>
          <Button
            onClick={() => navigate(`${routes.userInvitationCreator}?id=${search.id}`)}
          >
            Create user invitation
          </Button>
        </ButtonContainer>
      </FiltersContainer>

      {!isLoading &&
        userInvitations.length && (
          <Table
            columns={tableColumns}
            data={userInvitations}
            actions={tableActions}
            rowsPerPageOptions={[10, 50, 100]}
            count={userInvitationsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            columnActions={columnActions}
          />
        )}

    </ContentWrapper>
  );
};

export default inject('userInvitationsStore')(observer(UserInvitations));
