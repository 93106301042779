import React, { useState, useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { Trash, Edit } from 'tabler-icons-react';
import { useNavigate } from 'react-router';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import Table from '@components/Table';
import Button from '@components/Button';
import { ButtonContainer } from '@containers/Wraps/style';
import routes from '@routes';
import ConfirmationDialog from '@components/ConfirmationDialog';
import { tableColumns } from '@containers/CompanyRelations/constants';

const CompanyRelations = ({
  companyRelationsStore: {
    isLoading,
    data,
    fetchTable,
    isLoadingTable,
    totalRowsNumber,
    removeRelation,
  },
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [rowIdToRemove, setRowIdToRemove] = useState(null);
  const navigate = useNavigate();

  const actions = () => [
    {
      icon: () => <Trash strokeWidth={1} size={24} />,
      onClick: (row) => {
        setIsConfirmationDialogOpen(true);
        setRowIdToRemove(row.id);
      },
    },
    {
      icon: () => <Edit strokeWidth={1} size={24} />,
      onClick: (row) => {
        navigate(`${routes.companyRelationsEditor}?id=${row.id}`);
      },
    },
  ];

  const tableActions = useMemo(() => actions(), []);

  useEffect(() => {
    fetchTable({ limit: rowsPerPage, page: 0 });
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchTable({ limit: rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchTable({
      limit: value,
      page: 0,
    });
  };

  return (
    <ContentWrapper>
      {(isLoading || isLoadingTable) && <Preloader overlay />}
      <ConfirmationDialog
        onConfirmation={(rowId) => {
          removeRelation(rowId);
          setIsConfirmationDialogOpen(false);
          handleChangePage(0);
        }}
        isDialogOpen={isConfirmationDialogOpen}
        setIsDialogOpen={setIsConfirmationDialogOpen}
        rowId={rowIdToRemove}
        dialogText="Delete this item?"
      />
      <FiltersContainer>
        <ButtonContainer>
          <Button onClick={() => navigate(routes.companyRelationsEditor)}>
            Create company relations
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      <Table
        rowsPerPageOptions={[10, 50, 100]}
        count={totalRowsNumber}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={data}
        actions={tableActions}
        mobileBig
      />
    </ContentWrapper>
  );
};

export default inject('companyRelationsStore')(observer(CompanyRelations));
