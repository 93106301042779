import styled from '@emotion/styled';
import { InfoCircle } from 'tabler-icons-react';

export const SubTitle = styled.div`
  margin: 15px 0;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 9px;
  margin-left: 5px;
`;
