import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, BUTTON_THEMES } from '@components/Button/style';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';

export { BUTTON_THEMES };

export const BUTTON_TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
};

const Button = ({
  children,
  type,
  theme,
  onClick,
  loading,
  disabled,
  classes,
}) => (
  <StyledButton
    type={type}
    buttonTheme={theme}
    className={classes}
    onClick={onClick}
    disabled={disabled || loading}
    loading={loading.toString()}
  >
    {children}
    {loading ? (
      <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
    ) : null}
  </StyledButton>
);

Button.propTypes = {
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  theme: PropTypes.oneOf(Object.values(BUTTON_THEMES)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
};

Button.defaultProps = {
  type: BUTTON_TYPES.BUTTON,
  theme: BUTTON_THEMES.TERTIARY,
  onClick: null,
  loading: false,
  disabled: false,
  classes: '',
};

export default Button;
