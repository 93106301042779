import styled from '@emotion/styled';

export const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputs.secondary.icon};
  border-radius: 3px;
  max-height: 220px;
  overflow-y: scroll;
`;

export const FixedListContainer = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.inputs.secondary.icon};
  border-radius: 3px;
`;

export const CheckListItem = styled.div`
  display: flex;
  margin: 5px 0;
  padding: 0 5px;
  height: 35px;
  color: ${({ theme }) => theme.font.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.mainBackground};
    border-radius: 3px;
  }
`;

export const Title = styled.div`
  display: flex;
  height: ${({ big }) => (big ? '60px' : '40px')};
  align-items: center;
  margin-left: 5px;
`;

export const ListActions = styled.div`
  margin-left: auto;
`;
