import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4px;
`;

export const Label = styled.label`
  margin-left: 10px;
  margin-top: 7px;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  line-height: 1.71px;
  letter-spacing: -0.21px;
  color: ${({ theme }) => theme.font.secondary};
  text-align: left;
  cursor: pointer;
  flex: 1 0 calc(100% - 43px);
`;

export const StyledControl = styled.input`
  &[type='checkbox'] {
    appearance: none;
    background-color: ${({ theme }) => theme.control.background};
    margin: 0;
    color: red;
    width: 20px;
    height: 20px;
    border: ${({ theme }) => `1.5px solid ${theme.control.center}`};
    border-radius: 3px;
    transform: translateY(-0.075em);
    display: inline-grid;
    place-content: center;
    position: relative;
    top: 3px;
    cursor: pointer;
    transition: all 0.3s;

    &.invalid {
      color: ${({ theme }) => theme.colors.error};
      border-color: ${({ theme }) => theme.colors.error}red;
    }

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      clip-path: polygon(34% 42%, 46% 55%, 72% 30%, 80% 38%, 46% 69%, 25% 51%);
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: ${({ theme }) => theme.colors.secondary};
      transition: all 0.3s;
    }

    &:checked:before {
      transform: scale(1);
    }

    &:disabled {
      border: ${({ theme }) => `1.5px solid ${theme.colors.disabled}`};

      &:before {
        background-color: ${({ theme }) => theme.colors.disabled};
      }
    }
  }

  &[type='radio'] {
    background-color: ${({ theme }) => theme.control.background};
    border-color: ${({ theme }) => theme.colors.secondary};
    margin: 0 10px 0 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-grid;
    place-content: center;
    cursor: pointer;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: ${({ theme }) => theme.colors.secondary};
    }

    &:checked:before {
      transform: scale(1);
    }
  }
`;

export const ErrorMessage = styled.p`
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 1.67px;
  color: ${({ theme }) => theme.colors.error};
  margin-left: 42;
`;
