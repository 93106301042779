import React from 'react';
import { X } from 'tabler-icons-react';
import Dialog from '@components/Modal/Dialog';

import Button from '@components/Button';
import Text, { TAGS } from '@components/Text';
import useBreakpoint from '@root/hooks/useBreakpoint';
import {
  DialogContainer,
  Header,
  ListContainer,
  ScrollableContent,
} from '@components/TableDialog/styles';

const TableDialog = ({
  isOpen,
  closeHandler,
  dialogParams: { values, dialogName },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  return (
    <Dialog closeHandler={closeHandler} isOpen={isOpen} smallModal={!isMobile}>
      <DialogContainer>
        <Header className="first">
          <Text tag={TAGS.H2}>{dialogName}</Text>
          <Button onClick={closeHandler} classes="right no-min">
            <X />
          </Button>
        </Header>
        <ScrollableContent
          autoHide
          autoHeight
          autoHeightMin={300}
          autoHeightMax="100%"
        >
          <ListContainer className="scrollable">
            {values &&
              values.map((value) => <Text tag={TAGS.H3}>{value}</Text>)}
          </ListContainer>
        </ScrollableContent>
      </DialogContainer>
    </Dialog>
  );
};
export default TableDialog;
