import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';

export const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search],
  );

  const searchWithArrays = useMemo(
    () => parse(new URLSearchParams(search).toString()),
    [search],
  );

  return { search: searchAsObject, searchWithArrays, setSearch };
};
