import { v4 as uuidv4 } from 'uuid';
import { makeAutoObservable, runInAction } from 'mobx';
import { orderBy } from 'lodash';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';
import { copyObject } from '@utils/copy';

export class WrapsStore {
  defaultValues = {
    wraps: [],
    wrapElements: [],
    isLoading: false,
    contentOrderId: null,
    wrapName: '',
    frequency: '',
    recipients: [],
    companiesSections: [],
    enabled: false,
    useOldDesignTemplate: false,
    emptyWrap: false,
    skipWeekends: false,
    isSectionChanging: false,
    wrapElementsChanging: false,
    validationErrors: {},
    wrapsLength: 0,
    wrapId: null,
    errors: null,
    sendAtHours: ['', ''],
    sendAtDayOfWeek: '',
    sendAtDayOfMonth: '',
    emailSubject: '',
    additionalEmailSubject: '',
    includeEmptySections: true,
    addTimeToTitle: false,
    newspapersObject: {},
    logo: null,
    logoUrl: null,
    removeLogo: false,
  };

  wraps = [];

  wrapElements = [];

  isLoading = false;

  contentOrderId = null;

  wrapName = 'Wrap Name';

  frequency = 'daily';

  recipients = [];

  companiesSections = [];

  enabled = false;

  useOldDesignTemplate = false;

  emptyWrap = false;

  skipWeekends = false;

  isSectionChanging = false;

  wrapElementsChanging = false;

  validationErrors = {};

  wrapsLength = 0;

  wrapId = null;

  errors = null;

  sendAtHours = ['', ''];

  sendAtMinutes = [0, 0];

  sendAtDayOfWeek = '';

  sendAtDayOfMonth = '';

  emailSubject = '';

  additionalEmailSubject = '';

  includeEmptySections = true;

  addTimeToTitle = false;

  newspapersObject = {};

  newspapers = [];

  logo = null;

  logoUrl = null;

  removeLogo = false;

  constructor() {
    makeAutoObservable(this);
  }

  clearStore = () => {
    const allKeys = Object.keys(this.defaultValues);
    allKeys.forEach((key) => {
      this[key] = this.defaultValues[key];
    });
  };

  getWrapElement = (id) => this.wrapElements.find((e) => e.id === id);

  setLogo = (logo) => {
    this.logo = logo;
  };

  setRemoveLogo = (value) => {
    this.removeLogo = value;
  };

  setAddTimeToTitle = (addTimeToTitle) => {
    this.addTimeToTitle = addTimeToTitle;
  };

  setAdditionalEmailSubject = (additionalEmailSubject) => {
    this.additionalEmailSubject = additionalEmailSubject;
  };

  setEmailSubject = (emailSubject) => {
    this.emailSubject = emailSubject;
  };

  setSendAtHours = (sendAtHour, index) => {
    this.sendAtHours[index] = sendAtHour;
  };

  setSendAtMinutes = (sendAtMinutes, index) => {
    this.sendAtMinutes[index] = sendAtMinutes;
  };

  setSendAtDayOfWeek = (sendAtDayOfWeek) => {
    this.sendAtDayOfWeek = sendAtDayOfWeek;
  };

  setSendAtDayOfMonth = (sendAtDayOfMonth) => {
    this.sendAtDayOfMonth = sendAtDayOfMonth;
  };

  setContentOrderId = (contentOrderId) => {
    this.contentOrderId = contentOrderId;
  };

  setWrapName = (wrapName) => {
    this.wrapName = wrapName;
  };

  setSkipWeekends = (skipWeekends) => {
    this.skipWeekends = skipWeekends;
  };

  setEnabled = (enabled) => {
    this.enabled = enabled;
  };

  setEmptyWrap = (emptyWrap) => {
    this.emptyWrap = emptyWrap;
  };

  setUseOldDesignTemplate = (useOldDesignTemplate) => {
    this.useOldDesignTemplate = useOldDesignTemplate;
  };

  setFrequency = (frequency) => {
    if (frequency === 'twice_a_day') {
      this.sendAtHours = ['', ''];
      this.sendAtMinutes = [0, 0];
    } else {
      this.sendAtHours = [''];
      this.sendAtMinutes = [0];
    }
    this.frequency = frequency;
  };

  setIncludeEmptySections = (includeEmptySections) => {
    this.includeEmptySections = includeEmptySections;
  };

  changeRecipientChecked = (email) => {
    const recipient = this.recipients.find((r) => r.email === email);
    recipient.checked = !recipient.checked;
    this.recipients = this.recipients.sort(
      (a, b) => -(!!a.checked - !!b.checked),
    );
  };

  changeSectionChecked = (wrapElement, sectionId) => {
    this.isSectionChanging = true;
    const section = wrapElement.sections.find((s) => s.id === sectionId);
    section.checked = !section.checked;
    wrapElement.sections = wrapElement.sections.sort(
      (a, b) => -(!!a.checked - !!b.checked),
    );
    this.isSectionChanging = false;
  };

  // eslint-disable-next-line class-methods-use-this
  changeWrapElementHeader = (wrapElement, header) => {
    wrapElement.header = header;
  };

  removeWrapElement = (wrapElementId) => {
    this.wrapElementsChanging = true;
    this.wrapElements = this.wrapElements.filter(
      (element) => element.id !== wrapElementId,
    );
    this.wrapElementsChanging = false;
  };

  createWrapElement = () => {
    this.wrapElements = [
      ...this.wrapElements,
      {
        id: uuidv4(),
        header: '',
        sections: copyObject(this.companiesSections),
        priorityNewspapers: [],
      },
    ];
  };

  save = async () => {
    this.isLoading = true;

    const wrap = this.prepareData();
    this.validateForm();

    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      toast.error('Form has missing fields!');
      this.isLoading = false;
      return;
    }

    try {
      let response;
      if (this.wrapId) {
        response = await API.patch(apiRoutes.wrapRoutes.wrap(this.wrapId), {
          wrap,
        });
      } else {
        response = await API.post(apiRoutes.wrapRoutes.wraps, { wrap });
      }

      if (response.errors) {
        this.errors = response.errors.message;
        this.isLoading = false;
        toast.error('Something went wrong!');
        return;
      }
      routerStore.push(routes.wraps);
      toast.success('Wrap has been successfully saved!');
      this.contentOrderId = null;
    } catch (e) {
      console.log(e);
    }
  };

  prepareData = () => {
    const wrapElements = this.wrapElements.map((element) => {
      const sections = element.sections
        .filter((s) => s.checked)
        .map((s) => s.id);
      return {
        ...element,
        sections,
      };
    });
    return {
      contentOrderId: parseInt(this.contentOrderId, 10),
      name: this.wrapName,
      emailSubject: this.emailSubject,
      additionalEmailSubject:
        this.frequency === 'twice_a_day' && this.additionalEmailSubject !== ''
          ? this.additionalEmailSubject
          : null,
      enabled: this.enabled,
      useOldDesignTemplate: this.useOldDesignTemplate,
      emptyWrap: this.emptyWrap,
      frequency: this.frequency,
      skipWeekends:
        this.frequency === 'daily' || this.frequency === 'twice_a_day'
          ? this.skipWeekends
          : false,
      wrapElements,
      recipients: this.recipients.filter((r) => r.checked).map((r) => r.id),
      sendAtHours: this.sendAtHours,
      sendAtMinutes: this.sendAtMinutes,
      sendAtDayOfWeek:
        this.frequency === 'weekly' ? this.sendAtDayOfWeek : null,
      sendAtDayOfMonth:
        this.frequency === 'monthly' ? this.sendAtDayOfMonth : null,
      includeEmptySections: this.includeEmptySections,
      allowTitleTime: this.addTimeToTitle,
      logo: this.logo,
      removeLogo: this.removeLogo,
    };
  };

  validateHours = () => {
    if (this.sendAtHours === null || this.sendAtHours[0] === '') {
      return 'Choose send at hour';
    }
    if (this.frequency === 'twice_a_day' && this.sendAtHours[1] === '') {
      return 'Choose send at hour';
    }
    if (
      this.frequency === 'twice_a_day' &&
      this.sendAtHours[0] > this.sendAtHours[1]
    ) {
      return 'Second hour must be after the first one';
    }
    return undefined;
  };

  validateMinutes = () => {
    if (this.sendAtMinutes === null || this.sendAtMinutes[0] === undefined) {
      return 'Choose send at minute';
    }
    if (
      this.frequency === 'twice_a_day' &&
      this.sendAtMinutes[1] === undefined
    ) {
      return 'Choose send at minute';
    }
    if (
      this.frequency === 'twice_a_day' &&
      this.sendAtHours[0] === this.sendAtHours[1] &&
      this.sendAtMinutes[0] >= this.sendAtMinutes[1]
    ) {
      return 'Second minute must be after the first one';
    }
    return undefined;
  };

  validateLogo = () => {
    if (!this.removeLogo && this.logo !== null) {
      this.validationErrors.logo = !this.logo.type.includes('image')
        ? 'Logo should be an image'
        : undefined;
    } else {
      this.validationErrors.logo = undefined;
    }
  };

  validateForm = () => {
    this.validateLogo();

    this.validationErrors.name =
      this.wrapName === null || this.wrapName.length === 0
        ? 'Title should not be empty'
        : undefined;

    this.validationErrors.emailSubject =
      this.emailSubject === null || this.emailSubject.length === 0
        ? 'Email subject should not be empty'
        : undefined;

    this.validationErrors.frequency =
      this.frequency === null || this.frequency.length === 0
        ? 'Choose frequency'
        : undefined;

    this.validationErrors.sendAtHours = this.validateHours();

    this.validationErrors.sendAtMinutes = this.validateMinutes();

    this.validationErrors.sendAtDayOfWeek =
      this.frequency === 'weekly' &&
      (this.sendAtDayOfWeek === null || this.sendAtDayOfWeek === '')
        ? 'Choose send at day of week'
        : undefined;

    this.validationErrors.sendAtDayOfMonth =
      this.frequency === 'monthly' &&
      (this.sendAtDayOfMonth === null || this.sendAtDayOfMonth === '')
        ? 'Choose send at day of month'
        : undefined;

    this.validationErrors.recipients =
      this.recipients.filter((r) => r.checked).length === 0
        ? 'Choose recipients'
        : undefined;

    this.validationErrors.wrapElementsAny =
      this.wrapElements.length === 0 ? 'Add content' : undefined;

    this.validationErrors.wrapElementsHeader =
      this.wrapElements.filter((e) => e.header.length === 0).length !== 0
        ? 'Add headers to all wrap elements'
        : undefined;

    this.validationErrors.wrapElementsSections =
      this.wrapElements.filter(
        (e) => e.sections.filter((s) => s.checked).length !== 0,
      ).length === 0
        ? 'Add at least one section to all wrap elements'
        : undefined;
  };

  fetchWraps = async ({
    id,
    limit,
    page,
    enabled,
    searchQuery,
    contractSearchQuery,
  }) => {
    runInAction(() => {
      this.isLoading = true;
      this.wraps = [];
    });

    const query = {
      limit: parseInt(limit ? limit.toString() : 10, 10),
      page: parseInt(page ? page.toString() : 0, 10),
      enabled,
      searchQuery,
      contractSearchQuery,
    };

    if (id) {
      this.contentOrderId = id;
      query.contentOrderId = id;
    }

    try {
      const response = await API.get(apiRoutes.wrapRoutes.wraps, {
        params: query,
      });

      runInAction(() => {
        this.wraps = response.data.wraps;
        this.wrapsLength = response.data.numberOfWraps;
      });
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  fetchRecipients = async (contentOrderId) => {
    this.isLoading = true;
    this.recipients = [];
    const query = {
      content_order_id: this.contentOrderId || contentOrderId,
    };

    try {
      const response = await API.get(apiRoutes.wrapRoutes.recipients, {
        params: query,
      });
      const recipients = response.data.users;
      recipients.forEach((r) => {
        r.checked = false;
      });
      this.recipients = orderBy(recipients, 'email');
      // this.recipients = recipients;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  fetchCompaniesSections = async (contentOrderId) => {
    this.isLoading = true;
    this.companiesSections = [];
    const query = {
      content_order_id: this.contentOrderId || contentOrderId,
    };

    try {
      const response = await API.get(apiRoutes.wrapRoutes.companiesSections, {
        params: query,
      });
      // this.companiesSections = response.data.section_containers;
      this.companiesSections = orderBy(
        response.data.section_containers,
        'name',
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  fetchWrap = async (wrapId) => {
    this.isLoading = true;
    this.wrapId = wrapId;

    try {
      const response = await API.get(apiRoutes.wrapRoutes.wrap(wrapId));
      await this.fillForm(response.data.wrap);
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  fillForm = async (wrap) => {
    this.contentOrderId = wrap.contentOrderId;
    await this.fetchCompaniesSections();
    await this.fetchRecipients();

    this.wrapName = wrap.wrapName;
    this.emailSubject = wrap.emailSubject;
    this.logoUrl = wrap.logoUrl;
    this.additionalEmailSubject = wrap.additionalEmailSubject;
    this.sendAtHours = wrap.sendAtHours !== null ? wrap.sendAtHours : ['', ''];
    this.sendAtMinutes = wrap.sendAtMinutes ? wrap.sendAtMinutes : [0, 0];
    this.sendAtDayOfWeek =
      wrap.sendAtDayOfWeek !== null ? wrap.sendAtDayOfWeek : '';
    this.sendAtDayOfMonth =
      wrap.sendAtDayOfMonth !== null ? wrap.sendAtDayOfMonth : '';
    this.enabled = wrap.enabled;
    this.emptyWrap = wrap.emptyWrap;
    this.useOldDesignTemplate = wrap.useOldDesignTemplate;
    this.frequency = wrap.frequency;
    this.addTimeToTitle = wrap.allowTitleTime;
    this.skipWeekends = wrap.skipWeekends;
    this.includeEmptySections = wrap.includeEmptySections;
    wrap.wrapElements.forEach((element) => {
      const sectionIds = element.sections;
      element.priorityNewspapers = element.priorityNewspapers.map(
        ({ id, title }) => ({ value: id, label: title }),
      );
      element.id = element.id.toString();
      element.sections = copyObject(this.companiesSections);
      sectionIds.forEach((id, i) => {
        if (element.sections) {
          const section = element.sections.find((s) => s.id === id);
          if (section) {
            section.checked = true;
            const oldIndex = element.sections.findIndex((s) => s.id === id);
            if (oldIndex !== i) {
              this.moveSection(element.sections, oldIndex, i);
            }
          }
        }
      });
    });
    this.wrapElements = wrap.wrapElements;
    this.recipients.forEach((recipient) => {
      if (wrap.recipients.indexOf(recipient.id) !== -1) {
        recipient.checked = true;
      }
    });
    this.recipients = this.recipients.sort(
      (a, b) => -(!!a.checked - !!b.checked),
    );
  };

  moveWrapElement = (oldIndex, newIndex) => {
    const wrapElementToMove = this.wrapElements[oldIndex];
    this.wrapElements.splice(oldIndex, 1);
    this.wrapElements.splice(newIndex, 0, wrapElementToMove);
  };

  moveSection = (sections, oldIndex, newIndex) => {
    if (newIndex >= 0) {
      this.isSectionChanging = true;
      const sectionsToMove = sections[oldIndex];
      sections.splice(oldIndex, 1);
      sections.splice(newIndex, 0, sectionsToMove);
      this.isSectionChanging = false;
    }
  };

  changeWrapEnabled = async (row) => {
    try {
      await API.post(apiRoutes.wrapRoutes.wrapToggle(row.id));
      row.enabled = !row.enabled;
      toast.success('Wrap successfully changed!');
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.clearStore();
      await this.fetchWraps({});
    }
  };

  removeWrap = async (id) => {
    try {
      await API.delete(apiRoutes.wrapRoutes.wrap(id));
      toast.success('Wrap successfully removed!');
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.clearStore();
      await this.fetchWraps({});
    }
  };

  fetchNewspapers = async () => {
    this.isLoading = true;
    try {
      const response = await API.get(
        apiRoutes.articleSourceRequests.newspapers,
      );
      this.newspapersObject = {};
      this.newspapers = response.data.newspapers.map(({ id, title }) => {
        this.newspapersObject[id] = title;
        return {
          value: id,
          label: `${title} (${id})`,
        };
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  copyNewspapers = (fromId, wrapElementToCopyTo) => {
    const wrapElementToCopyFrom = this.wrapElements.find(
      ({ id }) => id === fromId,
    );
    if (
      wrapElementToCopyFrom &&
      wrapElementToCopyFrom.priorityNewspapers.length
    ) {
      wrapElementToCopyTo.priorityNewspapers = [
        ...wrapElementToCopyFrom.priorityNewspapers,
      ];
    } else {
      toast.error('Cannot copy newspapers');
    }
  };
}

export default new WrapsStore();
