import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { SubTitle, ContentWrapper, SettingsRow, TrapBox } from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import DetailsMobile from '@components/DetailsMobile';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';
import routes from '@routes';
import Button from '@components/Button';
import CopiableText from '@components/CopiableText';
import { detailsFields } from '@containers/Users/constants';

const User = ({
  usersStore: { user, fetchUser, isLoading, clearUser, companiesForUser, fetchImpersonateLink, impersonate },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const navigate = useNavigate();
  const { search } = useCustomSearchParams();

  useEffect(() => {
    if (search.id) {
      fetchUser(search.id);
    }
    return () => clearUser();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  const companies = {};
  companiesForUser.forEach(({ value, label }) => {
    companies[value] = label;
  });
  const fields = detailsFields(companies);

  const impersonateDetails = [{id: 'link', label: 'Impresonate link', format: (value) => (<CopiableText value={value} />)}];

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>User: {user.id}</Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={user} fields={fields} big />
      ) : (
        <Details data={user} fields={fields} />
      )}
      <SettingsRow className="small-margin">
        <Button onClick={() => navigate(`${routes.userEditor}?id=${search.id}`)}>
          Edit
        </Button>
        <Button theme="quaternary" onClick={fetchImpersonateLink}>
          Impersonate
        </Button>
        {(impersonate?.link === undefined && impersonate?.isLoading) && (
          <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
        )}
      </SettingsRow>
      {impersonate?.link !== undefined && (
        <TrapBox>
          {impersonate?.isLoading && (<Preloader display={POSITIONS.HORIZONRAL} overlay />)}
          {isMobile ? (
            <DetailsMobile data={impersonate} fields={impersonateDetails} big />
          ) : (
            <Details data={impersonate} fields={impersonateDetails} />
          )}
        </TrapBox>
      )}
    </ContentWrapper>
  );
};

export default inject('usersStore')(observer(User));
