import styled from '@emotion/styled';

export const Label = styled.div`
  display: flex;
  font-weight: bold;
  min-width: 250px;
  width: 250px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.font.default};
`;

export const Value = styled.div`
  max-height: 150px;
  overflow-y: auto;
  font-weight: bold;
  line-height: 24px;
  color: ${({ theme }) => theme.font.default};
`;

export const Field = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  cursor: ${({ clickable }) => (clickable ? `pointer` : '')};
  align-items: center;
`;
