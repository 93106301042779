import React from 'react';
import { PlayerPlay, Trash } from 'tabler-icons-react';

export const columnsFunc = (newspapersObject, extractMeta) => [
  {
    label: 'Fetch type',
    hide: true,
    id: 'fetch_type',
    minWidth: 120,
    maxWidth: 120,
  },
  {
    label: 'Title',
    id: 'title',
    minWidth: 60,
    maxWidth: 160,
  },
  {
    label: 'Display title',
    id: 'display_title',
    hide: true,
    minWidth: 70,
    maxWidth: 90,
  },
  {
    label: 'Url',
    id: 'url',
    hide: true,
    minWidth: 90,
    maxWidth: 130,
  },
  {
    label: 'Url for analysts',
    id: 'url_for_analysts',
    minWidth: 90,
    maxWidth: 110,
  },
  {
    label: 'Newspaper',
    id: 'newspaper_id',
    minWidth: 100,
    maxWidth: 120,
    format: (id, row) => newspapersObject[id] || row.newspaper_name,
  },
  {
    label: 'Category',
    id: 'category',
    minWidth: 80,
    maxWidth: 100,
    align: 'left',
  },
  {
    label: 'Lexis Nexis source code',
    id: 'meta',
    minWidth: 105,
    maxWidth: 105,
    format: (meta) => extractMeta(meta, 'LEXIS_NEXIS'),
  },
  {
    label: 'Media type',
    id: 'media_type',
    hide: true,
    maxWidth: 70,
    minWidth: 70,
  },
  {
    label: 'Direct LN links allowed',
    id: 'direct_ln_links_allowed',
    minWidth: 80,
    maxWidth: 80,
    format: (value) => value.toString(),
  },
  {
    label: 'Public links in briefings',
    id: 'public_article_links_in_briefings',
    minWidth: 80,
    maxWidth: 80,
    format: (value) => value.toString(),
  },
  {
    label: 'Actions',
    id: 'actions',
    minWidth: 100,
  },
];

export const actions = (remove, convert, clear) => [
  {
    icon: () => <Trash strokeWidth={1} size={24} />,
    onClick: (row) => {
      remove(row.id);
    },
  },
  {
    icon: ({ converted }) =>
      !converted ? (
        <PlayerPlay
          strokeWidth={1}
          size={24}
          data-tip="Convert to article source"
          data-place="bottom"
        />
      ) : null,
    onClick: (row) => {
      if (!row.converted) {
        convert(row.id);
        clear();
      }
    },
  },
];

export const defaultValues = {
  fetch_type: 'LEXIS_NEXIS',
  url: 'https://metabase.moreover.com/api/v10/articles',
  public_article_links_in_briefings: true,
  default_preview_mode: 'plain_text',
  direct_ln_links_allowed: false,
  default_preview: 'link',
  enabled_for_b2b_content_orders: false,
};
