import styled from '@emotion/styled';
import Switch from 'react-switch';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const Container = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 10px;

  ${({ noGap }) =>
    noGap === true
      ? css`
          grid-column-gap: 0px;
        `
      : null}

  @media screen and ${breakpoint.md} {
    flex-direction: ${({ mobileColumn }) => (mobileColumn ? 'column' : null)};
    gap: 10px;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.font.primary};
  font-size: 14px;
`;

export const CustomSwitch = styled(Switch)`
  ${({ offColor, checked }) =>
    offColor !== '#888' && checked !== true
      ? css`
          .react-switch-bg {
            opacity: 0.4;
          }
        `
      : null}
`;
