import { makeAutoObservable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class SwitchArticleSourcesStore {
  isLoading = false;

  articleSources = [];

  selectArticleSources = [];

  delayedJobId = null;

  delayedJobStatus = null;

  constructor() {
    makeAutoObservable(this);
  }

  pollForStatus = async () => {
    try {
      const { data } = await API.get(
        apiRoutes.switchASJobStatus(this.delayedJobId),
      );
      this.delayedJobStatus = data.state;

      if (this.delayedJobStatus === 'in progress') {
        setTimeout(() => {
          this.pollForStatus().catch(console.error);
        }, 1000);
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };

  createJob = async ({ sourceArticleSourceId, destArticleSourceId }) => {
    this.isLoading = true;
    try {
      const { data } = await API.post(apiRoutes.switchArticleSources, {
        source_article_source_id: sourceArticleSourceId,
        dest_article_source_id: destArticleSourceId,
      });
      this.delayedJobId = data.delayed_job_id;
      this.pollForStatus().catch(console.error);
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  fetchArticleSources = async () => {
    this.isLoading = true;
    try {
      const { data } = await API.get(
        `${apiRoutes.articleSources.index}?disabled=false&limit=10000`,
      );
      this.articleSources = data.article_sources;
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  prepareSelectOptions = async () => {
    await this.fetchArticleSources();
    this.selectArticleSources = this.articleSources.map(({ id, title }) => ({
      label: `[${id}] - ${title}`,
      value: id,
    }));
  };
}

export default new SwitchArticleSourcesStore();
