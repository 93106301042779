import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';
import { passwordValidator } from '@utils/passwordValidator';

export class UsersStore {
  isLoading = false;

  users = [];

  usersCount = 0;

  user = {};

  impersonate = { isLoading: false };

  validationErrors = {};

  subscriptionPlans = [];

  isLoadingSubscriptionPlans = false;

  contentOrders = [];

  isLoadingContentOrders = false;

  contracts = [];

  isLoadingContracts = false;

  companiesForUser = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchUsers = async ({ limit, page, searchQuery } = {}) => {
    this.isLoading = true;

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.users.index, {
        params: query,
      });
      runInAction(() => {
        this.users = response.data.users;
        this.usersCount = response.data.users_count;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchUser = async (id) => {
    this.isLoading = true;
    try {
      const response = await API.get(apiRoutes.users.record(id));
      runInAction(() => {
        this.user = response.data.user;
        this.companiesForUser = response.data.companies.map((company) => ({
          value: company[1],
          label: company[0],
        }));
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  setUser = (valueObj) => {
    this.user = { ...this.user, ...valueObj };
    this.impersonate = { isLoading: false };
  };

  validateUser = (create) => {
    this.validationErrors.email =
      this.user.email === null || this.user.email.length === 0
        ? 'Email should not be empty'
        : undefined;

    if (create) {
      this.validationErrors.password =
        this.user.password === null || this.user.password.length === 0
          ? 'Password should not be empty'
          : undefined;

      this.validationErrors.password_confirmation =
        this.user.password_confirmation === null ||
        this.user.password_confirmation.length === 0
          ? 'Password confirmation should not be empty'
          : undefined;

      this.validationErrors.password_confirmation =
        this.user.password_confirmation !== this.user.password
          ? 'Passwords do not match'
          : undefined;

      this.validationErrors.password = passwordValidator(this.user.password);
    }
  };

  updateUser = async (create) => {
    this.isLoading = true;
    this.validateUser(create);
    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }
    let response = null;
    try {
      if (create) {
        response = await API.post(apiRoutes.users.index, {
          user: this.user,
        });
      } else {
        response = await API.patch(apiRoutes.users.record(this.user.id), {
          user: this.user,
        });
      }

      if (response.data.message) {
        if (create) {
          routerStore.push(routes.users);
        } else {
          routerStore.push(`${routes.user}?id=${this.user.id}`);
        }
        toast.success(response.data.message);
      } else {
        toast.error(response.data.error);
      }
    } catch (e) {
      this.error = e;
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }

    this.isLoading = false;
  };

  fetchSubscriptionPlans = async () => {
    this.isLoadingSubscriptionPlans = true;
    try {
      const response = await API.get(apiRoutes.subscriptionPlans);
      runInAction(() => {
        this.subscriptionPlans = response.data.subscriptionPlans.map(
          ({ name, id }) => ({
            label: name,
            value: id,
          }),
        );
        this.isLoadingSubscriptionPlans = false;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
      this.isLoadingSubscriptionPlans = false;
    }
  };

  fetchContentOrders = async () => {
    this.isLoadingContentOrders = true;
    try {
      const response = await API.get(apiRoutes.contentOrders);
      runInAction(() => {
        this.contentOrders = response.data.map((contentOrder) => ({
          value: contentOrder[0],
          label: contentOrder[1],
        }));
        this.isLoadingContentOrders = false;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
      this.isLoadingContentOrders = false;
    }
  };

  fetchContracts = async () => {
    this.isLoadingContracts = true;

    const query = {
      limit: 'all',
    };

    try {
      const response = await API.get(apiRoutes.contractsForSelect, {
        params: query,
      });
      runInAction(() => {
        this.contracts = response.data.contracts.map((contentOrder) => ({
          value: contentOrder[0],
          label: contentOrder[1],
        }));
        this.isLoadingContracts = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchImpersonateLink = async () => {
    if (this.user?.id === undefined) {
      return;
    }
    this.impersonate.isLoading = true;

    try {
      const response = await API.get(apiRoutes.users.impersonate(this.user.id));
      runInAction(() => {
        this.impersonate.link = response.data.link;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.impersonate.isLoading = false;
    }
  };

  clearUser = () => {
    this.user = {};
    this.impersonate = { isLoading: false };
  };
}

export default new UsersStore();
