import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import Input from '@components/Input';
import { CollapsibleFilters } from '@components/CollapsibleFilters';
import Select from '@components/Select';
import DateRangeSelector from '@components/DateRangeSelector';

export const SelectContainer = styled.div`
  min-width: 150px;
  height: 65px;

  &.big {
    min-width: 300px;
  }
`;

const Filters = ({
  setSearchQuery,
  searchQuery,
  search,
  clear,
  isOpen,
  setIsOpen,
  filters,
  containerClass,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  return (
    <CollapsibleFilters
      clear={clear}
      search={search}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      containerClass={containerClass}
    >
      {filters.map((filter, idx) => (
        <Fragment key={`filter-${idx}`}>
          {filter.type === 'input' ? (
            <Input
              value={searchQuery[filter.id] || ''}
              onChange={(value) => setSearchQuery({ [filter.id]: value })}
              label={filter.label}
              placeholder={`${filter.placeholder || filter.label}...`}
              noMargin
              maxWidth={150}
              onKeyDown={handleKeyDown}
            />
          ) : null}
          {filter.type === 'select' ? (
            <SelectContainer className={filter.className}>
              <Select
                isLoading={filter.isLoading}
                isClearable={filter.isClearable}
                label={filter.label}
                placeholder={`${filter.placeholder || filter.label}...`}
                onChange={(value) => setSearchQuery({ [filter.id]: value })}
                value={searchQuery[filter.id]}
                options={filter.options}
                onMenuOpen={() => setIsOpen(true)}
              />
            </SelectContainer>
          ) : null}
          {filter.type === 'dateRange' ? (
            <DateRangeSelector
              label={filter.label}
              startDate={searchQuery[filter.startDateId]}
              endDate={searchQuery[filter.endDateId]}
              setStartDate={(value) =>
                setSearchQuery({ [filter.startDateId]: value })
              }
              setEndDate={(value) =>
                setSearchQuery({ [filter.endDateId]: value })
              }
              showTimeSelect={filter.showTime}
            />
          ) : null}
        </Fragment>
      ))}
    </CollapsibleFilters>
  );
};

export default Filters;
