import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class SubscriptionsStore {
  isLoading = false;

  subscriptions = [];

  subscription = {
    content_orders: [],
  };

  subscriptionsCount = 0;

  states = [];

  isLoadingStates = false;

  subscriptionPlans = [];

  isLoadingSubscriptionPlans = false;

  admins = [];

  isLoadingAdmins = false;

  subscriptionsForSelect = [];

  isLoadingSubscriptionsForSelect = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchSubscriptions = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;
    this.companies = [];

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.subscriptions.index, {
        params: query,
      });
      runInAction(() => {
        this.subscriptions = response.data.contracts;
        this.subscriptionsCount = response.data.contractsCount;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchSubscription = async (id, showLoader = true) => {
    if (showLoader) {
      this.isLoading = true;
    }
    this.subscription = {
      content_orders: [],
    };

    try {
      const response = await API.get(apiRoutes.subscriptions.show(id));
      runInAction(() => {
        this.subscription = response.data.contract;
        if (showLoader) {
          this.isLoading = false;
        }
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    }
  };

  fetchStates = async () => {
    this.isLoadingStates = true;
    try {
      const response = await API.get(apiRoutes.subscriptionStates);
      runInAction(() => {
        this.states = response.data.map((state) => ({
          label: state[0],
          value: state[1],
        }));
        this.isLoadingStates = false;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
      this.isLoadingStates = false;
    }
  };

  fetchSubscriptionPlans = async () => {
    this.isLoadingSubscriptionPlans = true;
    try {
      const response = await API.get(apiRoutes.subscriptionPlans);
      runInAction(() => {
        this.subscriptionPlans = response.data.subscriptionPlans.map(
          ({ name, id }) => ({
            label: name,
            value: id,
          }),
        );
        this.isLoadingSubscriptionPlans = false;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
      this.isLoadingSubscriptionPlans = false;
    }
  };

  fetchAdmins = async () => {
    this.isLoadingAdmins = true;
    try {
      const response = await API.get(apiRoutes.admins);
      runInAction(() => {
        this.admins = response.data.admins.map((admin) => ({
          label: admin[1],
          value: admin[0],
        }));
        this.isLoadingAdmins = false;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
      this.isLoadingAdmins = false;
    }
  };

  fetchSubscriptionsForSelect = async () => {
    this.isLoadingSubscriptionsForSelect = true;

    const query = {
      only_active: true,
    };

    try {
      const response = await API.get(apiRoutes.contractsForSelect, {
        params: query,
      });
      runInAction(() => {
        this.subscriptionsForSelect = response.data.contracts.map(
          (contract) => ({
            value: contract[0],
            label: `(${contract[0]}) ${contract[1]}`,
          }),
        );
        this.isLoadingSubscriptionsForSelect = false;
      });
    } catch (e) {
      console.log(e);
    }
  };



  removeUserFromSubscription = async (userId) => {
    try {
      await API.delete(`${apiRoutes.subscriptions.removeUser(this.subscription.id)}?user_id=${userId}`);
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      await this.fetchSubscription(this.subscription.id);
    }
  };
}

export default new SubscriptionsStore();
