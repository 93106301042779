import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Input from '@components/Input';
import Table from '@components/Table';
import useDebounce from '@root/hooks/useDebounce';
import Preloader from '@components/Preloader';
import Button from '@components/Button';
import { ButtonContainer } from '@containers/Wraps/style';
import routes from '@routes';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { listColumns } from '@containers/Companies/constants';

const Companies = ({
  companiesStore: {
    fetchCompanies,
    companies,
    isLoading,
    companiesCount,
    setCompanyToEdit,
  },
  authStore: { darkMode },
  sidebarStore: { menuCollapsed },
}) => {
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const hideLogo = matchBreakpoint(BREAKPOINTS.LG) && !menuCollapsed;

  const columns = useMemo(() => listColumns(darkMode), [darkMode]);
  const [columnsInTable, setColumnsInTable] = useState(columns);

  useEffect(() => {
    setColumnsInTable(
      columns.map((column) => {
        if (column.id === 'meta.icon' && hideLogo) {
          column.hide = true;
        }
        return column;
      }),
    );
  }, [hideLogo]);

  const columnActions = {
    id: (row) => setCompanyToEdit(row),
  };

  useEffect(() => {
    clear();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchCompanies({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchCompanies({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery('');
    await fetchCompanies({
      limit: rowsPerPage,
      page: 0,
      searchQuery: '',
    });
  };

  useDebounce(searchQuery, 300, () => {
    handleChangePage(0);
  });

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <FiltersContainer>
        <Input
          onChange={setSearchQuery}
          value={searchQuery}
          maxWidth={hideLogo && !isMobile ? 100 : 250}
          label="Name"
          placeholder="Name..."
        />

        {isMobile ? null : (
          <ButtonContainer>
            <Button onClick={() => navigate(routes.companiesCreator)}>
              Create companies
            </Button>
          </ButtonContainer>
        )}
        <ButtonContainer>
          <Button onClick={() => navigate(routes.companyCreator)}>
            Create company
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      <Table
        rowsPerPageOptions={[10, 50, 100]}
        count={companiesCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={columnsInTable}
        data={companies}
        columnActions={columnActions}
        mobileBig
      />
    </ContentWrapper>
  );
};

export default inject(
  'companiesStore',
  'authStore',
  'sidebarStore',
)(observer(Companies));
