import React from 'react';
import { inject, observer } from 'mobx-react';
import FrequencySelector, {
  MODES,
} from '@containers/Wraps/components/FrequencySelector';
import { CheckBoxContainer } from '@containers/Wraps/style';
import Control from '@components/Control';
import { SettingsRow } from '@styles/globalStyles';

const FrequencySelectors = ({ frequency, skipWeekends, setSkipWeekends }) => (
  <>
    <SettingsRow>
      <FrequencySelector mode={MODES.frequency} label="Frequency" />
      {(frequency === 'daily' || frequency === 'twice_a_day') && (
        <CheckBoxContainer>
          <Control
            name="skip_weekends"
            value={skipWeekends}
            checked={skipWeekends}
            onChange={(item) => setSkipWeekends(item.target.checked)}
            type="checkbox"
            label="Skip weekends"
          />
        </CheckBoxContainer>
      )}
    </SettingsRow>
    {frequency !== 'twice_a_day' && (
      <SettingsRow>
        <FrequencySelector
          mode={MODES.sendAtHours}
          label="Hour"
          sendAtHourIndex={0}
        />
        <FrequencySelector
          mode={MODES.sendAtMinutes}
          label="Minute"
          sendAtMinutesIndex={0}
        />
        {frequency === 'weekly' && (
          <FrequencySelector mode={MODES.sendAtDayOfWeek} label="Day of week" />
        )}
        {frequency === 'monthly' && (
          <FrequencySelector
            mode={MODES.sendAtDayOfMonth}
            label="Day of month"
          />
        )}
      </SettingsRow>
    )}
    {frequency === 'twice_a_day' && (
      <>
        <SettingsRow>
          <FrequencySelector
            mode={MODES.sendAtHours}
            label="Hour 1"
            sendAtHourIndex={0}
          />
          <FrequencySelector
            addNoneOption={false}
            mode={MODES.sendAtMinutes}
            label="Minute 1"
            sendAtMinutesIndex={0}
          />
        </SettingsRow>
        <SettingsRow>
          <FrequencySelector
            mode={MODES.sendAtHours}
            label="Hour 2"
            sendAtHourIndex={1}
          />
          <FrequencySelector
            addNoneOption={false}
            mode={MODES.sendAtMinutes}
            label="Minute 2"
            sendAtMinutesIndex={1}
          />
        </SettingsRow>
      </>
    )}
  </>
);

export default inject('wrapsStore')(observer(FrequencySelectors));
