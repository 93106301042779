import { useEffect, useRef, useState } from 'react';
import { breakpoint, BREAKPOINTS } from '@styles/breakpoints';

function useBreakpoint(delay = 300) {
  const debounce = useRef(null);
  const [width, setWidth] = useState();

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(debounce.current);
      debounce.current = setTimeout(() => {
        setWidth(window.innerWidth);
      }, delay);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [delay]);

  const matchBreakpoint = (bp) => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(breakpoint[bp]).matches;
    }
    return null;
  };

  return { matchBreakpoint, width, BREAKPOINTS };
}

export default useBreakpoint;
