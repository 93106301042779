import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import {
  ContentWrapper,
  Paper,
  SettingsColumn,
  SettingsRow,
  StyledTooltip,
} from '@styles/globalStyles';
import Input, { INPUT_TYPES } from '@components/Input';
import {
  ContentOrderLabel,
  CheckBoxContainer,
  InfoCircleIcon,
  TrashIconLogo,
} from '@containers/Wraps/style';
import Text, { TAGS } from '@components/Text';
import Control from '@components/Control';
import FrequencySelectors from '@containers/Wraps/components/FrequencySelectors';
import RecipientsList from '@containers/Wraps/components/RecipientsList';
import DndWrapElementsList from '@containers/Wraps/components/DndWrapElementsList';
import Preloader from '@components/Preloader';
import Button from '@components/Button';
import routes from '@routes';
import ErrorMessage from '@containers/Wraps/components/ErrorMessage';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { CompanyImage, Label } from '@containers/Companies/style';
import { getBase64 } from '@utils/getBase64';

const WrapsEditor = ({
  wrapsStore: {
    setWrapName,
    wrapName,
    enabled,
    emptyWrap,
    setEnabled,
    setEmptyWrap,
    validationErrors,
    emailSubject,
    setEmailSubject,
    frequency,
    skipWeekends,
    setSkipWeekends,
    clearStore,
    setContentOrderId,
    fetchCompaniesSections,
    fetchRecipients,
    fetchWrap,
    contentOrderId,
    includeEmptySections,
    setIncludeEmptySections,
    isLoading,
    createWrapElement,
    setAdditionalEmailSubject,
    additionalEmailSubject,
    save,
    addTimeToTitle,
    setAddTimeToTitle,
    fetchNewspapers,
    setUseOldDesignTemplate,
    useOldDesignTemplate,
    logo,
    setLogo,
    logoUrl,
    setRemoveLogo,
    removeLogo,
    validateLogo,
  },
  sidebarStore: { menuCollapsed },
}) => {
  const theme = useTheme();
  const hiddenLogoInputRef = useRef(null);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = menuCollapsed
    ? matchBreakpoint(BREAKPOINTS.MD)
    : matchBreakpoint(BREAKPOINTS.XXL);
  const navigate = useNavigate();

  useEffect(() => {
    clearStore();
    fetchNewspapers();
    const urlParams = new URLSearchParams(window.location.search);
    const paramsContentOrderId = urlParams.get('contentOrderId');
    const wrapId = urlParams.get('wrapId');
    if (paramsContentOrderId) {
      setContentOrderId(paramsContentOrderId);
      fetchCompaniesSections();
      fetchRecipients();
    }
    if (wrapId) {
      fetchWrap(wrapId);
    }
    return () => clearStore();
  }, []);

  const cancel = () => {
    navigate(routes.wraps);
  };

  const handleHiddenInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  if (isLoading) {
    return <Preloader overlay />;
  }

  const checkboxes = (
    <>
      <CheckBoxContainer>
        <Control
          name="enabled"
          type="checkbox"
          label="Enabled"
          value={enabled}
          checked={enabled}
          onChange={(item) => setEnabled(item.target.checked)}
        />
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Control
          name="old_design_template"
          type="checkbox"
          label="Use old design template"
          value={useOldDesignTemplate}
          checked={useOldDesignTemplate}
          onChange={(item) => setUseOldDesignTemplate(item.target.checked)}
        />
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Control
          name="empty_wrap"
          value={emptyWrap}
          checked={emptyWrap}
          onChange={(item) => setEmptyWrap(item.target.checked)}
          type="checkbox"
          label="Allow empty wrap"
        />
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Control
          name="include_empty_sections"
          value={includeEmptySections}
          checked={includeEmptySections}
          onChange={(item) => setIncludeEmptySections(item.target.checked)}
          type="checkbox"
          label="Show empty sections"
        />
      </CheckBoxContainer>
    </>
  );

  const handleLogoInput = async (file) => {
    const base64File = await getBase64(file);
    setRemoveLogo(false);
    setLogo({
      base64Logo: base64File,
      name: file.name,
      type: file.type,
    });
    validateLogo();
  };

  return (
    <ContentWrapper>
      <StyledTooltip />
      <ContentOrderLabel className="uppercase">
        <Text tag={TAGS.H2}>main wrap settings</Text>
      </ContentOrderLabel>
      <Paper>
        <ContentOrderLabel className="uppercase">
          <Text tag={TAGS.H2}>Content Order id: {contentOrderId}</Text>
        </ContentOrderLabel>
        <SettingsRow>
          <Input
            error={validationErrors.name}
            maxWidth={300}
            value={wrapName}
            onChange={setWrapName}
            label="Name"
            placeholder="Wrap name..."
          />
          {!isMobile ? checkboxes : null}
        </SettingsRow>
        {isMobile ? <SettingsColumn>{checkboxes}</SettingsColumn> : null}
        <SettingsRow className="small-margin">
          <Input
            maxWidth={300}
            value={emailSubject}
            onChange={setEmailSubject}
            label="Email subject"
            placeholder="Email subject..."
            error={validationErrors.emailSubject}
          />
        </SettingsRow>
        <SettingsRow className="no-margin">
          <Control
            name="add_time_to_title"
            value={addTimeToTitle}
            checked={addTimeToTitle}
            onChange={(item) => setAddTimeToTitle(item.target.checked)}
            type="checkbox"
            label="Add time to title"
          />
        </SettingsRow>
        <FrequencySelectors
          frequency={frequency}
          skipWeekends={skipWeekends}
          setSkipWeekends={setSkipWeekends}
        />
        {frequency === 'twice_a_day' && (
          <SettingsRow>
            <Input
              maxWidth={300}
              value={additionalEmailSubject}
              onChange={setAdditionalEmailSubject}
              label="Email subject for second hour"
              placeholder="Email subject for second hour..."
            />
          </SettingsRow>
        )}
        <Label>Logo</Label>
        <SettingsRow className="small-margin">
          {!removeLogo && (logoUrl || (logo && logo.base64Logo)) ? (
            <>
              <CompanyImage
                darkMode
                alt="logo"
                src={logo?.base64Logo || logoUrl}
              />
              <TrashIconLogo
                color={theme.font.default}
                onClick={() => {
                  setRemoveLogo(true);
                  setLogo(null);
                }}
              />
            </>
          ) : (
            <Label>(No logo)</Label>
          )}
        </SettingsRow>
        {validationErrors.logo ? (
          <ErrorMessage className="left">{validationErrors.logo}</ErrorMessage>
        ) : null}
        <SettingsRow className="small-margin">
          <Button onClick={() => handleHiddenInput(hiddenLogoInputRef)}>
            Upload logo
          </Button>
          <Input
            inputRef={hiddenLogoInputRef}
            inputType={INPUT_TYPES.FILE}
            maxWidth={300}
            onChange={handleLogoInput}
          />
        </SettingsRow>
      </Paper>
      <ContentOrderLabel className="uppercase">
        <Text tag={TAGS.H2}>Recipients</Text>
      </ContentOrderLabel>
      <RecipientsList />
      <ContentOrderLabel className="uppercase">
        <Text tag={TAGS.H2}>Content</Text>
        <InfoCircleIcon
          size={20}
          data-tip="You can change elements order using drag and drop."
          data-place="bottom"
        />
        {validationErrors.wrapElementsAny ||
        validationErrors.wrapElementsHeader ||
        validationErrors.wrapElementsSections ? (
          <ErrorMessage>
            {validationErrors.wrapElementsAny}{' '}
            {validationErrors.wrapElementsHeader}{' '}
            {validationErrors.wrapElementsSections}
          </ErrorMessage>
        ) : null}
      </ContentOrderLabel>
      <DndWrapElementsList />
      <SettingsRow>
        <Button onClick={createWrapElement}>Add Wrap Element</Button>
        <Button classes="right" onClick={save}>
          Save
        </Button>
        <Button onClick={cancel}>Cancel</Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('wrapsStore', 'sidebarStore')(observer(WrapsEditor));
