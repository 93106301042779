import moment from 'moment';

export const severitySelect = [
  { label: 'Critical', value: 'critical' },
  { label: 'Notification', value: 'notification' },
  { label: 'Info', value: 'info' },
];

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'severity',
    label: 'Severity',
    maxWidth: 150,
  },
  {
    id: 'valid_from',
    label: 'Valid from',
    format: (value) => moment(value).format('LLL').toString(),
    maxWidth: 150,
  },
  {
    id: 'valid_to',
    label: 'Valid to',
    format: (value) => moment(value).format('LLL').toString(),
    maxWidth: 150,
  },
  {
    label: 'Actions',
    id: 'actions',
    maxWidth: 80,
  },
];

export const editorFields = [
  {
    id: 'description',
    label: 'Description',
    component: 'input',
    type: 'text',
  },
  {
    id: 'severity',
    label: 'Severity',
    component: 'select',
    options: severitySelect,
  },
  {
    startDateId: 'valid_from',
    endDateId: 'valid_to',
    showTime: true,
    label: 'Valid',
    component: 'dateRange',
  },
];

export const detailsFields = [
  { id: 'description' },
  { id: 'severity' },
  {
    id: 'valid_from',
    format: (value) => moment(value).format('LLL').toString(),
  },
  {
    id: 'valid_to',
    format: (value) => moment(value).format('LLL').toString(),
  },
];

export const filters = [
  { id: 'description', label: 'Desciption', type: 'input' },
  {
    id: 'severity',
    label: 'Severity',
    type: 'select',
    options: severitySelect,
    isClearable: true,
  },
  {
    startDateId: 'valid_from_start_date',
    endDateId: 'valid_from_end_date',
    label: 'Valid from',
    showTime: true,
    type: 'dateRange',
  },
  {
    startDateId: 'valid_to_start_date',
    endDateId: 'valid_to_end_date',
    showTime: true,
    label: 'Valid to',
    type: 'dateRange',
  },
];
