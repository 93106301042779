import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper, SubTitle } from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Button from '@components/Button';
import Select from '@components/Select';
import {
  ButtonContainer, CollapseContainer,
  SelectContainer,
} from '@containers/ContentOrders/style';
import { mapForSelect } from '@utils/subscriptions';
import Details from '@components/Details';
import useBreakpoint from '@root/hooks/useBreakpoint';
import DetailsMobile from '@components/DetailsMobile';
import Text, { TAGS } from '@components/Text';
import routes from '@routes';
import { detailsFields } from '@containers/ContentOrders/constants';
import Input from "@components/Input";

const ContentOrder = ({
  subscriptionsStore: {
    isLoading: isLoadingSubscriptionsStore,
    fetchSubscriptionsForSelect,
    subscriptionsForSelect,
    fetchSubscription,
    subscription,
  },
  contentOrdersStore: {
    isLoading: isLoadingContentOrdersStore,
    fetchContentOrder,
    contentOrder,
    cloneArticleSource,
    clearContentOrder,
    bulkAddSources
  },
}) => {
  const navigate = useNavigate();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [openClone, setOpenClone] = useState(false);
  const [openBulkAdd, setOpenBulkAdd] = useState(false);
  const [sourcesToAdd, setSourcesToAdd] = useState('');
  const [chosenSubscription, setChosenSubscription] = useState(null);
  const [chosenContentOrder, setChosenContentOrder] = useState(null);
  const { search } = useCustomSearchParams();

  useEffect(() => {
    if (search.id) {
      fetchContentOrder(search.id);
      fetchSubscriptionsForSelect();
    }
  }, []);

  useEffect(() => {
    if(chosenSubscription) {
      fetchSubscription(chosenSubscription, false);
    }
  }, [chosenSubscription]);

  const contentOrders = useMemo(
    () => (subscription ? mapForSelect(subscription.content_orders) : []),
    [subscription],
  );

  if (isLoadingSubscriptionsStore || isLoadingContentOrdersStore) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>
          {`${contentOrder.name} - ${contentOrder.contract_name}`}
        </Text>
      </SubTitle>
      {isMobile ? (
        <DetailsMobile data={contentOrder} fields={detailsFields} big />
      ) : (
        <Details data={contentOrder} fields={detailsFields} />
      )}
      <ButtonContainer className={isMobile ? 'column' : null}>
        <Button
          onClick={() => {
            clearContentOrder();
            navigate(`${routes.contentOrderEditor}?id=${search.id}`);
          }}
        >
          Edit
        </Button>
        {contentOrder?.companies?.length ? (
          <Button
            onClick={() => {
              clearContentOrder();
              navigate(`${routes.wrapsEditor}?contentOrderId=${search.id}`);
            }}
          >
            Create wrap
          </Button>
        ) : null}
        {contentOrder.has_wraps ? (
          <Button
            onClick={() => {
              clearContentOrder();
              navigate(`${routes.wraps}?contentOrderId=${search.id}`);
            }}
          >
            Show wraps
          </Button>
        ) : null}
        <Button onClick={() => setOpenClone(!openClone)}>Clone sources</Button>
        <Button onClick={() => setOpenBulkAdd(!openBulkAdd)}>Bulk add sources</Button>
        <Button onClick={() => navigate(`${routes.userInvitations}?id=${contentOrder.id}`)}>Manage users</Button>
         <Button onClick={() => navigate(`${routes.contentOrderAddUsers}?id=${contentOrder.id}`)}>Add users</Button>
      </ButtonContainer>
      <CollapseContainer>
        {openClone ? (
            <div className="part">
              <SelectContainer>
                <Select
                    label="Subscription to copy from"
                    placeholder="Choose subscription..."
                    onChange={setChosenSubscription}
                    value={chosenSubscription}
                    options={subscriptionsForSelect}
                />
              </SelectContainer>
              {chosenSubscription &&
              subscription &&
              subscription.id === chosenSubscription ? (
                  <SelectContainer>
                    <Select
                        label="Content order to copy from"
                        placeholder="Choose content order..."
                        value={chosenContentOrder}
                        onChange={setChosenContentOrder}
                        options={contentOrders}
                    />
                  </SelectContainer>
              ) : null}
              <Button
                  disabled={!(chosenContentOrder && chosenSubscription)}
                  onClick={() =>
                      cloneArticleSource(chosenContentOrder, contentOrder.id)
                  }
              >
                Clone
              </Button>
            </div>
        ) : null}
        {openBulkAdd ? (
            <div className="part">
              <Input
                  noMargin
                  maxWidth={500}
                  value={sourcesToAdd}
                  onChange={(value) => setSourcesToAdd(value.replace(/[^0-9,]/g, ''))}
                  label="Sources to add (comma separated)"
                  placeholder="Sources to add (comma separated)..."
              />
              <Button
                  disabled={sourcesToAdd.length === 0}
                  onClick={() =>
                      bulkAddSources(contentOrder.id, sourcesToAdd)
                  }
              >
                Add
              </Button>
            </div>
        ) : null}
      </CollapseContainer>

    </ContentWrapper>
  );
};

export default inject(
  'subscriptionsStore',
  'contentOrdersStore',
)(observer(ContentOrder));
