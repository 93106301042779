import React, { useEffect, useMemo, useState } from 'react';

import { inject, observer } from 'mobx-react';
import Input from '@components/Input';
import { ButtonContainer } from '@containers/Wraps/style';
import Preloader from '@components/Preloader';
import Button from '@components/Button';
import {
  ContentWrapper,
  FiltersContainer,
  OnlyMobilePaper,
  SettingsRow,
} from '@styles/globalStyles';
import Select from '@components/Select';
import ColorPicker from '@components/ColorPicker';
import { Label } from '@containers/Companies/style';
import { SelectContainer } from '@containers/ArticleSourceRequests/style';
import {
  createColumns,
  emptyCompanyErrors,
  emptyCompanyFields,
} from '@containers/Companies/constants';

function CompanyCreator({
  companiesStore: {
    sectors,
    companyTypes,
    createCompanies,
    errors,
    setErrors,
    isLoadingCompanyTypes,
    isLoadingSectors,
    isLoading,
    serverError,
    setCompaniesToCreate,
    fetchSectors,
    fetchCompanyTypes,
  },
}) {
  const [result, setResult] = useState([]);

  useEffect(() => {
    setCompaniesToCreate([]);
    fetchSectors();
    fetchCompanyTypes();
    setResult([emptyCompanyFields]);
    setErrors([emptyCompanyErrors]);
  }, []);

  const columns = useMemo(
    () => createColumns(sectors, companyTypes),
    [sectors, companyTypes],
  );

  const changeField = (value, field) => {
    const newArray = [...result];
    newArray[0][field] = value;
    setResult(newArray);
    setCompaniesToCreate(newArray);
  };

  return (
    <ContentWrapper>
      <OnlyMobilePaper>
        {((isLoadingSectors && isLoadingCompanyTypes) || isLoading) && (
          <Preloader overlay />
        )}
        {sectors &&
          companyTypes &&
          sectors.length > 0 &&
          companyTypes.length > 0 &&
          result.length > 0 &&
          columns.map((column, index) => (
            <div key={`row-${index}`}>
              {column.type === 'color' ? <Label>Colour</Label> : null}
              <SettingsRow>
                {column.type === 'color' && (
                  <ColorPicker
                    color={result[0].color}
                    onChange={(color) => changeField(color, column.id)}
                  />
                )}
                {column.type === 'input' && (
                  <Input
                    label={column.label}
                    placeholder={`${column.label}...`}
                    maxWidth={300}
                    value={result[0][column.id]}
                    error={errors[0][column.id]}
                    id={column.id}
                    onChange={(value) => changeField(value, column.id)}
                  />
                )}
                {column.type === 'select' && (
                  <SelectContainer>
                    <Select
                      label={column.label}
                      placeholder={`${column.label}...`}
                      options={column.selectOptions.map(
                        ({ id: value, name: label }) => ({ value, label }),
                      )}
                      value={result[0][column.id]}
                      error={errors[0][column.id]}
                      onChange={(value) => changeField(value, column.id)}
                    />
                  </SelectContainer>
                )}
              </SettingsRow>
            </div>
          ))}
        <FiltersContainer style={{ marginBottom: 20 }}>
          <ButtonContainer>
            <Button onClick={() => createCompanies(result)}>Save</Button>
          </ButtonContainer>
          {serverError !== '' && (
            <div style={{ color: 'red', margin: 8, fontSize: 16 }}>
              {serverError}
            </div>
          )}
        </FiltersContainer>
      </OnlyMobilePaper>
    </ContentWrapper>
  );
}
export default inject('companiesStore')(observer(CompanyCreator));
