import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Search } from 'tabler-icons-react';
import { useTheme } from '@emotion/react';
import Input from '@components/Input';
import { Paper, SettingsRow } from '@styles/globalStyles';
import CheckList from '@components/CheckList';

const RecipientsList = ({
  wrapsStore: { recipients, changeRecipientChecked },
}) => {
  const [search, setSearch] = useState('');

  const filter = (allRecipients, searchParam) =>
    allRecipients.filter((recipient) =>
      recipient.email.toLowerCase().includes(searchParam.trim().toLowerCase()),
    );

  const theme = useTheme();

  return (
    <Paper>
      <SettingsRow>
        <Input
          icon={<Search stroke={theme.colors.secondary} />}
          value={search}
          onChange={setSearch}
          maxWidth={200}
          label="Search recipients"
          placeholder="Search recipients..."
        />
      </SettingsRow>
      <CheckList
        data={filter(recipients, search)}
        onChange={changeRecipientChecked}
        onChangeKey="email"
        checkboxKey="checked"
        labelKey="email"
      />
    </Paper>
  );
};

export default inject('wrapsStore')(observer(RecipientsList));
