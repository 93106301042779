import React from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import {
  MenuItemIcon,
  MenuItemLabel,
  StyledMenuItem,
} from '@components/SideBar/styles';

const MenuItem = ({
  path,
  label,
  icon,
  sidebarStore: { setMobileMenuOpened },
  authStore: { logout },
}) => (
  <NavLink
    to={path}
    className={({ isActive }) =>
      isActive ? 'sidebar-item sidebar-item--active' : 'sidebar-item'
    }
  >
    <StyledMenuItem
      onClick={() => {
        setMobileMenuOpened(false);
        if (label === 'Logout') {
          logout();
        }
      }}
    >
      <MenuItemIcon>{icon}</MenuItemIcon>
      <MenuItemLabel>{label}</MenuItemLabel>
    </StyledMenuItem>
  </NavLink>
);

export default inject('sidebarStore', 'authStore')(observer(MenuItem));
