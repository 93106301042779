import React from 'react';
import { StyledMenuItem, MenuItemIcon } from '@components/SideBar/styles';
import collapseMenuIcon from '@app/assets/collapse-menu.svg';
import showMenuIcon from '@app/assets/show-menu.svg';

const OpenCloseMenu = ({ setMenuCollapsed, menuCollapsed }) => (
  <StyledMenuItem
    onClick={() => setMenuCollapsed(!menuCollapsed)}
    className="open-close-menu"
  >
    <MenuItemIcon>
      {!menuCollapsed && (
        <img
          alt="collapse"
          src={collapseMenuIcon}
          style={{ height: 32, width: 32 }}
        />
      )}
      {menuCollapsed && (
        <img
          alt="collapse"
          src={showMenuIcon}
          style={{ height: 32, width: 32 }}
        />
      )}
    </MenuItemIcon>
  </StyledMenuItem>
);

export default OpenCloseMenu;
