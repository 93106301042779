import moment from 'moment';

export const fields = [
  { id: 'id' },
  { id: 'name' },
  {
    id: 'created_at',
    format: (value) => moment(value).format('LL').toString(),
  },
  {
    id: 'last_published_at',
    format: (value) => moment(value).format('LL').toString(),
  },
  { id: 'disabled', format: (value) => value.toString() },
];

export const metaFields = [
  { id: 'color' },
  { id: 'description' },
  { id: 'always_available', format: (value) => value.toString() },
  { id: 'demo', format: (value) => value.toString() },
  { id: 'include_analyst_in_email', format: (value) => value.toString() },
  { id: 'tags', format: (tags) => tags.join(', ') },
];
