import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { Trash } from 'tabler-icons-react';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import routes from '@routes';
import { filters, tableColumns } from '@containers/NlaOrganisations/constants';
import Button from '@components/Button';
import { ButtonContainer } from '@containers/NlaOrganisations/styles';
import ConfirmationDialog from '@components/ConfirmationDialog';

const NlaOrganisations = ({
  nlaOrganisationsStore: {
    fetchNlaOrganisations,
    nlaOrganisations,
    isLoading,
    nlaOrganisationsCount,
    removeNlaOrganisation,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [rowIdToRemove, setRowIdToRemove] = useState(null);
  const navigate = useNavigate();

  const columnActions = {
    id: (row) => navigate(`${routes.nlaOrganisation}?id=${row.id}`),
  };

  useEffect(() => {
    clear();
    fetchNlaOrganisations();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchNlaOrganisations({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchNlaOrganisations({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery({});
    setPage(0);
    await fetchNlaOrganisations({
      limit: rowsPerPage,
      page: 0,
      searchQuery: {},
    });
  };

  const actions = () => [
    {
      icon: () => <Trash strokeWidth={1} size={24} />,
      onClick: (row) => {
        setIsConfirmationDialogOpen(true);
        setRowIdToRemove(row.id);
      },
    },
  ];

  const tableActions = useMemo(() => actions(), []);

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <ConfirmationDialog
        onConfirmation={(rowId) => {
          removeNlaOrganisation(rowId);
          setIsConfirmationDialogOpen(false);
          clear();
        }}
        isDialogOpen={isConfirmationDialogOpen}
        setIsDialogOpen={setIsConfirmationDialogOpen}
        rowId={rowIdToRemove}
        dialogText="Delete this item?"
      />
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <FiltersContainer>
        <ButtonContainer>
          <Button
            onClick={() => navigate(`${routes.nlaOrganisationEditor}?create=true`)}
          >
            Create NLA organisation
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      <Table
        rowsPerPageOptions={[10, 50, 100]}
        count={nlaOrganisationsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={nlaOrganisations}
        columnActions={columnActions}
        actions={tableActions}
      />
    </ContentWrapper>
  );
};

export default inject('nlaOrganisationsStore')(observer(NlaOrganisations));
