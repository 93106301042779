import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Label = styled.div`
  display: flex;
  font-weight: bold;
  min-width: 250px;
  width: 250px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.font.default};

  @media screen and ${breakpoint.md} {
    min-width: 100px;
    width: 100px;
  }
`;

export const Value = styled.div`
  max-height: 150px;
  overflow-y: scroll;
  font-weight: bold;
  line-height: 24px;
  color: ${({ theme }) => theme.font.default};

  @media screen and ${breakpoint.md} {
    display: flex;
    font-weight: normal;
    font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
    //height: 20px;
    align-items: center;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  cursor: ${({ clickable }) => (clickable ? `pointer` : '')};

  @media screen and ${breakpoint.md} {
    margin: 5px 0;
    font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
    display: flex;
    height: ${({ big }) => (big ? '45px' : '30px')};
    align-items: center;
  }
`;
