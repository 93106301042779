import React, { memo, useState, useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { routerStore } from '@stores';

const browserHistory = createBrowserHistory();

export const history = syncHistoryWithStore(browserHistory, routerStore);

export const BrowserRouter = memo((props) => {
  const { history: historyProp, ...restProps } = props;
  const [state, setState] = useState({
    action: historyProp.action,
  });

  useLayoutEffect(() => {
    historyProp.listen(setState);
  }, [historyProp]);

  return (
    <Router
      {...restProps}
      location={historyProp.location}
      navigationType={state.action}
      navigator={historyProp}
    />
  );
});
