/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

export class UserSettingsStore {
  isLoading = false;

  userSettings = [];

  userSettingsCount = 0;

  userSetting = {};

  validationErrors = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchUserSettings = async ({ limit, page, searchQuery } = {}) => {
    this.isLoading = true;

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.userSettings.index, {
        params: query,
      });
      runInAction(() => {
        this.userSettings = response.data.userSettings;
        this.userSettingsCount = response.data.numberOfUserSetting;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  setUserSetting = (valueObj) => {
    this.userSetting = { ...this.userSetting, ...valueObj };
  };

  validateUserSettings = () => {
    this.validationErrors.content_container_id =
      this.userSetting.content_container_id === null ||
      this.userSetting.content_container_id.length === 0
        ? 'Content container id should not be empty'
        : undefined;

    this.validationErrors.content_order_id =
      this.userSetting.content_order_id === null ||
      this.userSetting.content_order_id.length === 0
        ? 'Content order id should not be empty'
        : undefined;
  };

  updateUserSetting = async () => {
    this.isLoading = true;
    this.validateUserSettings();
    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }
    let response = null;
    try {
      response = await API.post(
        apiRoutes.userSettings.bulkUpdate,
        this.userSetting,
      );

      if (response.data.message) {
        routerStore.push(routes.userSettings);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.error);
      }
    } catch (e) {
      this.error = e;
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }

    this.isLoading = false;
  };

  clearUserSetting = () => {
    this.userSetting = {};
  };
}

export default new UserSettingsStore();
