import styled from '@emotion/styled';
import ChevronIcon from '@assets/images/icons/chevron.svg';

export const SelectContainer = styled.div`
  width: 100%;
`;

export const CollapsibleSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Chevron = styled.div`
  margin: auto;
  padding-bottom: 10px;
  &:after {
    content: '';
    width: 14px;
    height: 12px;
    display: block;
    background: transparent url(${ChevronIcon}) no-repeat 50% 50% / contain;
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(${({ isOpened }) => (isOpened ? 0 : 180)}deg);
  }
`;
