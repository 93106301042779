import styled from '@emotion/styled';
import { Checkbox as BaseCheckbox, SquareOff } from 'tabler-icons-react';
import { SettingsColumn as GlobalSettingsColumn } from '@styles/globalStyles';

export const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100%;

  &.uppercase {
    text-transform: uppercase;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -27px;
`;

export const SettingsColumn = styled(GlobalSettingsColumn)`
  height: 105px;
`;

export const Checkbox = styled(BaseCheckbox)`
  :hover {
    opacity: 0.5;
  }
`;

export const EmptyCheckbox = styled(SquareOff)`
  :hover {
    opacity: 0.5;
  }
`;

export const SelectContainer = styled.div`
  max-width: 300px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 15px 0;
  max-width: 300px;

  &.column {
    flex-direction: column;
  }
`;

export const TableTitle = styled.div`
  margin: 10px;
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  
  .part {
    min-width: 300px;
    
    input {
      margin-bottom: 30px;
    }
  }
`;
