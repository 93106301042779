import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper, Paper, SettingsRow } from '@styles/globalStyles';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import {
  Header,
  CheckBoxContainer,
  SettingsColumn,
  Checkbox,
  EmptyCheckbox,
  ButtonContainer,
} from '@containers/ContentOrders/style';
import Text, { TAGS } from '@components/Text';
import Control from '@components/Control';
import Input, { INPUT_TYPES } from '@components/Input';
import { Field, Label, Value } from '@components/DetailsMobile/styles';
import Table from '@components/Table';
import CollapsibleSelect from '@components/CollapsibleSelect';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Button from '@components/Button';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';

const SectionCell = ({ value, toggleColumn, column }) =>
  value === 'all' ? (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
      <Checkbox onClick={() => toggleColumn(column, 'selectAll')} />
      <EmptyCheckbox onClick={() => toggleColumn(column, 'deselectAll')} />
    </div>
  ) : (
    <div>{value ? <Checkbox /> : <EmptyCheckbox />}</div>
  );

const columnsFunc = (toggleColumn) => [
  {
    id: 'content_container_id',
    label: 'Container id',
  },
  {
    id: 'content_container_name',
    label: 'Company name',
  },
  {
    id: 'bespoke_sections',
    label: 'Bespoke sections',
    format: (value) => (
      <SectionCell
        value={value}
        column="bespoke_sections"
        toggleColumn={toggleColumn}
      />
    ),
  },
  {
    id: 'standard_sections',
    label: 'Standard Sections',
    format: (value) => (
      <SectionCell
        value={value}
        column="standard_sections"
        toggleColumn={toggleColumn}
      />
    ),
  },
  {
    id: 'actions',
    label: 'Select/Deselect Row',
    align: 'left',
  },
];

const firstRow = {
  content_container_id: '',
  content_container_name: 'Select/Deselect',
  standard_sections: 'all',
  bespoke_sections: 'all',
};

const ContentOrderEditor = ({
  contentOrdersStore: {
    contentOrder,
    fetchContentOrder,
    companies,
    companyTypes,
    fetchCompanies,
    fetchCompanyTypes,
    fetchArticleSources,
    articleSources,
    setContentOrderField,
    toggleSection,
    toggleColumn,
    updateContentOrder,
    validationErrors,
    isUpdating,
    isLoading,
    nlaOrganisations,
    isLoadingNlaOrganisations,
    fetchNlaOrganisations
  },
}) => {
  const { search } = useCustomSearchParams();
  const columns = columnsFunc(toggleColumn);
  useEffect(() => {
    if (search.id) {
      fetchContentOrder(search.id, true);
      fetchCompanies();
      fetchCompanyTypes();
      fetchArticleSources();
      fetchNlaOrganisations();
    }
  }, []);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const columnActions = {
    bespoke_sections: (row) =>
      row.bespoke_sections !== 'all' && toggleSection(row, 'bespoke_sections'),
    standard_sections: (row) =>
      row.standard_sections !== 'all' &&
      toggleSection(row, 'standard_sections'),
  };

  const actions = [
    {
      icon: (row) =>
        row.content_container_id !== '' ? (
          <Checkbox strokeWidth={1} size={24} />
        ) : null,
      onClick: (row) => toggleSection(row, 'row', 'selectAll'),
    },
    {
      icon: (row) =>
        row.content_container_id !== '' ? (
          <EmptyCheckbox strokeWidth={1} size={24} />
        ) : null,
      onClick: (row) => toggleSection(row, 'row', 'deselectAll'),
    },
  ];

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <Header className="uppercase">
        <Text tag={TAGS.H2}>Main content order settings</Text>
      </Header>
      <Paper>
        <Field>
          <Label>Contract name</Label>
          <Value>{contentOrder.contract_name}</Value>
        </Field>
        <SettingsRow>
          <Input
            error={validationErrors.name}
            maxWidth={500}
            value={contentOrder.name}
            onChange={(value) => setContentOrderField(value, 'name')}
            label="Name"
            placeholder="Wrap name..."
          />
        </SettingsRow>
        <SettingsColumn>
          <CheckBoxContainer>
            <Control
              name="has_briefings"
              type="checkbox"
              label="Has briefings"
              value={contentOrder.has_briefings}
              checked={contentOrder.has_briefings}
              onChange={(item) =>
                setContentOrderField(item.target.checked, 'has_briefings')
              }
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Control
              name="full_article_links_in_briefings"
              value={contentOrder.full_article_links_in_briefings}
              checked={contentOrder.full_article_links_in_briefings}
              onChange={(item) =>
                setContentOrderField(
                  item.target.checked,
                  'full_article_links_in_briefings',
                )
              }
              type="checkbox"
              label="Full article links in briefings"
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Control
              name="show_company_name_in_notifications"
              value={contentOrder.show_company_name_in_notifications}
              checked={contentOrder.show_company_name_in_notifications}
              onChange={(item) =>
                setContentOrderField(
                  item.target.checked,
                  'show_company_name_in_notifications',
                )
              }
              type="checkbox"
              label="Show company name in notifications"
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Control
              name="is_active"
              value={contentOrder.is_active}
              checked={contentOrder.is_active}
              onChange={() => {}}
              disabled
              type="checkbox"
              label="Active"
            />
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Control
              name="is_default"
              value={contentOrder.is_default}
              checked={contentOrder.is_default}
              onChange={() => {}}
              disabled
              type="checkbox"
              label="Default"
            />
          </CheckBoxContainer>
        </SettingsColumn>
        <SettingsRow>
          <Input
            error={validationErrors.user_limit}
            maxWidth={500}
            value={contentOrder.user_limit}
            onChange={(value) => setContentOrderField(value, 'user_limit')}
            label="User limit"
            placeholder="User limit..."
            inputType={INPUT_TYPES.NUMBER}
          />
        </SettingsRow>
        <Field big>
          <Label>Users</Label>
          <Value className="add-line-height">
            {contentOrder.users?.map(({ email }) => email).join(', ')}
          </Value>
        </Field>
      </Paper>
      <Header className="uppercase">
        <Text tag={TAGS.H2}>Content order scope</Text>
      </Header>
      <Paper>
        <CollapsibleSelect
          label="Company groups"
          options={companyTypes}
          onChange={(value) => setContentOrderField(value, 'company_groups')}
          value={contentOrder.company_groups}
        />
        <CollapsibleSelect
          label="Primary companies"
          options={companies}
          onChange={(value) => setContentOrderField(value, 'primary_companies')}
          value={contentOrder.primary_companies}
        />
        <CollapsibleSelect
          label="Secondary companies"
          options={companies}
          onChange={(value) =>
            setContentOrderField(value, 'secondary_companies')
          }
          value={contentOrder.secondary_companies}
        />
        <CollapsibleSelect
          label="Peer companies"
          options={companies}
          onChange={(value) => setContentOrderField(value, 'peer_companies')}
          value={contentOrder.peer_companies}
        />
        <CollapsibleSelect
          label="Portfolio companies"
          options={companies}
          onChange={(value) =>
            setContentOrderField(value, 'portfolio_companies')
          }
          value={contentOrder.portfolio_companies}
        />
        <CollapsibleSelect
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          label="Article sources"
          options={articleSources}
          onChange={(value) => setContentOrderField(value, 'article_sources')}
          value={contentOrder.article_sources}
        />
        <CollapsibleSelect
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          label="Excluded article sources"
          options={articleSources}
          onChange={(value) => setContentOrderField(value, 'excluded_article_sources')}
          value={contentOrder.excluded_article_sources}
        />
        <CollapsibleSelect
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          label="NLA organisations"
          options={nlaOrganisations}
          onChange={(value) => setContentOrderField(value, 'nla_organisations')}
          value={contentOrder.nla_organisations}
          isLoading={isLoadingNlaOrganisations}
        />
      </Paper>
      <ButtonContainer>
        <Button onClick={() => updateContentOrder(true)}>
          Update and reload content order
        </Button>
        {isUpdating ? (
          <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
        ) : null}
      </ButtonContainer>
      <Header className="uppercase">
        <Text tag={TAGS.H2}>Content order sections</Text>
      </Header>
      {contentOrder.content_order_sections && (
        <Paper>
          <Table
            columnActions={columnActions}
            actions={actions}
            columns={columns}
            data={
              isMobile
                ? contentOrder.content_order_sections
                : [firstRow, ...contentOrder.content_order_sections]
            }
            disablePagination
            hideMobileEdit
          />
        </Paper>
      )}
      <ButtonContainer>
        <Button onClick={() => updateContentOrder(false)}>
          Update content order
        </Button>
        {isUpdating ? (
          <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
        ) : null}
      </ButtonContainer>
    </ContentWrapper>
  );
};

export default inject('contentOrdersStore')(observer(ContentOrderEditor));
