import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

export class ToolMessagesStore {
  isLoading = false;

  toolMessages = [];

  toolMessagesCount = 0;

  toolMessage = {};

  validationErrors = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchToolMessages = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.toolMessages.index, {
        params: query,
      });
      runInAction(() => {
        this.toolMessages = response.data.tool_messages;
        this.toolMessagesCount = response.data.tool_messages_count;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchToolMessage = async (id) => {
    this.isLoading = true;
    try {
      const response = await API.get(apiRoutes.toolMessages.record(id));
      runInAction(() => {
        this.toolMessage = response.data;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  setToolMessage = (valueObj) => {
    this.toolMessage = { ...this.toolMessage, ...valueObj };
  };

  validateToolMessage = () => {
    this.validationErrors.description =
      this.toolMessage.description === null ||
      this.toolMessage.description.length === 0
        ? 'Description should not be empty'
        : undefined;

    this.validationErrors.severity =
      this.toolMessage.severity === null ||
      this.toolMessage.severity.length === 0
        ? 'Severity should not be empty'
        : undefined;

    this.validationErrors.valid_from =
      this.toolMessage.valid_from === null ||
      this.toolMessage.valid_from === undefined
        ? 'Valid from should not be empty'
        : undefined;

    this.validationErrors.valid_to =
      this.toolMessage.valid_to === null ||
      this.toolMessage.valid_to === undefined
        ? 'Valid to should not be empty'
        : undefined;
  };

  updateToolMessage = async (create) => {
    this.isLoading = true;
    this.validateToolMessage();
    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }
    let response = null;
    try {
      if (create) {
        response = await API.post(apiRoutes.toolMessages.index, {
          tool_message: this.toolMessage,
        });
      } else {
        response = await API.patch(
          apiRoutes.toolMessages.record(this.toolMessage.id),
          {
            tool_message: this.toolMessage,
          },
        );
      }

      if (response.data.message) {
        if (create) {
          routerStore.push(routes.toolMessages);
        } else {
          routerStore.push(`${routes.toolMessage}?id=${this.toolMessage.id}`);
        }
        toast.success(response.data.message);
      } else {
        toast.error(response.data.error);
      }
    } catch (e) {
      this.error = e;
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }

    this.isLoading = false;
  };

  removeToolMessage = async (id) => {
    try {
      await API.delete(apiRoutes.toolMessages.record(id));
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      await this.fetchToolMessages({});
    }
  };

  clearToolMessage = () => {
    this.toolMessage = {};
  };
}

export default new ToolMessagesStore();
