import React from 'react';

import {
  BuildingFactory,
  BuildingSkyscraper,
  Logout,
  Mail,
  News,
  Note,
  Package,
  Grain,
  Exchange,
  Message,
  Users,
  Strikethrough, Circles,
} from 'tabler-icons-react';
import DesktopSideBar from '@components/SideBar/components/DesktopSideBar';
import useBreakpoint from '@root/hooks/useBreakpoint';
import MobileSideBar from '@components/SideBar/components/MobileSideBar';
import routes from '@routes';
import DarkModeSwitch from '@components/DarkModeSwitch';

const MENU_ITEMS = [
  {
    label: 'Wraps',
    key: 'wraps',
    path: routes.wraps,
    icon: <Mail strokeWidth={1} size={32} color="white" />,
    // submenuItemsKey: 'myNews', use to create toggable list under menu item
  },
  {
    label: 'Users',
    key: 'users',
    path: routes.users,
    icon: <Users strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'User Settings',
    key: 'userSettings',
    path: routes.userSettings,
    icon: <Users strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Companies',
    key: 'companies',
    path: routes.companies,
    icon: <BuildingSkyscraper strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Company Relations',
    key: 'companyRelations',
    path: routes.companyRelations,
    icon: <Grain strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Subscriptions',
    key: 'subscriptions',
    path: routes.subscriptions,
    icon: <Package strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Article Sources',
    key: 'articleSources',
    path: routes.articleSources,
    icon: <News strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Article Source Requests',
    key: 'articleSourceRequests',
    path: routes.articleSourceRequests,
    icon: <News strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Switch Article Sources',
    key: 'switchArticleSources',
    path: routes.switchArticleSources,
    icon: <Exchange strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Briefing Types',
    key: 'briefingTypes',
    path: routes.briefingTypes,
    icon: <Note strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Delayed Jobs',
    key: 'delayedJobs',
    path: routes.delayedJobs,
    icon: <BuildingFactory strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Tool Messages',
    key: 'toolMessages',
    path: routes.toolMessages,
    icon: <Message strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Skipped Author Terms',
    key: 'skippedAuthorTerms',
    path: routes.skippedAuthorTerms,
    icon: <Strikethrough strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'NLA organisations',
    key: 'nlaOrganisations',
    path: routes.nlaOrganisations,
    icon: <Circles strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Logout',
    key: 'logout',
    path: routes.auth.logout,
    icon: <Logout strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Dark Mode',
    key: 'dark-mode',
    icon: <DarkModeSwitch />,
  },
];

const SideBar = ({ mobileLabel }) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  if (isMobile) {
    return <MobileSideBar mobileLabel={mobileLabel} menuItems={MENU_ITEMS} />;
  }
  return <DesktopSideBar menuItems={MENU_ITEMS} />;
};

export default SideBar;
