import React from 'react';
import { useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Container, Wrapper } from '@components/Navbar/style';
import { PAGE_LABELS } from '@root/constants/common';
import Text, { TAGS } from '@components/Text';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';
import UserMenu from '@components/UserMenu';

const Navbar = ({
  authStore: { isLoadingUser, user },
  sidebarStore: { menuCollapsed },
}) => {
  const { pathname } = useLocation();
  const label = PAGE_LABELS[pathname];

  return (
    <Wrapper menuCollapsed={menuCollapsed}>
      <Container>
        {label ? <Text tag={TAGS.H1}>{label}</Text> : null}
        {isLoadingUser || !user?.fullName ? (
          <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
        ) : (
          <UserMenu
            fullName={user.fullName}
            firstName={user.firstName}
            lastName={user.lastName}
            userEmail={user.email}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default inject('authStore', 'sidebarStore')(observer(Navbar));
