export const boolSelect = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const tableColumns = [
  {
    id: 'id',
    label: 'ID',
    maxWidth: 70,
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'user_id',
    label: 'User ID',
    maxWidth: 80,
    format: (value) => (value || undefined),
  },
  {
    id: 'invitation_sent',
    label: 'Invitation processed',
    maxWidth: 130,
    format: (value) => (value ? 'true' : 'false'),
  },
  {
    id: 'send_welcome_email',
    label: 'Send welcome email',
    maxWidth: 130,
    format: (value) => (value ? 'true' : 'false'),
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 220,
    maxWidth: 220,
    align: 'left',
  },
];

export const filters = [
  { id: 'email', label: 'Email', type: 'input' },
  {
    id: 'send_welcome_email',
    label: 'Welcome email sent',
    type: 'select',
    options: boolSelect,
    isClearable: true,
  },
  {
    id: 'invitation_sent',
    label: 'Invitation processed',
    type: 'select',
    options: boolSelect,
    isClearable: true,
  },
];

export const editorFields = [
  {
    id: 'emails',
    label: 'Email(s) (space separated)',
    component: 'input',
    type: 'text',
  },
  {
    id: 'send_welcome_email',
    label: 'Send welcome email',
    component: 'select',
    options: boolSelect,
  },
  {
    id: 'nla_password',
    label: 'NLA password (optional)',
    component: 'input',
    type: 'text',
  },
];