import React from 'react';
import moment from 'moment';
import { UserItem } from '@containers/Subscriptions/style';
import { DialogItem } from '@components/TableDialog/styles';

export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'display_name',
    label: 'Display name',
    hide: true,
  },
  {
    id: 'subscription_plan',
    label: 'Subscription type',
    maxWidth: 150,
  },
  {
    id: 'start_date',
    label: 'Start date',
    format: (value) => moment(value).format('LL').toString(),
    maxWidth: 150,
  },
  {
    id: 'end_date',
    label: 'End date',
    format: (value) => moment(value).format('LL').toString(),
    maxWidth: 150,
  },
  {
    id: 'state',
    label: 'State',
    maxWidth: 90,
  },
  {
    id: 'users',
    label: 'Users',
    maxWidth: 150,
    format: (users) =>
      users.length ? <DialogItem>Show users ({users.length})</DialogItem> : 0,
  },
  {
    id: 'number_of_external_users',
    label: 'External users',
    maxWidth: 80,
    hide: true,
  },
  {
    id: 'number_of_internal_users',
    label: 'Internal users',
    maxWidth: 80,
    hide: true,
  },
  {
    id: 'invitations',
    label: 'Invitations',
    format: (invitations) =>
      invitations.length ? (
        <UserItem>Show invitations ({invitations.length})</UserItem>
      ) : (
        0
      ),
    hide: true,
  },
];

export const filtersFunc = (
  subscriptionPlans,
  isLoadingSubscriptionPlans,
  states,
  isLoadingStates,
  admins,
  isLoadingAdmins,
) => [
  { id: 'name', label: 'Name', type: 'input' },
  { id: 'display_name', label: 'Display name', type: 'input' },
  {
    startDateId: 'start_date_from',
    endDateId: 'start_date_to',
    label: 'Start date',
    type: 'dateRange',
  },
  {
    startDateId: 'end_date_from',
    endDateId: 'end_date_to',
    label: 'End date',
    type: 'dateRange',
  },
  {
    id: 'user',
    label: 'Users in subscription',
    placeholder: 'Email...',
    type: 'input',
  },
  {
    id: 'subscription_plan_id',
    label: 'Subscription type',
    type: 'select',
    options: subscriptionPlans,
    isLoading: isLoadingSubscriptionPlans,
    isClearable: true,
  },
  {
    id: 'state',
    label: 'State',
    type: 'select',
    options: states,
    isLoading: isLoadingStates,
    isClearable: true,
  },
  {
    id: 'admin_id',
    label: 'Account manager',
    type: 'select',
    options: admins,
    isLoading: isLoadingAdmins,
    isClearable: true,
    className: 'big',
  },
];
