import { makeAutoObservable, runInAction } from 'mobx';
// import { toast } from 'react-toastify';
import {toast} from "react-toastify";
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from "@root/stores/routerStore";
import routes from "@routes";

export class UserInvitationsStore {
  isLoading = false;

  isLoadingAction = false;

  userInvitations = [];

  userInvitationsCount = 0;

  contentOrderName = ""

  userInvitation = {};

  validationErrors = {}


  constructor() {
    makeAutoObservable(this);
  }

  fetchUserInvitations = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;
    this.userInvitations = [];

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.userInvitations.index, {
        params: query,
      });
      runInAction(() => {
        this.userInvitations = response.data.user_invitations;
        this.userInvitationsCount = response.data.user_nvitations_count;
        this.contentOrderName = response.data.content_order_name;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  doAction = async (id, action) => {
    this.isLoadingAction = true;
    try {
      const { data: { message }} = await API.post(apiRoutes.userInvitations[action](id));
      toast.success(message);
    } catch (e) {
      toast.error(e.message);
    } finally {
      this.isLoadingAction = false;
    }
  }

  removeUserFromContentOrder = async (id, clearMethod) => {
    this.isLoadingAction = true;
    try {
      const { data: { message }} = await API.delete(apiRoutes.userInvitations.record(id));
      toast.success(message);
    } catch (e) {
      toast.success(e.message);
    } finally {
      this.isLoadingAction = false;
      await clearMethod();
    }
  }

  setUserInvitation = (valueObj) => {
    this.userInvitation = { ...this.userInvitation, ...valueObj };
  };

  validateUserInvitation = () => {
    this.validationErrors.emails =
      this.userInvitation.emails === null ||
      this.userInvitation.emails.length === 0
        ? 'Emails should not be empty'
        : undefined;

    this.userInvitation.emails.split(' ').forEach((email) => {
      const match = email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
      if(!match) {
        this.validationErrors.emails = 'Bad email format'
      }
    })
  };

  createUserInvitation = async () => {
    this.isLoading = true;
    this.validateUserInvitation();
    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }
    let response = null;
    try {

      response = await API.post(apiRoutes.userInvitations.index, {
        ...this.userInvitation
      });

      if (response.data.message) {
        routerStore.push(`${routes.userInvitations}?id=${this.userInvitation.content_order_id}`);
        response.data.message.forEach(({ message }) => toast.success(message));
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      this.error = e;
      console.log(e);
      e.message.forEach(({ message, error }) => error ? toast.error(message) : toast.success(message));
    } finally {
      this.isLoading = false;
    }
  };

  clearUserInvitation = () => {
    this.userInvitation = {}
  }
}

export default new UserInvitationsStore();
