import React, { useState, useMemo } from 'react';

import { ChevronRight, ChevronLeft, Pencil } from 'tabler-icons-react';
import {
  TableContainer,
  TableCell,
  TableRow,
  ActionButton,
  PaginationContainer,
  PaginationLabel,
  SelectContainer,
  CollapseIconUp,
  CollapseIconDown,
  RecordContainer,
  Actions,
} from '@components/MobileTable/style';
import Select from '@components/Select';
import DetailsTable from '@components/DetailsTable';

export const ActionsCell = ({ actions, row, column }) => (
  <TableCell
    className="actions"
    maxWidth={column.maxWidth}
    key={`table-cell-${column.id}-${row.id}`}
  >
    {actions.map(({ icon, label, onClick }, index) =>
      icon && icon(row) !== null ? (
        <ActionButton
          key={`action-${column.id}-${row.id}-${index}`}
          onClick={() => onClick(row)}
        >
          {icon ? icon(row) : label}
        </ActionButton>
      ) : null,
    )}
  </TableCell>
);

const MobileTable = ({
  columns,
  data,
  actions,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  count,
  columnActions,
  big,
  disablePagination,
  hidePencil,
}) => {
  const [showRows, setShowRows] = useState([]);
  const maxPage = Math.floor(count / rowsPerPage);
  const pageStart = rowsPerPage * page;
  const pageEnd = page === maxPage ? count : rowsPerPage * (page + 1);

  const toggleRow = (rowId, includes) => {
    if (includes) {
      setShowRows(showRows.filter((item) => item !== rowId));
    } else {
      setShowRows([...showRows, rowId]);
    }
  };

  const allActions = useMemo(() => {
    let all = [];
    if (data) {
      if (actions) {
        all = all.concat(actions);
      }
      if (columnActions && !hidePencil) {
        const editKey = Object.keys(columnActions)[0];
        const editAction = {
          icon: () => <Pencil strokeWidth={1} size={24} />,
          onClick: columnActions[editKey],
        };
        all.push(editAction);
      }
    }
    return all;
  }, [actions, columnActions, data]);

  return (
    <TableContainer>
      {data && data.length
        ? data.map((row, index) => {
            const rowId = `${row[columns[0].id]}-${index + page * rowsPerPage}`;
            const actionColumn =
              columns.find(({ id }) => id === 'actions') || columnActions;
            const rowVisible = showRows.includes(rowId);
            return (
              <RecordContainer>
                <TableRow onClick={() => toggleRow(rowId, rowVisible)}>
                  {`${row[columns[0].id]} - ${row[columns[1].id]}`}
                  {rowVisible ? (
                    <CollapseIconUp
                      onClick={() => toggleRow(rowId, rowVisible)}
                    />
                  ) : (
                    <CollapseIconDown
                      onClick={() => toggleRow(rowId, rowVisible)}
                    />
                  )}
                </TableRow>
                {showRows.includes(rowId) ? (
                  <>
                    <DetailsTable
                      data={row}
                      fields={columns}
                      big={big}
                      actions={columnActions}
                    />
                    <Actions>
                      {actionColumn ? (
                        <ActionsCell
                          actions={allActions}
                          row={row}
                          column={actionColumn}
                        />
                      ) : null}
                    </Actions>
                  </>
                ) : null}
              </RecordContainer>
            );
          })
        : null}
      {disablePagination ? null : (
        <PaginationContainer>
          <PaginationLabel>Rows:</PaginationLabel>
          <SelectContainer>
            <Select
              onChange={onChangeRowsPerPage}
              value={rowsPerPage}
              placeholder="Enabled"
              options={rowsPerPageOptions.map((value) => ({
                value,
                label: value,
              }))}
            />
          </SelectContainer>
          <PaginationLabel>
            {pageStart + 1}-{pageEnd} of {count}
          </PaginationLabel>
          <PaginationLabel
            className={page === 0 ? 'disabled' : 'cursor'}
            onClick={() => (page >= 1 ? onChangePage(page - 1) : null)}
          >
            <ChevronLeft />
          </PaginationLabel>
          <PaginationLabel
            className={page === maxPage ? 'disabled' : 'cursor'}
            onClick={() => (page !== maxPage ? onChangePage(page + 1) : null)}
          >
            <ChevronRight />
          </PaginationLabel>
        </PaginationContainer>
      )}
    </TableContainer>
  );
};

export default MobileTable;
