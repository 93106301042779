import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const CollapsibleFiltersContainer = styled.div`
  display: flex;
  margin-bottom: 40px;

  &.no-margin {
    margin-bottom: unset;
  }
`;

export const InnerCollapsibleFiltersContainer = styled.div`
  display: flex;
  height: 65px;
  overflow: hidden;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 95vw;
  margin-right: 10px;
  padding-left: 2px;
  margin-left: -2px;

  &.open {
    height: unset;
    overflow: unset;
  }

  @media screen and (${breakpoint.sm}) {
    width: 50vw;
  }
`;

export const ChevronContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 65px;
  justify-content: center;
  margin-left: auto;
  padding-top: 17px;
  align-items: center;
  gap: 10px;

  .icon-tabler-chevron-down,
  .icon-tabler-chevron-up {
    stroke: ${({ theme }) => theme.colors.secondary};
  }
`;
