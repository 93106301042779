import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const MainLayout = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  @media screen and ${breakpoint.md} {
    min-height: calc(
      100vh - ${({ theme }) => theme.defaults.mobileMenuHeight}px
    );
  }
`;

export const Wrapper = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 100vh;

  @media screen and ${breakpoint.xl} {
    min-width: calc(100% - ${({ theme }) => theme.defaults.desktopMenuWidth}px);
  }

  @media screen and ${breakpoint.md} {
    min-width: calc(100% - 220px);
  }

  @media screen and ${breakpoint.md} {
    padding-top: ${({ theme }) => theme.defaults.mobileMenuHeight}px;
  }
`;

export const Content = styled.div`
  position: relative;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  transition: background-color 0.3s;
  @media screen and ${breakpoint.md} {
    width: 100vw;
  }
`;
