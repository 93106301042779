export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 90,
  },
  {
    id: 'user_id',
    label: 'User id',
  },
  { id: 'content_container_id', label: 'Content container id' },
  {
    id: 'follow',
    label: 'Follow',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
  {
    id: 'email_notification',
    label: 'Email notifications',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
  {
    id: 'push_notification',
    label: 'Push notifications',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
  {
    id: 'slack_notification',
    label: 'Slack notifications',
    format: (value) => (value === true ? 'Yes' : 'No'),
    maxWidth: 110,
  },
];

export const updateFields = () => [
  {
    id: 'content_container_id',
    label: 'Content container id',
    component: 'input',
    type: 'text',
  },
  {
    id: 'content_order_id',
    label: 'Content order id',
    component: 'input',
    type: 'text',
  },
  {
    checkboxes: [
      {
        id: 'follow',
        label: 'Follow',
      },
      {
        id: 'email_notification',
        label: 'Email notifications',
      },
      {
        id: 'push_notification',
        label: 'Push notifications',
      },
      {
        id: 'slack_notification',
        label: 'Slack notifications',
      },
    ],
    component: 'checkboxes',
  },
];

export const filters = () => [
  {
    id: 'user_id',
    label: 'User id',
    type: 'input',
  },
  {
    id: 'content_container_id',
    label: 'Content container id',
    type: 'input',
  },
];
