import React from 'react';
import { ChevronDown, ChevronUp, Search, TrashX } from 'tabler-icons-react';
import {
  ChevronContainer,
  CollapsibleFiltersContainer,
  InnerCollapsibleFiltersContainer,
} from '@components/CollapsibleFilters/styles';
import Button from '@components/Button';
import useBreakpoint from '@root/hooks/useBreakpoint';

export const CollapsibleFilters = ({
  children,
  search,
  clear,
  isOpen,
  setIsOpen,
  containerClass,
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  return (
    <CollapsibleFiltersContainer className={containerClass}>
      <InnerCollapsibleFiltersContainer className={isOpen ? 'open' : null}>
        {children}
      </InnerCollapsibleFiltersContainer>
      <ChevronContainer>
        <Button classes="no-min" onClick={() => search()}>
          {isMobile ? <Search strokeWidth={1} size={24} /> : 'Filter'}
        </Button>
        <Button classes="no-min" onClick={() => clear()}>
          {isMobile ? <TrashX strokeWidth={1} size={24} /> : 'Clear'}
        </Button>
        {isOpen ? (
          <ChevronUp onClick={() => setIsOpen(false)} />
        ) : (
          <ChevronDown onClick={() => setIsOpen(true)} />
        )}
      </ChevronContainer>
    </CollapsibleFiltersContainer>
  );
};
