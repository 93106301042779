import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  SubTitle,
  ContentWrapper,
  Paper,
  SettingsRow,
} from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import Button from '@components/Button';
import routes from '@routes';
import Select from '@components/Select';
import { editorFields } from '@containers/ToolMessages/constants';
import DateRangeSelector from '@components/DateRangeSelector';

export const SelectContainer = styled.div`
  width: 500px;
`;

const ToolMessagesEditor = ({
  toolMessagesStore: {
    toolMessage,
    fetchToolMessage,
    isLoading,
    validationErrors,
    setToolMessage,
    updateToolMessage,
    clearToolMessage,
  },
}) => {
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();

  const shouldCreate = search.create === 'true';
  const cancelRoute = shouldCreate
    ? routes.toolMessages
    : `${routes.toolMessage}?id=${search.id}`;

  useEffect(() => {
    if (search.id) {
      fetchToolMessage(search.id);
    } else {
      setToolMessage({
        description: '',
        severity: '',
        valid_from: new Date(),
        valid_to: new Date(),
      });
    }
    return () => clearToolMessage();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>ToolMessage: {toolMessage.id}</Text>
      </SubTitle>
      <Paper>
        {editorFields.map(
          ({
            id,
            label,
            component,
            type,
            options,
            startDateId,
            endDateId,
            showTime,
          }) => (
            <Fragment key={`edit-field-${id}`}>
              {component === 'input' && (
                <SettingsRow>
                  <Input
                    error={validationErrors[id]}
                    maxWidth={500}
                    value={toolMessage[id] || ''}
                    onChange={(value) => setToolMessage({ [id]: value })}
                    label={label}
                    placeholder={`${label}...`}
                    inputType={type}
                  />
                </SettingsRow>
              )}
              {component === 'select' && (
                <SettingsRow>
                  <SelectContainer>
                    <Select
                      label={label}
                      placeholder={`${label}...`}
                      onChange={(value) => setToolMessage({ [id]: value })}
                      value={toolMessage[id]}
                      options={options}
                    />
                  </SelectContainer>
                </SettingsRow>
              )}
              {component === 'dateRange' &&
              toolMessage[startDateId] &&
              toolMessage[endDateId] ? (
                <SettingsRow>
                  <DateRangeSelector
                    label={label}
                    startDate={new Date(toolMessage[startDateId])}
                    endDate={new Date(toolMessage[endDateId])}
                    setStartDate={(value) =>
                      setToolMessage({ [startDateId]: value })
                    }
                    setEndDate={(value) =>
                      setToolMessage({ [endDateId]: value })
                    }
                    showTimeSelect={showTime}
                  />
                </SettingsRow>
              ) : null}
            </Fragment>
          ),
        )}
      </Paper>
      <SettingsRow>
        <Button onClick={() => updateToolMessage(shouldCreate)}>Save</Button>
        <Button onClick={() => navigate(cancelRoute)}>Cancel</Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('toolMessagesStore')(observer(ToolMessagesEditor));
