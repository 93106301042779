import routes from '@routes';

export const PAGE_LABELS = {
  [routes.wraps]: 'Wraps',
  [routes.wrapsEditor]: 'Wraps editor',
  [routes.companies]: 'Companies',
  [routes.companiesCreator]: 'Create companies',
  [routes.articleSourceRequests]: 'Article source requests',
  [routes.articleSourceRequestCreator]: 'Create article source request',
  [routes.companiesEditor]: 'Edit company',
  [routes.subscriptions]: 'Subscriptions',
  [routes.subscription]: 'Subscription',
  [routes.contentOrder]: 'Content order',
  [routes.contentOrderEditor]: 'Content order editor',
  [routes.briefingTypes]: 'Briefing types',
  [routes.briefingTypeCreator]: 'Create briefing type',
  [routes.briefingType]: 'Briefing type details',
  [routes.delayedJobs]: 'Delayed jobs',
  [routes.companyCreator]: 'Create company',
  [routes.companyRelations]: 'Company relations list',
  [routes.companyRelationsEditor]: 'Company relation editor',
  [routes.switchArticleSources]: 'Switch article sources',
  [routes.articleSources]: 'Article sources',
  [routes.articleSource]: 'Article source',
  [routes.articleSourceEditor]: 'Article source editor',
  [routes.toolMessages]: 'Tool messages',
  [routes.toolMessage]: 'Tool message',
  [routes.toolMessageEditor]: 'Tool message editor',
  [routes.users]: 'Users',
  [routes.user]: 'User',
  [routes.userEditor]: 'User editor',
  [routes.toolMessages]: 'Skipped author terms',
  [routes.toolMessageEditor]: 'Skipped author terms editor',
  [routes.userSettings]: 'User settings',
  [routes.userSettingsUpdate]: 'User settings update',
  [routes.subscriptionUsers]: 'Subscription users',
  [routes.userInvitations]: 'User invitations',
  [routes.userInvitationCreator]: 'User invitations creator',
  [routes.nlaOrganisations]: 'NLA organisations',
  [routes.nlaOrganisation]: 'NLA organisation',
  [routes.nlaOrganisationEditor]: 'NLA organisation editor',
  [routes.contentOrderAddUsers]: 'Add users to content order',
};
