import React from 'react';
import Text, { TAGS } from '@components/Text';
import { Wrapper } from '@containers/NotFound/style';

const NotFoundPage = () => (
  <Wrapper>
    <Text tag={TAGS.H1}>404</Text>
    <Text tag={TAGS.H2}>Page not found</Text>
  </Wrapper>
);

export default NotFoundPage;
