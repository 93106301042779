import React, { useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  ContentWrapper,
  OnlyMobilePaper,
  SettingsRow,
} from '@styles/globalStyles';
import Input, { INPUT_TYPES } from '@components/Input';
import ColorPicker from '@components/ColorPicker';
import { CompanyImage, ErrorMessage, Label } from '@containers/Companies/style';
import Button from '@components/Button';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';
import Preloader from '@components/Preloader';
import { getBase64 } from '@utils/getBase64';

const CompanyEditor = ({
  companiesStore: {
    companyToEdit,
    updateCompany,
    updateFormErrors,
    isLoading,
    validateCompany,
  },
  authStore: { darkMode },
}) => {
  if (Object.keys(companyToEdit).length === 0) {
    routerStore.push(routes.companies);
  }
  const hiddenLogoInputRef = useRef(null);
  const hiddenSvgLogoInputRef = useRef(null);
  const [name, setName] = useState(companyToEdit.name);
  const [url, setUrl] = useState(companyToEdit.meta.website_url);
  const [color, setColor] = useState(companyToEdit.meta.color);
  const [logo, setLogo] = useState(companyToEdit.logo_url);
  const [notes, setNotes] = useState(companyToEdit.meta.company_notes);
  const [fileName, setFileName] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [svgLogo, setSvgLogo] = useState(companyToEdit.svg_logo_url);
  const [svgFileName, setSvgFileName] = useState(null);
  const [svgFileType, setSvgFileType] = useState(null);

  const save = () => {
    updateCompany({
      name,
      url,
      color,
      logo: {
        base64Logo: logo,
        name: fileName,
        type: fileType,
      },
      svgLogo: {
        base64Logo: svgLogo,
        name: svgFileName,
        type: svgFileType,
      },
      id: companyToEdit.id,
      notes,
    });
  };

  const handleLogoInput = async (file) => {
    const isValid = validateCompany({
      name,
      url,
      color,
      logo: {
        base64Logo: logo,
        name: file.name,
        type: file.type,
      },
      svgLogo: {
        name: null,
      },
    });
    if (isValid) {
      setFileName(file.name);
      setFileType(file.type);
      const base64File = await getBase64(file);
      setLogo(base64File);
    }
  };

  const handleSvgLogoInput = async (file) => {
    const isValid = validateCompany({
      name,
      url,
      color,
      logo: {
        name: null,
      },
      svgLogo: {
        base64Logo: svgLogo,
        name: file.name,
        type: file.type,
      },
    });
    if (isValid) {
      setSvgFileName(file.name);
      setSvgFileType(file.type);
      const base64File = await getBase64(file);
      setSvgLogo(base64File);
    }
  };

  const handleHiddenInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <OnlyMobilePaper>
        <SettingsRow>
          <Input
            error={updateFormErrors.name}
            maxWidth={300}
            value={name || ''}
            onChange={setName}
            label="Name"
            placeholder="Company name..."
          />
        </SettingsRow>
        <SettingsRow>
          <Input
            error={updateFormErrors.url}
            maxWidth={300}
            value={url || ''}
            onChange={setUrl}
            label="URL"
            placeholder="Company URL..."
          />
        </SettingsRow>
        <SettingsRow>
          <Input
            error={updateFormErrors.url}
            maxWidth={300}
            value={notes || ''}
            onChange={setNotes}
            label="Notes"
            placeholder="Company notes..."
          />
        </SettingsRow>
        <Label>Colour</Label>
        <SettingsRow className="small-margin">
          <ColorPicker color={color} onChange={setColor} />
        </SettingsRow>
        <Label>Logo</Label>
        <SettingsRow className="small-margin">
          {logo ? (
            <CompanyImage darkMode={darkMode} alt="logo" src={logo} />
          ) : (
            <Label>(No logo)</Label>
          )}
        </SettingsRow>
        {updateFormErrors.logo ? (
          <ErrorMessage>{updateFormErrors.logo}</ErrorMessage>
        ) : null}
        <SettingsRow>
          <Button onClick={() => handleHiddenInput(hiddenLogoInputRef)}>
            Upload logo
          </Button>
          <Input
            inputRef={hiddenLogoInputRef}
            inputType={INPUT_TYPES.FILE}
            maxWidth={300}
            onChange={handleLogoInput}
          />
        </SettingsRow>
        <Label>SVG Logo</Label>
        <SettingsRow className="small-margin">
          {svgLogo ? (
            <CompanyImage darkMode={darkMode} alt="logo" src={svgLogo} />
          ) : (
            <Label>(No SVG logo)</Label>
          )}
        </SettingsRow>
        {updateFormErrors.svgLogo ? (
          <ErrorMessage>{updateFormErrors.svgLogo}</ErrorMessage>
        ) : null}
        <SettingsRow>
          <Button onClick={() => handleHiddenInput(hiddenSvgLogoInputRef)}>
            Upload svg logo
          </Button>
          <Input
            inputRef={hiddenSvgLogoInputRef}
            inputType={INPUT_TYPES.FILE}
            maxWidth={300}
            onChange={handleSvgLogoInput}
          />
        </SettingsRow>
        <SettingsRow>
          <Button onClick={save}>Save</Button>
        </SettingsRow>
      </OnlyMobilePaper>
    </ContentWrapper>
  );
};

export default inject('companiesStore', 'authStore')(observer(CompanyEditor));
