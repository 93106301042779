import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  SubTitle,
  ContentWrapper,
  Paper,
  SettingsColumn,
  SettingsRow,
} from '@styles/globalStyles';
import Text, { TAGS } from '@components/Text';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import useBreakpoint from '@root/hooks/useBreakpoint';
import JSONInput from '@components/JSONInput';
import Button from '@components/Button';
import routes from '@routes';
import Checkbox from '@containers/ArticleSources/components/Checkbox';
import Select from '@components/Select';
import { editorFields } from '@containers/ArticleSources/constants';

export const SelectContainer = styled.div`
  width: 500px;
`;

const ArticleSourceEditor = ({
  articleSourcesStore: {
    articleSource,
    fetchArticleSource,
    isLoadingArticleSource,
    validationErrors,
    setArticleSource,
    updateArticleSource,
    fetchNewspapers,
    newspapers,
    fetchAvailableLanguages,
    availableLanguages,
    fetchAvailableLicensors,
    availableLicensors
  },
}) => {
  const { search } = useCustomSearchParams();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const navigate = useNavigate();

  useEffect(() => {
    if (search.id) {
      fetchArticleSource(search.id);
      fetchNewspapers();
      fetchAvailableLanguages();
      fetchAvailableLicensors();
    }
  }, []);

  if (isLoadingArticleSource) {
    return <Preloader overlay />;
  }

  const fields = editorFields(newspapers, availableLanguages, availableLicensors);

  return (
    <ContentWrapper>
      <SubTitle>
        <Text tag={TAGS.H2}>{articleSource.title}</Text>
      </SubTitle>
      <Paper>
        {fields.map(
          (
            { id, label, component, type, checkboxes, options, isClearable, condition },
            index,
          ) => (
            <Fragment key={`edit-field-${index}`}>
              {component === 'input' && (condition === undefined || condition(articleSource)) && (
                <SettingsRow>
                  <Input
                    error={validationErrors[id] || null}
                    maxWidth={500}
                    value={articleSource[id] || ''}
                    onChange={(value) => setArticleSource({ [id]: value })}
                    label={label}
                    placeholder={`${label}...`}
                    inputType={type}
                  />
                </SettingsRow>
              )}
              {component === 'select' && (
                <SettingsRow>
                  <SelectContainer>
                    <Select
                      label={label}
                      placeholder={`${label}...`}
                      onChange={(value) => setArticleSource({ [id]: value })}
                      value={articleSource[id]}
                      options={options}
                      isClearable={isClearable}
                    />
                  </SelectContainer>
                </SettingsRow>
              )}
              {component === 'checkboxes' && isMobile && (
                <SettingsColumn>
                  {checkboxes.map((checkbox, idx) => (
                    <Checkbox
                      key={`checkboxes-${idx}`}
                      articleSource={articleSource}
                      setArticleSource={setArticleSource}
                      id={checkbox.id}
                      label={checkbox.label}
                    />
                  ))}
                </SettingsColumn>
              )}
              {component === 'checkboxes' && !isMobile && (
                <SettingsRow>
                  {checkboxes.map((checkbox, idx) => (
                    <Checkbox
                      key={`checkboxes-${idx}`}
                      articleSource={articleSource}
                      setArticleSource={setArticleSource}
                      id={checkbox.id}
                      label={checkbox.label}
                    />
                  ))}
                </SettingsRow>
              )}
              {component === 'json' && !isMobile && (
                <JSONInput
                  label={label}
                  value={articleSource[id]}
                  onChange={(value) => setArticleSource({ [id]: value })}
                />
              )}
            </Fragment>
          ),
        )}
      </Paper>
      <SettingsRow>
        <Button onClick={updateArticleSource}>Save</Button>
        <Button
          onClick={() => navigate(`${routes.articleSource}?id=${search.id}`)}
        >
          Cancel
        </Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('articleSourcesStore')(observer(ArticleSourceEditor));
