import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Input from '@components/Input';
import Table from '@components/Table';
import useDebounce from '@root/hooks/useDebounce';
import Preloader from '@components/Preloader';
import routes from '@routes';
import { ButtonContainer } from '@containers/Wraps/style';
import Button from '@components/Button';
import useBreakpoint from '@root/hooks/useBreakpoint';

const columns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'last_published_at',
    label: 'Last published at',
    format: (value) => moment(value).format('LL').toString(),
  },
  {
    id: 'disabled',
    label: 'disabled',
    format: (value) => value.toString(),
    maxWidth: 90,
  },
];

const BriefingTypes = ({
  briefingsStore: {
    fetchBriefingTypes,
    briefingTypes,
    isLoading,
    briefingTypesCount,
  },
}) => {
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const columnActions = {
    id: (row) => navigate(`${routes.briefingType}?id=${row.id}`),
  };

  useEffect(() => {
    clear();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchBriefingTypes({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchBriefingTypes({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery('');
    await fetchBriefingTypes({
      limit: rowsPerPage,
      page: 0,
      searchQuery: '',
    });
  };

  useDebounce(searchQuery, 300, () => {
    handleChangePage(0);
  });

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <FiltersContainer>
        <Input
          onChange={setSearchQuery}
          value={searchQuery}
          maxWidth={225}
          label="Name"
          placeholder="Name..."
        />
        <ButtonContainer>
          <Button onClick={() => navigate(routes.briefingTypeCreator)}>
            {isMobile ? 'Add briefing type' : 'Create briefing type'}
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      <Table
        rowsPerPageOptions={[10, 50, 100]}
        count={briefingTypesCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={columns}
        data={briefingTypes}
        columnActions={columnActions}
      />
    </ContentWrapper>
  );
};

export default inject('briefingsStore')(observer(BriefingTypes));
