import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

export class CompanyRelationsStore {
  isLoading = false;

  isLoadingOptions = false;

  isLoadingRelation = false;

  isLoadingTable = false;

  data = [];

  totalRowsNumber = 0;

  companyOptionFields = {
    companies: [],
    relatedCompanies: [],
    relationTypes: [],
  };

  company = 'all';

  relatedCompany = 'all';

  relationType = '';

  relationId = '';

  notes = '';

  relationTypesObj = {};

  constructor() {
    makeAutoObservable(this);
  }

  setCompany = (company) => {
    this.company = company;
  };

  setRelatedCompany = (company) => {
    this.relatedCompany = company;
  };

  setRelationType = (relationType) => {
    this.relationType = relationType;
  };

  setRelationId = (id) => {
    this.relationId = id;
  };

  setNotes = (notes) => {
    this.notes = notes;
  };

  updateRelation = async () => {
    this.isLoading = true;
    try {
      const response = await API.patch(
        apiRoutes.company_relations.relation(this.relationId),
        {
          company_relation: {
            source_content_container_id: this.company,
            destination_content_container_id: this.relatedCompany,
            relation_type: this.relationType,
            notes: this.notes,
          },
        },
      );
      if (response.data.message) {
        toast.error(response.data.message);
      } else {
        routerStore.push(routes.companyRelations);
        toast.success('Relation successfully edited!');
      }
    } catch (e) {
      this.error = e;
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  removeRelation = async (id) => {
    try {
      await API.delete(apiRoutes.company_relations.relation(id));
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      await this.fetchTable({});
    }
  };

  createRelation = async () => {
    this.isLoading = true;
    try {
      const response = await API.post(apiRoutes.company_relations.relations, {
        company_relation: {
          source_content_container_id: this.company,
          destination_content_container_id: this.relatedCompany,
          relation_type: this.relationType,
          notes: this.notes,
        },
      });
      if (response.data.message) {
        toast.error(response.data.message);
      } else {
        toast.success('Relation successfully add!');
        routerStore.push(routes.companyRelations);
      }
    } catch (e) {
      this.isLoading = false;
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  fetchCompanyOptionFields = async () => {
    this.isLoadingOptions = true;
    try {
      const companies = await API.get(apiRoutes.companies.all, {
        params: {
          limit: 10000,
        },
      });
      this.companies = companies.data.companies.map((c) => ({
        label: c.name,
        value: c.id,
      }));
      this.companyOptionFields.companies = this.companies;
      this.companyOptionFields.relatedCompanies = this.companies;
      const relationTypes = await API.get(
        apiRoutes.company_relations.relationTypes,
      );
      this.companyOptionFields.relationTypes =
        relationTypes.data.relation_types;
      this.companyOptionFields.relationTypes.forEach((rt) => {
        this.relationTypesObj[rt.label] = rt.value;
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingOptions = false;
    }
  };

  fetchTable = async ({ page, limit }) => {
    this.isLoadingTable = true;

    try {
      const params = {
        limit: parseInt(limit ? limit.toString() : 10, 10),
        skip: parseInt(page ? (page * limit).toString() : 0, 10),
      };
      const relationsResponse = await API.get(
        apiRoutes.company_relations.relations,
        {
          params,
        },
      );

      this.data = relationsResponse.data.company_relations;
      this.totalRowsNumber = relationsResponse.data.company_relations_count;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingTable = false;
    }
  };

  fetchRelation = async (id) => {
    this.isLoadingRelation = true;

    try {
      const response = await API.get(apiRoutes.company_relations.relation(id));
      this.relationId = response.data.id;
      this.company = response.data.source_content_container_id;
      this.relatedCompany = response.data.destination_content_container_id;
      this.relationType = response.data.relation_type;
      this.notes = response.data.notes;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoadingRelation = false;
    }
  };

  clearStore = () => {
    this.isLoading = false;
    this.isLoadingOptions = false;
    this.isLoadingRelation = false;
    this.isLoadingTable = false;
    this.data = [];
    this.totalRowsNumber = 0;
    this.companyOptionFields = {
      companies: [],
      relatedCompanies: [],
      relationTypes: [],
    };
    this.company = 'all';
    this.relatedCompany = 'all';
    this.relationType = '';
    this.relationId = '';
    this.notes = '';
    this.relationTypesObj = {};
  };
}

export default new CompanyRelationsStore();
