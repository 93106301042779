import styled from '@emotion/styled';
import { ContentWrapper } from '@styles/globalStyles';

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.navbar};
  height: 90px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
  position: fixed;
  top: 0;
  width: calc(
    100% -
      ${({ menuCollapsed, theme }) =>
        menuCollapsed ? 96 : theme.defaults.desktopMenuWidth}px
  );
`;

export const Container = styled(ContentWrapper)`
  display: flex;
  top: 0;
  margin-top: 0;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  position: relative;
  line-height: 90px;
`;
