import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

export class NlaOrganisationsStore {
  isLoading = false;

  nlaOrganisations = [];

  nlaOrganisationsCount = 0;

  nlaOrganisation = {};

  validationErrors = {};

  companies = [];

   constructor() {
    makeAutoObservable(this);
  }

  fetchNlaOrganisations = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.nlaOrganisations.index, {
        params: query,
      });
      runInAction(() => {
        this.nlaOrganisations = response.data.nla_organisations;
        this.nlaOrganisationsCount = response.data.nla_organisations_count;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchNlaOrganisation = async (id) => {
    this.isLoading = true;
    try {
      const response = await API.get(apiRoutes.nlaOrganisations.record(id));
      runInAction(() => {
        this.nlaOrganisation = response.data;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  setNlaOrganisation = (valueObj) => {
    this.nlaOrganisation = { ...this.nlaOrganisation, ...valueObj };
  };

  validateNlaOrganisation = () => {
    this.validationErrors.name =
      this.nlaOrganisation.name === null ||
      this.nlaOrganisation.name.length === 0
        ? 'Name should not be empty'
        : undefined;

  };

  updateNlaOrganisation = async (create) => {
    this.isLoading = true;
    this.validateNlaOrganisation();
    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }

    let response = null;
    try {
      if (create) {
        response = await API.post(apiRoutes.nlaOrganisations.index, {
          nla_organisation: this.nlaOrganisation
        });
      } else {
        response = await API.patch(
          apiRoutes.nlaOrganisations.record(this.nlaOrganisation.id),
          {
            nla_organisation: this.nlaOrganisation
          },
        );
      }

      if (response.data.message) {
        if (create) {
          routerStore.push(routes.nlaOrganisations);
        } else {
          routerStore.push(`${routes.nlaOrganisation}?id=${this.nlaOrganisation.id}`);
        }
        toast.success(response.data.message);
      } else {
        toast.error(response.data.error);
      }
    } catch (e) {
      this.error = e;
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }

    this.isLoading = false;
  };

  removeNlaOrganisation = async (id) => {
    try {
      await API.delete(apiRoutes.nlaOrganisations.record(id));
    } catch (e) {
      toast.error('Something went wrong!');
    } finally {
      await this.fetchNlaOrganisations({});
    }
  };

  clearNlaOrganisation = () => {
    this.nlaOrganisation = {};
  };
}

export default new NlaOrganisationsStore();
