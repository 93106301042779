import styled from '@emotion/styled';
import { InfoCircle, Lock, LockOpen, Trash } from 'tabler-icons-react';

export const ContentOrderLabel = styled.div`
  margin-bottom: 10px;
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100%;

  &.uppercase {
    text-transform: uppercase;
  }
`;

export const SelectContainer = styled.div`
  width: 150px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -27px;
`;

export const TrashIcon = styled(Trash)`
  margin: auto 0 -3px 0;
`;

export const LockIcon = styled(Lock)`
  height: 18px;
  width: 18px;
`;

export const LockOpenIcon = styled(LockOpen)`
  height: 18px;
  width: 18px;
  min-width: 18px;
`;

export const SectionActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 5px;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 9px;
  margin-left: 5px;
`;

export const SelectContainerIndex = styled.div`
  min-width: 88px;
`;

export const ButtonContainer = styled.div`
  margin-top: 21px;

  &.right {
    margin-left: auto;
  }
`;

export const TrashIconLogo = styled(Trash)`
  margin: auto 0;
`;
