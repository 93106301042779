import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars';

export const NewspaperDialogContainer = styled.div`
  margin: 20px 10px;
`;

export const SelectContainer = styled.div`
  margin-top: -1px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: -10px;

  &.first {
    margin-top: 20px;
  }
`;

export const ScrollableContent = styled(Scrollbars)`
  .scrollable {
    overflow-x: hidden !important;
  }
`;

export const ListContainer = styled.div`
  height: calc(90vh - 130px);
`;
