import moment from 'moment';

export const detailsFields = [
  { id: 'id' },
  { id: 'name' },
  { id: 'contract_name' },
  { id: 'is_default', format: (value) => value.toString() },
  { id: 'is_active', format: (value) => value.toString() },
  { id: 'has_briefings', format: (value) => value.toString() },
  { id: 'user_limit' },
  {
    id: 'created_at',
    format: (value) => moment(value).format('LL').toString(),
  },
  {
    id: 'updated_at',
    format: (value) => moment(value).format('LL').toString(),
  },
  {
    id: 'full_article_links_in_briefings',
    format: (value) => value.toString(),
  },
  {
    id: 'company_groups',
    format: (value) => value.map(({ name }) => name).join(', '),
  },
  {
    id: 'primary_companies',
    format: (value) => value.map(({ name }) => name).join(', '),
  },
  {
    id: 'secondary_companies',
    format: (value) => value.map(({ name }) => name).join(', '),
  },
  {
    id: 'peer_companies',
    format: (value) => value.map(({ name }) => name).join(', '),
  },
  {
    id: 'portfolio_companies',
    format: (value) => value.map(({ name }) => name).join(', '),
  },
  {
    id: 'selected_bespoke_sections_for',
    format: (value) => value.map(({ name }) => name).join(', '),
  },
  {
    id: 'selected_standard_sections_for',
    format: (value) => value.map(({ name }) => name).join(', '),
  },
  {
    id: 'article_sources',
    format: (value) =>
      value.map(({ id, title }) => `(${id}) ${title}`).join(', '),
    border: true,
  },
  {
    id: 'excluded_article_sources',
    format: (value) =>
      value.map(({ id, title }) => `(${id}) ${title}`).join(', '),
    border: true,
  },
  {
    id: 'nla_organisations',
    format: (value) =>
      value.map(({ id, name }) => `(${id}) ${name}`).join(', '),
    border: true,
  },
  {
    id: 'users',
    format: (value) => value.map(({ email }) => email).join(', '),
  },
];

export const addUsersEditorFieldsFunc = (companies, isLoadingCompanies) => [
  {
    id: 'id',
    label: 'Content order ID',
    component: 'input',
    type: 'number',
    readOnly: true
  },
  {
    id: 'content_container_id',
    label: 'Content container ID',
    placeholder: 'Content container ID...',
    component: 'select',
    options: companies,
    isLoading: isLoadingCompanies,
    isClearable: true,
  },
  {
    checkboxes: [
      { id: 'follow_for_companies' },
      { id: 'push_notifications_for_companies' },
      { id: 'email_notifications_for_companies' },
    ],
    component: 'checkboxes',
  },
  {
    checkboxes: [
      { id: 'follow_for_briefing_types' },
      { id: 'push_notifications_for_briefing_types'  },
      { id: 'email_notifications_for_briefing_types' },
    ],
    component: 'checkboxes',
  },
  {
    id: 'invitations_list',
    label: 'Invitations list (file)',
    component: 'fileInput',
    type: 'file',
  }
];
