export const tableColumns = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 70,
  },
  {
    id: 'name',
    label: 'name',
  },
  {
    label: 'Actions',
    id: 'actions',
    maxWidth: 80,
  },
];

export const editorFields = [
  {
    id: 'name',
    label: 'Name',
    component: 'input',
    type: 'text',
  },
];

export const detailsFields = [
  { id: 'name' },
  { id: 'companies', format: (companies) => companies.map(({ name, id }) => `${name} (${id})`).join(', ') },
  { id: 'content_orders', format: (contentOrders) => contentOrders.map(({ name, id }) => `${name} (${id})`).join(', ') },
  { id: 'internal_users', format: (users) => users.map(({ email }) => email).join(', ') },
];

export const filters = [
  { id: 'name', label: 'Name', type: 'input' },
];
