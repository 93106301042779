export const tableColumns = [
  {
    label: 'ID',
    id: 'id',
    minWidth: 60,
    maxWidth: '10%',
  },
  {
    label: 'Company',
    id: 'source_content_container_name',
    minWidth: 60,
    maxWidth: '30%',
  },
  {
    label: 'Related company',
    id: 'destination_content_container_name',
    minWidth: 60,
    maxWidth: '30%',
  },
  {
    label: 'Relation type',
    id: 'relation_type',
    minWidth: 60,
    maxWidth: '30%',
  },
  {
    label: 'Notes',
    id: 'notes',
    minWidth: 60,
    maxWidth: '30%',
  },
  {
    id: 'actions',
    label: 'Actions',
    maxWidth: 100,
    align: 'left',
  },
];
