import React from 'react';
import Control from '@components/Control';
import {
  CheckListContainer,
  CheckListItem,
  Title,
} from '@components/CheckList/style';

const CheckList = ({ data, onChange, onChangeKey, checkboxKey, labelKey }) => (
  <CheckListContainer>
    {data.map((item) => (
      <CheckListItem key={item.id || Math.floor(Math.random() * 100)}>
        <Control
          name={item.id || Math.floor(Math.random() * 100)}
          value={item[checkboxKey]}
          checked={item[checkboxKey]}
          onChange={() => onChange(item[onChangeKey])}
          type="checkbox"
        />
        <Title>{item[labelKey]}</Title>
      </CheckListItem>
    ))}
  </CheckListContainer>
);

export default CheckList;
