import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import routes from '@routes';
import { filtersFunc, tableColumns } from '@containers/Subscriptions/constants';
import Filters from '@components/Filters';
import TableDialog from '@components/TableDialog';

const Subscriptions = ({
  subscriptionsStore: {
    fetchSubscriptions,
    subscriptions,
    isLoading,
    subscriptionsCount,
    fetchSubscriptionPlans,
    subscriptionPlans,
    isLoadingSubscriptionPlans,
    fetchStates,
    states,
    isLoadingStates,
    fetchAdmins,
    isLoadingAdmins,
    admins,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    values: [],
    dialogName: '',
  });
  const navigate = useNavigate();

  const filters = useMemo(
    () =>
      filtersFunc(
        subscriptionPlans,
        isLoadingSubscriptionPlans,
        states,
        isLoadingStates,
        admins,
        isLoadingAdmins,
      ),
    [
      subscriptionPlans,
      isLoadingSubscriptionPlans,
      states,
      isLoadingStates,
      admins,
      isLoadingAdmins,
    ],
  );

  const columnActions = {
    id: (row) => navigate(`${routes.subscription}?id=${row.id}`),
    users: (row) => {
      setDialogParams({
        values: row.users.map(({ email }) => email),
        dialogName: row.name,
      });
      setIsDialogOpen(true);
    },
    invitations: (row) => {
      setDialogParams({
        values: row.invitations,
        dialogName: row.name,
      });
      setIsDialogOpen(true);
    },
  };

  useEffect(() => {
    fetchSubscriptionPlans();
    fetchStates();
    fetchAdmins();
    clear();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchSubscriptions({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchSubscriptions({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery({});
    await fetchSubscriptions({
      limit: rowsPerPage,
      page: 0,
      searchQuery: {},
    });
  };

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <TableDialog
        closeHandler={() => {
          setIsDialogOpen(false);
        }}
        isOpen={isDialogOpen}
        dialogParams={dialogParams}
      />
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
      />
      <Table
        rowsPerPageOptions={[10, 50, 100]}
        count={subscriptionsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={subscriptions}
        columnActions={columnActions}
      />
    </ContentWrapper>
  );
};

export default inject('subscriptionsStore')(observer(Subscriptions));
