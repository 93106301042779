import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import {
  Color,
  ColorContainer,
  Cover,
  Popover,
} from '@components/ColorPicker/styles';

const ColorPicker = ({ color, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    onChange(newColor.hex);
  };

  return (
    <div>
      <ColorContainer onClick={handleClick}>
        <Color color={color}>{color}</Color>
      </ColorContainer>
      {displayColorPicker ? (
        <Popover>
          <Cover onClick={handleClose} />
          <SketchPicker disableAlpha color={color} onChange={handleChange} />
        </Popover>
      ) : null}
    </div>
  );
};

export default ColorPicker;
