import {
  ArrowBarToDown,
  ArrowBarToUp,
  ChevronDown,
  ChevronUp,
} from 'tabler-icons-react';
import React from 'react';
import { CheckListItem, Title } from '@components/CheckList/style';
import Control from '@components/Control';
import {
  LockIcon,
  LockOpenIcon,
  SectionActions,
} from '@containers/Wraps/style';

const WrapElementSection = ({
  index,
  style,
  data: { wrapElement, changeSectionChecked, moveSection, sections, isMobile },
}) => {
  const section = sections[index];
  return (
    <CheckListItem style={style} key={section.id} role={undefined} dense button>
      <Control
        name={`section_${section.id}_checked_${index}`}
        value={section.checked}
        checked={section.checked}
        onChange={() => changeSectionChecked(wrapElement, section.id)}
        type="checkbox"
      />
      <Title big={isMobile}>
        {section.sectionType === 'standard' ? (
          <LockOpenIcon style={{ color: '#3f51b5', height: 18, width: 18 }} />
        ) : (
          <LockIcon style={{ color: '#3f51b5', height: 18, width: 18 }} />
        )}
        {section.name}
      </Title>
      <SectionActions>
        <ChevronUp
          aria-label="up"
          onClick={() => moveSection(wrapElement.sections, index, index - 1)}
        />
        <ChevronDown
          aria-label="down"
          onClick={() => moveSection(wrapElement.sections, index, index + 1)}
        />
        <ArrowBarToUp
          aria-label="top"
          onClick={() => moveSection(wrapElement.sections, index, 0)}
        />
        <ArrowBarToDown
          aria-label="last"
          onClick={() =>
            moveSection(
              wrapElement.sections,
              index,
              wrapElement.sections.length - 1,
            )
          }
        />
      </SectionActions>
    </CheckListItem>
  );
};

export default WrapElementSection;
