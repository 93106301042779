import React from 'react';
import { Global, css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import reset from 'emotion-reset';
import ReactTooltip from 'react-tooltip';
import { breakpoint } from '@styles/breakpoints';
import useBreakpoint from '@root/hooks/useBreakpoint';

const GlobalStyle = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;500;700&display=swap');
        ${reset}

        * {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        body {
          color: #000;
          background-color: #fafbfc;
          font-family: ${theme.defaults.fontFamily.primary}, sans-serif;
          // transform: scale(0.8);
          // transform-origin: 0 0;
          // zoom: 80%;
          // -moz-transform: scale(0.8);
          // -moz-transform-origin: 0 0;

          ::-webkit-scrollbar {
            width: 8px;
          }

          ::-webkit-scrollbar-thumb {
            background: ${theme.scroll.default};
            border-radius: 3px;
          }

          ::-webkit-scrollbar-track {
            background-color: ${theme.scroll.secondary};
          }
        }

        html {
          box-sizing: border-box;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        button {
          background: transparent;
          border: none;
          cursor: pointer;
        }

        a {
          text-decoration: none;
        }

        .scroll-lock {
          overflow-y: hidden;
        }
        /* Firefox scrollbar */
        /* * {
          scrollbar-width: thin;
          scrollbar-color: gray lightgray;
        } */

        /* Chrome, Edge, Safari scrollbar*/
        /* *::-webkit-scrollbar {
          width: 6px;
        }

        *::-webkit-scrollbar-track {
          background: lightgray;
        }

        *::-webkit-scrollbar-thumb {
          background-color: gray;
          border-radius: 20px;
        } */

        .slidein-enter {
          left: -100vw;
          transform: translateX(0);
          transform-origin: left;
        }
        .slidein-enter-active {
          left: 0;
          transform: translateX(0vw);
          transition: left 300ms, transform 300ms;
        }
        .slidein-exit {
          left: 0;
          transform: translateX(0vw);
          transform-origin: left;
        }
        .slidein-exit-active {
          transform: translateX(0);
          left: -100vw;
          transition: left 300ms, transform 300ms;
        }

        .fadein-enter {
          opacity: 0;
        }
        .fadein-enter-active {
          opacity: 1;
          transition: opacity 300ms;
        }
        .fadein-exit {
          opacity: 1;
        }
        .fadein-exit-active {
          opacity: 0;
          transition: opacity 300ms;
        }

        b {
          font-weight: bold;
        }

        swiper {
          width: 100%;
          height: 100%;
        }

        .swiper {
          //padding: 0 40px;
        }

        .swiper-slide {
          text-align: center;
          font-size: ${theme.defaults.fontSizeXL}px;

          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
        }

        .swiper-slide img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .swiper-button-next {
          color: ${theme.colors.secondary} !important;
        }

        .swiper-button-prev {
          color: ${theme.colors.secondary} !important;
        }

        .swiper-pagination-bullet {
          height: 12px;
          width: 12px;
        }

        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
          bottom: 0;
        }
      `}
    />
  );
};

export const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  padding: 28px;
  min-height: calc(100% - 80px);

  @media only screen and ${breakpoint.md} {
    padding: 5px;
    margin-top: 0px;
  }
  //
  // @media only screen and ${breakpoint.lg} {
  //   padding: 16px;
  // }
`;

export const PageTitle = styled.div`
  padding-bottom: 32px;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;

  @media only screen and (max-width: 1600px) {
    width: 100%;
    padding-bottom: 16px;
    justify-content: center;
  }

  @media only screen and ${breakpoint.md} {
    gap: 16px;
  }
`;

export const Paper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.content};
  box-shadow: rgb(41 35 92 / 3%) 0px -2px 8px 4px;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;

  ${({ noShadow }) =>
    noShadow
      ? css`
          box-shadow: unset;
        `
      : null}
`;

export const OnlyMobilePaper = ({ children }) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  if (isMobile) {
    return <Paper>{children}</Paper>;
  }
  return <>{children}</>;
};

export const StyledTooltip = styled(ReactTooltip)`
  max-width: 40vh;
  text-align: left;
  white-space: normal;
`;

export const SettingsRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  height: 50px;
  gap: 10px;

  &.small-margin {
    margin-bottom: 5px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.checkbox-column {
    flex-direction: column;
    margin-top: 10px;
    gap: 21px;
  }

  & > .preloader {
    max-height: 40px;
  }
`;

export const SettingsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 80px;
  height: 50px;
  gap: 35px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  height: 80px;
  gap: 16px;
  max-width: 95vw;
`;

export const SubTitle = styled.div`
  margin: 15px 0;
`;

export const TrapBox = styled.div`
  position: absolute;
  overflow: hidden;
`;

export default GlobalStyle;
