import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';
import {
  categoryOptions,
  defaultPreviewModeOptions,
  defaultPreviewOptions,
  fetchTypeOptions,
  mediaTypes,
} from '@containers/ArticleSources/constants';
import routerStore from '@root/stores/routerStore';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routes from '@routes';
import ArticleSourceRequestsStorageController from "@utils/ArticleSourceRequestsStorageController";

export class ArticleSourceRequestsStore {
  rowsPerPage = 10;

  isHydrated = false;

  articleSourceRequest = {};

  newspapers = [];

  validationErrors = {};

  newspaper = { title: '' };

  createNewspaper = false;

  articleSourceRequests = [];

  articleSourceRequestsCount = 0;

  isLoading = false;

  serverError = '';

  articleSourceRequestToEdit = {};

  newspapersObject = {};

  availableLanguages = [];

  availableLicensors = []

  articleSourceFields = [
    {
      label: 'Fetch type',
      id: 'fetch_type',
      type: 'select',
      options: fetchTypeOptions,
    },
    { label: 'Title', id: 'title', type: 'input' },
    { label: 'Display title', id: 'display_title', type: 'input' },
    { label: 'Url', id: 'url', type: 'input' },
    { label: 'Url for analysts', id: 'url_for_analysts', type: 'input' },
    { label: 'Region', id: 'region', type: 'input' },
    { label: 'Country', id: 'country', type: 'input' },
    {
      label: 'Newspaper',
      id: 'newspaper_id',
      type: 'select',
      options: this.newspapers,
    },
    {
      label: 'Language',
      id: 'lang',
      type: 'select',
      options: this.availableLanguages,
      isClearable: true,
    },
    {
      label: 'Category',
      id: 'category',
      type: 'select',
      options: categoryOptions,
    },
    {
      label: 'Default preview',
      id: 'default_preview',
      type: 'select',
      options: defaultPreviewOptions,
    },
    {
      label: 'Default preview mode',
      id: 'default_preview_mode',
      type: 'select',
      options: defaultPreviewModeOptions,
    },
    {
      label: 'Licensor',
      id: 'licensor',
      type: 'select',
      options: this.availableLicensors,
    },
    {
      label: 'Lexis Nexis source code',
      id: 'metaValue',
      type: 'input',
    },
    {
      label: 'Media type',
      id: 'media_type',
      type: 'select',
      options: mediaTypes,
    },
    {
      label: 'Direct LN links allowed',
      id: 'direct_ln_links_allowed',
      type: 'checkbox',
    },
    {
      label: 'Public links in briefings',
      id: 'public_article_links_in_briefings',
      type: 'checkbox',
    },
    {
      label: 'Enabled for b2b content orders',
      id: 'enabled_for_b2b_content_orders',
      type: 'checkbox',
    },
  ];

  constructor() {
    makeAutoObservable(this);
    this.articleSourceFields.forEach(({ id, type }) => {
      this.articleSourceRequest[id] = type === 'checkbox' ? false : '';
    });

    const controller = new ArticleSourceRequestsStorageController();

    makePersistable(this, {
      name: 'ArticleSourceRequestsStore',
      properties: [
        'rowsPerPage',
      ],
      storage: controller,
      stringify: false,
    }).then((store) => {
      this.isHydrated = store.isHydrated;
    });
  }

  setRowsPerPage = (value) => {
    this.rowsPerPage = value;
  }

  fillArticleSourceRequestForEdit = (defaultValues) => {
    if (Object.keys(this.articleSourceRequestToEdit).length > 0) {
      this.isLoading = true;
      this.articleSourceFields.forEach(({ id }) => {
        if (id === 'metaValue') {
          this.articleSourceRequest[id] = this.extractMeta(
            this.articleSourceRequestToEdit.meta,
            this.articleSourceRequestToEdit.fetch_type,
          );
        } else {
          this.articleSourceRequest[id] = this.articleSourceRequestToEdit[id];
        }
      });
      this.isLoading = false;
    } else {
      this.articleSourceRequest = defaultValues;
    }
  };

  clearArticleSourceRequestToEdit = () => {
    this.articleSourceRequestToEdit = {};
    this.articleSourceRequest = {};
  };

  changeArticleSourceField = (key, value) => {
    this.articleSourceRequest[key] = value;
  };

  toggleCreateNewspaper = (value) => {
    this.createNewspaper = value;
    if (value) {
      this.articleSourceRequest.newspaper_id = '';
    }
  };

  changeNewspaperField = (key, value) => {
    this.newspaper[key] = value;
  };

  editArticleSourceRequest = (articleSourceRequest) => {
    this.articleSourceRequestToEdit = articleSourceRequest;
    routerStore.push(routes.articleSourceRequestCreator);
  };

  save = async () => {
    this.validateForm();

    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }
    this.articleSourceRequest.meta = this.formatMetaValue(
      this.articleSourceRequest.metaValue,
    );

    if (this.createNewspaper) {
      this.articleSourceRequest.newspaper_name = this.newspaper.title;
    }
    const { metaValue, ...articleSourceRequest } = this.articleSourceRequest;

    this.isLoading = true;
    this.serverError = '';
    try {
      let response;
      if (Object.keys(this.articleSourceRequestToEdit).length === 0) {
        response = await API.post(apiRoutes.articleSourceRequests.requests(), {
          article_source_request: articleSourceRequest,
        });
      } else {
        response = await API.patch(
          apiRoutes.articleSourceRequests.requests(
            this.articleSourceRequestToEdit.id,
          ),
          {
            article_source_request: articleSourceRequest,
          },
        );
      }

      if (response.data.errors) {
        this.serverError = response.data.errors.message;
        this.isLoading = false;
        toast.error('Something went wrong!');
      } else {
        routerStore.push(routes.articleSourceRequests);
        toast.success('Article source request successfully saved!');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  validateForm = () => {
    this.articleSourceFields.forEach(({ label, id }) => {
      if (id === 'newspaper_id' && this.createNewspaper) {
        this.validationErrors.newspaper_id = undefined;
        this.validationErrors.newspaper_title =
          this.newspaper.title === null ||
          this.newspaper.title === undefined ||
          this.newspaper.title.length === 0
            ? `${label} should not be empty`
            : undefined;
      } else if (id !== 'lang') {
        this.validationErrors[id] =
          this.articleSourceRequest[id] === null ||
          this.articleSourceRequest[id] === undefined ||
          this.articleSourceRequest[id].length === 0
            ? `${label} should not be empty`
            : undefined;
      }
    });
  };

  fetchArticleSourceRequests = async ({ limit, page }) => {
    this.isLoading = true;
    this.articleSourceRequests = [];

    const query = {
      limit: parseInt(limit ? limit.toString() : 10, 10),
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
    };

    try {
      const response = await API.get(
        apiRoutes.articleSourceRequests.requests(),
        {
          params: query,
        },
      );
      this.articleSourceRequests = response.data.article_source_requests;
      this.articleSourceRequestsCount =
        response.data.article_source_requests_count;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  fetchNewspapers = async () => {
    this.isLoading = true;
    try {
      const response = await API.get(
        apiRoutes.articleSourceRequests.newspapers,
      );
      this.newspapers = response.data.newspapers.map(({ id, title }) => ({
        value: id,
        label: `${title} (${id})`,
      }));
      const newspaperFieldIndex = this.articleSourceFields.findIndex(
        (field) => field.id === 'newspaper_id',
      );
      if (newspaperFieldIndex >= 0) {
        this.articleSourceFields[newspaperFieldIndex].options = this.newspapers;
      }
      this.newspapersObject = {};
      this.newspapers.forEach(({ value, label }) => {
        this.newspapersObject[value] = label;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  fetchAvailableLanguages = async () => {
    this.isLoading = true;
    try {
      const response = await API.get(
        apiRoutes.articleSources.availableLanguages,
      );
      this.availableLanguages = response.data.map(
        ({ code, label, service }) => ({
          value: code,
          label: `${label} (${service})`,
        }),
      );
      const langFieldIndex = this.articleSourceFields.findIndex(
        (field) => field.id === 'lang',
      );
      if (langFieldIndex >= 0) {
        this.articleSourceFields[langFieldIndex].options =
          this.availableLanguages;
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }
  };

  removeArticleSourceRequest = async (id) => {
    try {
      this.isLoading = true;
      await API.delete(apiRoutes.articleSourceRequests.requests(id));
    } catch (e) {
      toast.error('Something went wrong!');
      console.log(e);
    } finally {
      toast.success('Request successfully removed.');
      await this.fetchArticleSourceRequests({});
    }
  };

  createArticleSource = async (id) => {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      await API.post(apiRoutes.articleSourceRequests.comply(id));
    } catch (e) {
      toast.error('Something went wrong!');
      console.log(e);
    } finally {
      toast.success(
        'Article source is being created. It should be available in a few minutes.',
      );
      await this.fetchArticleSourceRequests({});
    }
  };

  // eslint-disable-next-line class-methods-use-this
  extractMeta = (meta, fetchType) => {
    if (fetchType === 'LEXIS_NEXIS') {
      return JSON.parse(meta).selector.where.source_code.$eq;
    }
    return null;
  };

  // eslint-disable-next-line class-methods-use-this
  formatMetaValue = (value) =>
    JSON.stringify({
      selector: {
        where: {
          source_code: {
            $eq: value,
          },
        },
      },
    });

  fetchAvailableLicensors = async () => {
    this.isLoadingAvailableLicensors = true;
    try {
      const response = await API.get(
        apiRoutes.articleSources.availableLicensors,
      );
      this.availableLicensors = response.data.filter((licensor) => !!licensor).map(
        (licensor) => ({ value: licensor, label: licensor})
      );
      const langFieldIndex = this.articleSourceFields.findIndex(
        (field) => field.id === 'licensor',
      );
      if (langFieldIndex >= 0) {
        this.articleSourceFields[langFieldIndex].options =
          this.availableLicensors;
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoadingAvailableLicensors = false;
    }
  };
}

export default new ArticleSourceRequestsStore();
