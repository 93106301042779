import styled from '@emotion/styled';

export const Color = styled.div`
  background-color: ${(params) => params.color};
  color: white;
  text-align: center;
  border-radius: 2px;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  padding: 5px 5px;
  margin-right: 10px;
  min-width: 70px;
`;

export const CompanyImage = styled.img`
  filter: invert(${({ darkMode }) => (darkMode ? 0 : 1)});
  height: 40px;
  max-width: 200px;
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.inputLabel};
  margin-bottom: 10px;
`;

export const ErrorMessage = styled.div`
  background-color: transparent;
  color: red;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  margin-bottom: 10px;
  text-transform: none;
`;
