import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Input from '@components/Input';
import Select from '@components/Select';
import useBreakpoint from '@root/hooks/useBreakpoint';
import Button from '@components/Button';
import Preloader from '@components/Preloader';
import { Flex, SelectWrapper } from '@containers/CompanyRelations/style';

const CompanyRelationsEditor = ({
  companyRelationsStore: {
    company,
    setCompany,
    relatedCompany,
    setRelatedCompany,
    relationType,
    setRelationType,
    relationId,
    notes,
    setNotes,
    companyOptionFields,
    fetchCompanyOptionFields,
    updateRelation,
    createRelation,
    fetchRelation,
    isLoadingRelation,
    isLoadingOptions,
    relationTypesObj,
    clearStore,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.SM);

  useEffect(() => {
    clearStore();
    const urlParams = new URLSearchParams(window.location.search);
    const paramsId = urlParams.get('id') || 0;
    const fetchData = async () => {
      await fetchCompanyOptionFields();
    };
    fetchData();

    if (paramsId !== 0) {
      const getRelation = async (id) => {
        await fetchRelation(id);
      };
      getRelation(paramsId);
    }
  }, []);

  const save = async () => {
    if (relationId) {
      await updateRelation();
    } else {
      await createRelation();
    }
  };

  if (isLoadingRelation || isLoadingOptions) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <Flex isMobile={isMobile}>
        <SelectWrapper>
          <Select
            label="Company"
            placeholder="Company"
            onChange={setCompany}
            value={company}
            options={companyOptionFields.companies}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            label="Related company"
            placeholder="Related company"
            onChange={setRelatedCompany}
            value={relatedCompany}
            options={companyOptionFields.relatedCompanies}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            label="Relation type"
            placeholder="Relation type"
            onChange={setRelationType}
            value={relationTypesObj[relationType]}
            options={companyOptionFields.relationTypes}
          />
        </SelectWrapper>
        <Input
          label="Notes"
          placeholder="Info..."
          onChange={setNotes}
          value={notes}
          maxWidth={300}
        />
        <div>
          <Button onClick={save}>{relationId ? 'Update' : 'Create'}</Button>
        </div>
      </Flex>
    </ContentWrapper>
  );
};

export default inject('companyRelationsStore')(
  observer(CompanyRelationsEditor),
);
