import React, { useMemo, useState } from 'react';

import { ChevronRight, ChevronLeft } from 'tabler-icons-react';
import { get } from 'lodash';
import {
  TableContainer,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  ActionButton,
  PaginationContainer,
  PaginationLabel,
  SelectContainer,
} from '@components/Table/style';
import Select from '@components/Select';
import useBreakpoint from '@root/hooks/useBreakpoint';
import MobileTable from '@components/MobileTable';

export const ActionsCell = ({ actions, row, column }) => (
  <TableCell
    className="actions"
    maxWidth={column.maxWidth}
    key={`table-cell-${column.id}-${row.id}`}
  >
    {actions.map(({ icon, label, onClick }, index) =>
      icon && icon(row) !== null ? (
        <ActionButton
          key={`action-${column.id}-${row.id}-${index}`}
          onClick={() => onClick(row)}
        >
          {icon ? icon(row) : label}
        </ActionButton>
      ) : null,
    )}
  </TableCell>
);

const Table = ({
  columns,
  data,
  actions,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  count,
  columnActions,
  big,
  disablePagination,
  mobileBig,
  hideMobileEdit,
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [columnsInTable, setColumnsInTable] = useState(
    columns
      .filter(({ hide }) => !hide)
      .map(({ id, label }) => ({ value: id, label })),
  );

  const filteredColumnsSet = useMemo(
    () => new Set(columnsInTable.map(({ value }) => value)),
    [columnsInTable],
  );

  const filteredColumns = isMobile
    ? columns
    : columns.filter(({ id }) => filteredColumnsSet.has(id));

  if (isMobile) {
    return (
      <MobileTable
        columns={columns}
        data={data}
        actions={actions}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        count={count}
        columnActions={columnActions}
        big={mobileBig}
        disablePagination={disablePagination}
        hidePencil={hideMobileEdit}
      />
    );
  }
  const maxPage = Math.floor(count / rowsPerPage);
  const pageStart = rowsPerPage * page;
  const pageEnd = page === maxPage ? count : rowsPerPage * (page + 1);

  return (
    <TableContainer>
      <TableHeader className={big ? 'big' : ''}>
        {filteredColumns.map(({ id, label, maxWidth, minWidth }) => (
          <TableHeaderCell maxWidth={maxWidth} minWidth={minWidth} key={id}>
            {label}
          </TableHeaderCell>
        ))}
      </TableHeader>
      {data && data.length && filteredColumns.length
        ? data.map((row) => (
            <TableRow className={big ? 'big' : ''} key={`row${row.id}`}>
              {filteredColumns.map((column) => {
                const value = get(row, column.id);
                if (column.id === 'actions' && actions) {
                  return (
                    <ActionsCell
                      key={`action-cell-${column.id}-${row.id}`}
                      actions={actions}
                      row={row}
                      column={column}
                    />
                  );
                }
                return (
                  <TableCell
                    className={column.className}
                    clickable={!!get(columnActions, column.id) || undefined}
                    maxWidth={column.maxWidth}
                    minWidth={column.minWidth}
                    align={column.align || 'flex-start'}
                    onClick={() =>
                      get(columnActions, column.id)
                        ? get(columnActions, column.id)(row)
                        : null
                    }
                    key={`table-cell-${column.id}-${row.id}`}
                  >
                    {column.format ? column.format(value, row) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))
        : null}
      {disablePagination ? null : (
        <PaginationContainer>
          <SelectContainer className="left">
            <Select
              isMulti
              placeholder="Show/hide columns"
              options={columns.map(({ id, label }) => ({ value: id, label }))}
              onChange={(values) => setColumnsInTable(values)}
              value={columnsInTable}
              disableSearch
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              closeMenuOnSelect={false}
            />
          </SelectContainer>
          <PaginationLabel>Rows per page:</PaginationLabel>
          <SelectContainer>
            <Select
              onChange={onChangeRowsPerPage}
              value={rowsPerPage}
              placeholder="Enabled"
              options={rowsPerPageOptions.map((value) => ({
                value,
                label: value,
              }))}
            />
          </SelectContainer>
          <PaginationLabel>
            {pageStart + 1}-{pageEnd} of {count}
          </PaginationLabel>
          <PaginationLabel
            className={page === 0 ? 'disabled' : 'cursor'}
            onClick={() => (page >= 1 ? onChangePage(page - 1) : null)}
          >
            <ChevronLeft />
          </PaginationLabel>
          <PaginationLabel
            className={page === maxPage ? 'disabled' : 'cursor'}
            onClick={() => (page !== maxPage ? onChangePage(page + 1) : null)}
          >
            <ChevronRight />
          </PaginationLabel>
        </PaginationContainer>
      )}
    </TableContainer>
  );
};

export default Table;
