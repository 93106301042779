import React from 'react';
import { inject, observer } from 'mobx-react';
import { ThemeProvider } from '@emotion/react';
import darkModeTheme from '@styles/darkMode';
import lightModeTheme from '@styles/lightMode';
import defaultTheme from '@styles/defaultTheme';

const defaults = {
  small: { defaults: defaultTheme.defaults },
  big: { defaults: defaultTheme.defaultsBig },
};

const ThemesProvider = ({ authStore: { darkMode, appSize }, children }) => {
  const darkTheme = { ...defaults[appSize], ...darkModeTheme };
  const lightTheme = { ...defaults[appSize], ...lightModeTheme };
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {children}
    </ThemeProvider>
  );
};

export default inject('authStore')(observer(ThemesProvider));
