import React from 'react';
import Select from 'react-select';
import {
  Container,
  Label,
  ErrorText,
  ReactSelectStyles,
} from '@components/Select/styles';

function Dropdown({
  onChange,
  options,
  label,
  error,
  placeholder,
  value,
  name,
  id,
  onBlur,
  disableSearch,
  defaultValue,
  classes,
  isMulti,
  collapsed,
  controlShouldRenderValue = true,
  hideSelectedOptions = true,
  isClearable = undefined,
  closeMenuOnSelect = true,
  isLoading,
  onMenuOpen,
}) {
  const localValue = isMulti ? value : options?.find((x) => x.value === value);
  const selectId = `dropdown_${name}-${id}`;
  return (
    <Container
      className={`custom-select ${classes}`}
      error={error}
      collapsed={collapsed}
    >
      <Label htmlFor={selectId}>{label}</Label>
      <Select
        id={selectId}
        options={options}
        name={name}
        classNamePrefix="react-select"
        onChange={(option) => {
          if (isMulti) {
            onChange(option);
            return;
          }
          if (isClearable && option === null) {
            onChange(option);
            return;
          }
          onChange(option.value);
        }}
        onBlur={onBlur}
        styles={ReactSelectStyles}
        defaultValue={defaultValue}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        placeholder={placeholder}
        value={localValue}
        isSearchable={!disableSearch}
        noBoarder
        isMulti={isMulti}
        menuPlacement="auto"
        controlShouldRenderValue={controlShouldRenderValue}
        hideSelectedOptions={hideSelectedOptions}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        isLoading={isLoading}
        onMenuOpen={onMenuOpen}
      />
      {error && error.message && (
        <ErrorText className="error-message">{error.message}</ErrorText>
      )}
    </Container>
  );
}

Dropdown.defaultProps = {
  placeholder: null,
  id: null,
  onBlur: null,
  disableSearch: false,
};

export default Dropdown;
