import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import routes from '@routes';
import {
  filters as filtersFunc,
  tableColumns,
} from '@containers/Users/constants';
import Button from '@components/Button';
import { ButtonContainer } from '@containers/Users/styles';
import TableDialog from '@components/TableDialog';

const Users = ({
  usersStore: {
    fetchUsers,
    users,
    isLoading,
    usersCount,
    fetchSubscriptionPlans,
    subscriptionPlans,
    isLoadingSubscriptionPlans,
    fetchContentOrders,
    contentOrders,
    isLoadingContentOrders,
    fetchContracts,
    contracts,
    isLoadingContracts,
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    values: [],
    dialogName: '',
  });
  const navigate = useNavigate();

  const columnActions = {
    id: (row) => navigate(`${routes.user}?id=${row.id}`),
    active_subscription: (row) => {
      setDialogParams({
        values: row.active_subscription,
        dialogName: `Active subscriptions for ${row.email}`,
      });
      setIsDialogOpen(true);
    },
    active_content_order: (row) => {
      setDialogParams({
        values: row.active_content_order,
        dialogName: `Active content orders for ${row.email}`,
      });
      setIsDialogOpen(true);
    },
  };

  useEffect(() => {
    clear();
    fetchUsers();
    fetchSubscriptionPlans();
    fetchContentOrders();
    fetchContracts();
  }, []);

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchUsers({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchUsers({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery({});
    await fetchUsers({
      limit: rowsPerPage,
      page: 0,
      searchQuery: {},
    });
  };

  const filters = filtersFunc(
    contentOrders,
    isLoadingContentOrders,
    contracts,
    isLoadingContracts,
    subscriptionPlans,
    isLoadingSubscriptionPlans,
  );

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <TableDialog
        closeHandler={() => {
          setIsDialogOpen(false);
        }}
        isOpen={isDialogOpen}
        dialogParams={dialogParams}
      />
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />
      <FiltersContainer>
        <ButtonContainer>
          <Button onClick={() => navigate(`${routes.userEditor}?create=true`)}>
            Create user
          </Button>
        </ButtonContainer>
      </FiltersContainer>
      <Table
        big
        rowsPerPageOptions={[10, 50, 100]}
        count={usersCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={users}
        columnActions={columnActions}
      />
    </ContentWrapper>
  );
};

export default inject('usersStore')(observer(Users));
