import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {
  ContentWrapper,
  OnlyMobilePaper,
  SettingsRow,
} from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import {
  CheckBoxContainer,
  SelectContainer,
} from '@containers/ArticleSourceRequests/style';
import Input from '@components/Input';
import Control from '@components/Control';
import Select from '@components/Select';
import Button from '@components/Button';
import { defaultValues } from '@containers/ArticleSourceRequests/constants';

const ArticleSourceRequestCreator = ({
  articleSourceRequestsStore: {
    articleSourceRequest,
    changeArticleSourceField,
    articleSourceFields,
    save,
    validationErrors,
    createNewspaper,
    newspaper,
    toggleCreateNewspaper,
    changeNewspaperField,
    fetchNewspapers,
    isLoading,
    fillArticleSourceRequestForEdit,
    clearArticleSourceRequestToEdit,
    fetchAvailableLanguages,
    fetchAvailableLicensors,
  },
}) => {
  useEffect(() => {
    fetchNewspapers();
    fetchAvailableLanguages();
    fetchAvailableLicensors();
    fillArticleSourceRequestForEdit(defaultValues);
    return clearArticleSourceRequestToEdit;
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <OnlyMobilePaper>
        {articleSourceFields.map(
          ({ label, id, type, options, isClearable }) => (
            <Fragment key={`edit-field-${id}`}>
              {type === 'input' && (
                <SettingsRow key={`container_${id}`}>
                  <Input
                    maxWidth={300}
                    key={`field_${id}`}
                    error={validationErrors[id]}
                    value={articleSourceRequest[id]}
                    onChange={(value) => {
                      changeArticleSourceField(id, value);
                    }}
                    label={label}
                    placeholder={label}
                  />
                </SettingsRow>
              )}
              {type === 'select' &&
                !(createNewspaper && id === 'newspaper_id') && (
                  <SettingsRow key={`container_${id}`}>
                    <SelectContainer>
                      <Select
                        error={validationErrors[id]}
                        value={articleSourceRequest[id]}
                        placeholder={label}
                        onChange={(newValue) => {
                          changeArticleSourceField(id, newValue);
                        }}
                        options={options}
                        label={label}
                        isClearable={isClearable}
                      />
                    </SelectContainer>
                  </SettingsRow>
                )}
              {type === 'checkbox' && (
                <SettingsRow key={`container_${id}`} className="no-margin">
                  <CheckBoxContainer>
                    <Control
                      name={id}
                      value={articleSourceRequest[id]}
                      checked={articleSourceRequest[id]}
                      onChange={() => {
                        changeArticleSourceField(id, !articleSourceRequest[id]);
                      }}
                      type="checkbox"
                      label={label}
                    />
                  </CheckBoxContainer>
                </SettingsRow>
              )}
            </Fragment>
          ),
        )}
        <SettingsRow key="container-newspapers">
          <CheckBoxContainer>
            <Control
              name="create_newspaper"
              checked={createNewspaper}
              value={createNewspaper}
              onChange={(event) => toggleCreateNewspaper(event.target.checked)}
              type="checkbox"
              label="Create newspaper"
            />
          </CheckBoxContainer>
        </SettingsRow>
        {createNewspaper && (
          <SettingsRow key="container-create-newspaper">
            <Input
              key="newspaper_title"
              maxWidth={300}
              error={validationErrors.newspaper_title}
              value={newspaper.title}
              onChange={(value) => changeNewspaperField('title', value)}
              label="Newspaper title"
              placeholder="Newspaper title..."
            />
          </SettingsRow>
        )}
        <SettingsRow>
          <Button onClick={save}>Save</Button>
        </SettingsRow>
      </OnlyMobilePaper>
    </ContentWrapper>
  );
};

export default inject('articleSourceRequestsStore')(
  observer(ArticleSourceRequestCreator),
);
