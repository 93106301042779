export const passwordValidator = (value) => {
  if (value.length < 6) return 'Password needs to be 6 characters or longer.';
  if (value.match(new RegExp(/[a-z]/)) === null)
    return 'Password needs to contain at least one lower case character (a-z).';
  if (value.match(new RegExp(/[A-Z]/)) === null)
    return 'Password needs to contain at least one upper case character (A-Z).';
  if (value.match(new RegExp(/\d/)) === null)
    return 'Password needs to contain at least one number (0-9).';
  if (
    value.match(new RegExp(/[`~!@#$%^&*()\-_=+[{\]};:'"\\|,<.>/?]/)) === null
  ) {
    return 'Password needs to contain at least one special character (e.g. !, @, & or #).';
  }
  if (value.match(new RegExp(/\s/)) !== null)
    return "Password can't contain whitespace";
  return undefined;
};
