import React, { useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import {
  ContentWrapper,
  SettingsRow,
  StyledTooltip,
  SubTitle,
} from '@styles/globalStyles';
import Preloader from '@components/Preloader';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Details from '@components/Details';
import Text, { TAGS } from '@components/Text';
import Button from '@components/Button';
import routes from '@routes';
import { InfoCircleIcon } from '@containers/BriefingTypes/style';
import useBreakpoint from '@root/hooks/useBreakpoint';
import DetailsMobile from '@components/DetailsMobile';
import { fields, metaFields } from '@containers/BriefingTypes/constants';

const BriefingType = ({
  briefingsStore: {
    fetchBriefingType,
    briefingType,
    isLoading,
    followByAllUsers,
    enableNotificationsForAllUsers,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();
  const disableActionButtons =
    briefingType && briefingType.created_at
      ? moment().diff(moment(briefingType.created_at), 'minutes') <= 10
      : true;

  useEffect(() => {
    if (search.id) {
      fetchBriefingType(search.id);
    }
  }, []);

  if (isLoading && !briefingType) {
    return <Preloader overlay />;
  }

  if (!briefingType) {
    if (search.id) {
      fetchBriefingType(search.id);
    }
  }

  const meta = useMemo(() => briefingType.meta, [briefingType]);

  return (
    <ContentWrapper>
      <StyledTooltip />
      <SettingsRow className="small-margin">
        <Button
          disabled={disableActionButtons}
          onClick={() => followByAllUsers(briefingType.id)}
        >
          Follow by all users
        </Button>
        <Button
          disabled={disableActionButtons}
          onClick={() => enableNotificationsForAllUsers(briefingType.id)}
        >
          {isMobile
            ? 'Enable notifications'
            : 'Enable notifications for all users'}
        </Button>
        {isMobile ? null : (
          <InfoCircleIcon
            size={22}
            data-tip="These actions include all users with enabled briefings. You can use them after 10 minutes from briefing type creation"
            data-place="bottom"
          />
        )}
        <Button
          classes={isMobile ? 'right' : 'no-min right'}
          onClick={() =>
            navigate(`${routes.briefingTypeCreator}?id=${briefingType.id}`)
          }
        >
          Edit
        </Button>
      </SettingsRow>
      {isMobile ? (
        <DetailsMobile data={briefingType} fields={fields} />
      ) : (
        <Details data={briefingType} fields={fields} />
      )}
      <SubTitle>
        <Text tag={TAGS.H2}>Meta</Text>
      </SubTitle>
      {meta && !isMobile && <Details data={meta} fields={metaFields} />}
      {meta && isMobile && <DetailsMobile data={meta} fields={metaFields} />}
    </ContentWrapper>
  );
};

export default inject('briefingsStore')(observer(BriefingType));
