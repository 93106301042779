import React, { useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import {
  ContentWrapper,
  Paper,
  SettingsRow,
} from '@styles/globalStyles';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import Preloader from '@components/Preloader';
import Input from '@components/Input';
import Button from '@components/Button';
import routes from '@routes';
import Select from '@components/Select';
import { editorFields } from '@containers/UserInvitations/constants';

export const SelectContainer = styled.div`
  width: 500px;
`;

const UserInvitationsCreator = ({
  userInvitationsStore: {
    userInvitation,
    isLoading,
    validationErrors,
    setUserInvitation,
    createUserInvitation,
    clearUserInvitation,
  },
}) => {
  const { search } = useCustomSearchParams();
  const navigate = useNavigate();

  const cancelRoute = `${routes.userInvitations}?id=${search.id}`;

  useEffect(() => {
    setUserInvitation({
      content_order_id: search.id,
      emails: '',
      nla_password: '',
      send_welcome_email: false
    });

    return () => clearUserInvitation();
  }, []);

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <Paper>
        {editorFields.map(
          ({
            id,
            label,
            component,
            type,
            options,
          }) => (
            <Fragment key={`edit-field-${id}`}>
              {component === 'input' && (
                <SettingsRow>
                  <Input
                    error={validationErrors[id]}
                    maxWidth={500}
                    value={userInvitation[id] || ''}
                    onChange={(value) => setUserInvitation({ [id]: value })}
                    label={label}
                    placeholder={`${label}...`}
                    inputType={type}
                  />
                </SettingsRow>
              )}
              {component === 'select' && (
                <SettingsRow>
                  <SelectContainer>
                    <Select
                      label={label}
                      placeholder={`${label}...`}
                      onChange={(value) => setUserInvitation({ [id]: value })}
                      value={userInvitation[id]}
                      options={options}
                    />
                  </SelectContainer>
                </SettingsRow>
              )}
            </Fragment>
          ),
        )}
      </Paper>
      <SettingsRow>
        <Button onClick={() => createUserInvitation()}>Save</Button>
        <Button onClick={() => navigate(cancelRoute)}>Cancel</Button>
      </SettingsRow>
    </ContentWrapper>
  );
};

export default inject('userInvitationsStore')(observer(UserInvitationsCreator));
