import React, { useEffect, useMemo, useState } from 'react';

import { inject, observer } from 'mobx-react';
import Input from '@components/Input';
import { ButtonContainer } from '@containers/Wraps/style';
import {
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@components/Table/style';
import Preloader from '@components/Preloader';
import Button from '@components/Button';
import { ContentWrapper, FiltersContainer } from '@styles/globalStyles';
import Select from '@components/Select';
import ColorPicker from '@components/ColorPicker';
import { ActionsCell } from '@components/Table';
import {
  bulkCreateActions,
  bulkCreateColumns,
} from '@containers/Companies/constants';

const companyFields = {
  name: '',
  url: '',
  color: '#000000',
  ticker: '',
  companyTypeId: '',
  sectorId: '',
};

const companyErrors = {
  name: false,
  sectorId: false,
  companyTypeId: false,
  ticker: false,
  url: false,
};

function CompaniesCreator({
  companiesStore: {
    sectors,
    companyTypes,
    createCompanies,
    errors,
    setErrors,
    isLoadingCompanyTypes,
    isLoadingSectors,
    isLoading,
    serverError,
    setCompaniesToCreate,
    companiesToCreate,
    fetchSectors,
    fetchCompanyTypes,
  },
}) {
  const [rows, setRows] = useState(0);
  const [rowsInput, setRowsInput] = useState(0);
  const [result, setResult] = useState([]);

  useEffect(() => {
    setCompaniesToCreate([]);
    fetchSectors();
    fetchCompanyTypes();
  }, []);

  useEffect(() => {
    const emptyCompanies = [];
    const emptyErrors = [];
    [...Array(rows)].forEach((e, i) => {
      const emptyCompany = JSON.parse(JSON.stringify(companyFields));
      if (companiesToCreate[i]) {
        emptyCompanies.push(companiesToCreate[i]);
      } else {
        emptyCompanies.push(emptyCompany);
      }
      emptyErrors.push(JSON.parse(JSON.stringify(companyErrors)));
    });
    setResult(emptyCompanies);
    setErrors(emptyErrors);
    setRowsInput(rows);
  }, [rows]);

  const columns = useMemo(
    () => bulkCreateColumns(sectors, companyTypes),
    [sectors, companyTypes],
  );

  const changeField = (index, value, field) => {
    const newArray = [...result];
    newArray[index][field] = value;
    setResult(newArray);
    setCompaniesToCreate(newArray);
  };

  const removeRow = (indexToRemove) => {
    const filtered = result.filter((_, index) => index !== indexToRemove);
    setCompaniesToCreate(filtered);
    setRows(rows - 1);
  };

  const actions = bulkCreateActions(removeRow);

  return (
    <ContentWrapper>
      <FiltersContainer style={{ marginBottom: 20 }}>
        <Input
          onChange={(value) => setRowsInput(parseInt(value, 10) || '')}
          value={rowsInput}
          maxWidth={250}
          label="Number of companies"
          placeholder="Number of companies to create"
        />
        <ButtonContainer>
          <Button onClick={() => setRows(rowsInput)}>Add rows</Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button onClick={() => createCompanies(result)}>Save</Button>
        </ButtonContainer>
        {serverError !== '' && (
          <div style={{ color: 'red', margin: 8, fontSize: 16 }}>
            {serverError}
          </div>
        )}
      </FiltersContainer>
      {((isLoadingSectors && isLoadingCompanyTypes) || isLoading) && (
        <Preloader overlay />
      )}
      {sectors &&
        companyTypes &&
        sectors.length > 0 &&
        companyTypes.length > 0 && (
          <TableContainer>
            <TableHeader>
              {columns.map(({ id, label, maxWidth, minWidth }) => (
                <TableHeaderCell
                  maxWidth={maxWidth}
                  minWidth={minWidth}
                  key={id}
                >
                  {label}
                </TableHeaderCell>
              ))}
            </TableHeader>
            {result.map((item, i) => (
              <TableRow id={i}>
                {columns.map((column) => {
                  if (column.id === 'actions' && actions) {
                    return (
                      <ActionsCell actions={actions} row={i} column={column} />
                    );
                  }
                  return (
                    <TableCell className="right-padding">
                      {column.type === 'color' && (
                        <ColorPicker
                          color={result[i].color}
                          onChange={(color) => changeField(i, color, column.id)}
                        />
                      )}
                      {column.type === 'input' && (
                        <Input
                          noMargin
                          inputType="table"
                          value={result[i][column.id]}
                          error={errors[i][column.id]}
                          id={`${i}-${column.id}`}
                          onChange={(value) => changeField(i, value, column.id)}
                        />
                      )}
                      {column.type === 'select' && (
                        <Select
                          classes="no-margin"
                          options={column.selectOptions.map(
                            ({ id: value, name: label }) => ({ value, label }),
                          )}
                          value={result[i][column.id]}
                          error={errors[i][column.id]}
                          onChange={(value) => changeField(i, value, column.id)}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableContainer>
        )}
    </ContentWrapper>
  );
}
export default inject('companiesStore')(observer(CompaniesCreator));
