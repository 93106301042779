import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import routes from '@routes';
import {
  filtersFunc,
  tableColumns,
} from '@containers/ArticleSources/constants';

const ArticleSources = ({
  articleSourcesStore: {
    fetchArticleSources,
    articleSources,
    isLoading,
    articleSourcesCount,
    fetchNewspapers,
    newspapers,
    isLoadingNewspapers,
    fetchAvailableLicensors,
    availableLicensors,
    isLoadingAvailableLicensors
  },
}) => {
  const [page, setPage] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const columnActions = {
    id: (row) => navigate(`${routes.articleSource}?id=${row.id}`),
  };

  useEffect(() => {
    clear();
    fetchNewspapers();
    fetchAvailableLicensors();
  }, []);

  const filters = useMemo(
    () => filtersFunc(newspapers, isLoadingNewspapers, availableLicensors, isLoadingAvailableLicensors),
    [newspapers, isLoadingNewspapers, availableLicensors, isLoadingAvailableLicensors],
  );

  const handleChangePage = async (newPage) => {
    setPage(newPage);
    await fetchArticleSources({
      limit: rowsPerPage,
      page: newPage,
      searchQuery,
    });
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
    await fetchArticleSources({
      limit: value,
      page: 0,
      searchQuery,
    });
  };

  const clear = async () => {
    setSearchQuery({});
    await fetchArticleSources({
      limit: rowsPerPage,
      page: 0,
      searchQuery: {},
    });
  };

  return (
    <ContentWrapper>
      {isLoading && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => handleChangePage(0)}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
      />
      <Table
        rowsPerPageOptions={[10, 50, 100]}
        count={articleSourcesCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={articleSources}
        columnActions={columnActions}
      />
    </ContentWrapper>
  );
};

export default inject('articleSourcesStore')(observer(ArticleSources));
