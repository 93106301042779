import styled from '@emotion/styled';

export const Label = styled.div`
  display: flex;
  font-weight: bold;
  text-transform: capitalize;
  color: ${({ theme }) => theme.font.default};
  margin-bottom: 8px;
`;

export const Value = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  min-height: 20px;
  max-height: 150px;
  overflow-y: auto;
  color: ${({ theme }) => theme.font.default};
  font-weight: normal;

  &.add-line-height {
    line-height: 20px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  cursor: ${({ clickable }) => (clickable ? `pointer` : '')};
  margin: 5px 0 16px 0;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  //height: ${({ big }) => (big ? '50px' : '30px')};
  //align-items: center;
`;
