import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import routerStore from '@root/stores/routerStore';
import routes from '@routes';

export class ArticleSourcesStore {
  isLoading = false;

  articleSources = [];

  articleSourcesCount = 0;

  newspapers = [];

  isLoadingNewspapers = false;

  articleSource = {};

  isLoadingArticleSource = false;

  validationErrors = {};

  availableLanguages = [];

  isLoadingAvailableLanguages = false;

  availableLanguagesObj = {};

  availableLicensors = []

  isLoadingAvailableLicensors = false;


  constructor() {
    makeAutoObservable(this);
  }

  fetchArticleSources = async ({ limit, page, searchQuery }) => {
    this.isLoading = true;

    const query = {
      limit:
        limit !== 'all' ? parseInt(limit ? limit.toString() : 10, 10) : 'all',
      skip: parseInt(page ? (page * limit).toString() : 0, 10),
      ...searchQuery,
    };

    try {
      const response = await API.get(apiRoutes.articleSources.index, {
        params: query,
      });
      runInAction(() => {
        this.articleSources = response.data.article_sources;
        this.articleSourcesCount = response.data.article_sources_count;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchNewspapers = async () => {
    this.isLoadingNewspapers = true;
    try {
      const response = await API.get(
        apiRoutes.articleSourceRequests.newspapers,
      );
      this.newspapers = response.data.newspapers.map(({ id, title }) => ({
        value: id,
        label: `${title} (${id})`,
      }));
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoadingNewspapers = false;
    }
  };

  fetchAvailableLanguages = async () => {
    this.isLoadingAvailableLanguages = true;
    try {
      const response = await API.get(
        apiRoutes.articleSources.availableLanguages,
      );
      this.availableLanguages = response.data.map(
        ({ code, label, service }) => {
          const name = `${label} (${service})`;
          this.availableLanguagesObj[code] = name;
          return {
            value: code,
            label: name,
          };
        },
      );
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoadingAvailableLanguages = false;
    }
  };

  fetchAvailableLicensors = async () => {
    this.isLoadingAvailableLicensors = true;
    try {
      const response = await API.get(
        apiRoutes.articleSources.availableLicensors,
      );
      this.availableLicensors = response.data.map(
        (licensor) => ({ value: licensor, label: licensor})
      );
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoadingAvailableLicensors = false;
    }
  };

  fetchArticleSource = async (id) => {
    this.isLoadingArticleSource = true;
    try {
      const response = await API.get(apiRoutes.articleSources.show(id));
      runInAction(() => {
        this.articleSource = response.data.article_source;
      });
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoadingArticleSource = false;
    }
  };

  setArticleSource = (valueObj) => {
    this.articleSource = { ...this.articleSource, ...valueObj };
  };

  validateArticleSource = () => {
    this.validationErrors.title =
      this.articleSource.title === null || this.articleSource.title.length === 0
        ? 'Title should not be empty'
        : undefined;

    this.validationErrors.display_title =
      this.articleSource.display_title === null ||
      this.articleSource.display_title.length === 0
        ? 'Display title should not be empty'
        : undefined;

    this.validationErrors.url =
      this.articleSource.url === null || this.articleSource.url.length === 0
        ? 'Url should not be empty'
        : undefined;

    this.validationErrors.url_for_analysts =
      this.articleSource.url_for_analysts === null ||
      this.articleSource.url_for_analysts.length === 0
        ? 'Url for analyst should not be empty'
        : undefined;

    this.validationErrors.schedule_expresion =
      this.articleSource.schedule_expression === null ||
      this.articleSource.schedule_expression.length === 0
        ? 'Schedule expression should not be empty'
        : undefined;

    this.validationErrors.position =
      this.articleSource.position === null ||
      this.articleSource.position === undefined
        ? 'Position should not be empty'
        : undefined;

    this.validationErrors.weekday_warning_time_in_hours =
      this.articleSource.weekday_warning_time_in_hours === null ||
      this.articleSource.weekday_warning_time_in_hours === undefined
        ? 'Time should not be empty'
        : undefined;

    this.validationErrors.weekend_warning_time_in_hours =
      this.articleSource.weekend_warning_time_in_hours === null ||
      this.articleSource.weekend_warning_time_in_hours === undefined
        ? 'Time should not be empty'
        : undefined;

    this.validationErrors.weekday_error_time_in_hours =
      this.articleSource.weekday_error_time_in_hours === null ||
      this.articleSource.weekday_error_time_in_hours === undefined
        ? 'Time should not be empty'
        : undefined;

    this.validationErrors.weekend_error_time_in_hours =
      this.articleSource.weekend_error_time_in_hours === null ||
      this.articleSource.weekend_error_time_in_hours === undefined
        ? 'Time should not be empty'
        : undefined;

    this.validationErrors.fetch_type =
      this.articleSource.fetch_type === null ||
      this.articleSource.fetch_type.length === 0
        ? 'Fetch type should not be empty'
        : undefined;
  };

  updateArticleSource = async () => {
    this.isLoading = true;
    this.validateArticleSource();
    if (Object.values(this.validationErrors).filter((e) => !!e).length > 0) {
      this.isLoading = false;
      return;
    }

    try {
      const response = await API.patch(
        apiRoutes.articleSources.show(this.articleSource.id),
        {
          article_source: this.articleSource,
        },
      );
      if (response.data.message) {
        toast.error(response.data.message);
      } else {
        routerStore.push(`${routes.articleSource}?id=${this.articleSource.id}`);
        toast.success('Article source successfully edited!');
      }
    } catch (e) {
      this.error = e;
      console.log(e);
      toast.error('Something went wrong!');
    } finally {
      this.isLoading = false;
    }

    this.isLoading = false;
  };
}

export default new ArticleSourcesStore();
