import React from 'react';
import Dialog from '@components/Modal/Dialog';
import {
  Buttons,
  ContentDialog,
  Question,
} from '@components/ConfirmationDialog/styles';
import Button, { BUTTON_THEMES } from '@components/Button';

const ConfirmationDialog = ({
  onConfirmation,
  isDialogOpen,
  setIsDialogOpen,
  dialogText,
  rowId,
}) => (
  <Dialog
    isOpen={isDialogOpen}
    closeHandler={() => {
      setIsDialogOpen(false);
    }}
    smallModal
  >
    <ContentDialog>
      <Question>{dialogText}</Question>
      <Buttons>
        <Button
          theme={BUTTON_THEMES.PRIMARY}
          onClick={() => onConfirmation(rowId)}
        >
          Confirm
        </Button>
        <Button
          theme={BUTTON_THEMES.SECONDARY}
          onClick={() => setIsDialogOpen(false)}
        >
          Cancel
        </Button>
      </Buttons>
    </ContentDialog>
  </Dialog>
);

export default ConfirmationDialog;
