import React, { useState } from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import FlipMove from 'react-flip-move';
import { observer, inject } from 'mobx-react';
import WrapElement from '@containers/Wraps/components/WrapElement';

const DndWrapElementsList = ({ wrapsStore }) => {
  const { wrapElements, moveWrapElement } = wrapsStore;

  const [dragging, setDragging] = useState(false);

  const onDragEnd = (event) => {
    moveWrapElement(event.source.index, event.destination.index);
    setTimeout(() => setDragging(false), 200);
  };

  return (
    <>
      <DragDropContext
        onBeforeDragStart={() => setDragging(true)}
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="0">
          {(p) => (
            <div {...p.droppableProps} ref={p.innerRef}>
              <FlipMove disableAllAnimations={dragging}>
                {wrapElements &&
                  wrapElements.map((wrapElement, i) => (
                    <WrapElement
                      key={wrapElement.id}
                      wrapElement={wrapElement}
                      index={i}
                    />
                  ))}
              </FlipMove>
              {p.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default inject('wrapsStore')(observer(DndWrapElementsList));
