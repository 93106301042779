import React from 'react';
import PropTypes from 'prop-types';
import Swipe from 'react-easy-swipe';
import { inject, observer } from 'mobx-react';
import Navbar from '@components/Navbar';
// import { history } from '@app/history';
import { Content, MainLayout, Wrapper } from '@components/Layout/style';

import useBreakpoint from '@root/hooks/useBreakpoint';
import SideBar from '@components/SideBar';

const Layout = ({
  children,
  mobileLabel,
  // navBarleftSide,
  sidebarStore: { setMobileMenuOpened, mobileMenuOpened },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const swipeStyle = {
    width: '100%',
    overflowX: 'hidden',
    minHeight: '100vh',
    height: isMobile ? '100%' : 'inherit',
  };

  const handleMenuOpen = () => {
    setMobileMenuOpened(!mobileMenuOpened);
    if (!window.history.state.menu) {
      window.history.pushState({ menu: !mobileMenuOpened }, '', '');
    }
  };

  if (mobileMenuOpened) {
    window.onpopstate = (event) => {
      if (event) {
        setMobileMenuOpened(false);
      }
    };
  }

  return (
    <MainLayout>
      <SideBar mobileLabel={mobileLabel} />
      <Swipe
        onSwipeRight={() => isMobile && handleMenuOpen()}
        style={swipeStyle}
        tolerance={120}
      >
        <Wrapper>
          {!isMobile ? <Navbar /> : null}

          <Content>{children}</Content>
          <div id="content-portal" />
        </Wrapper>
      </Swipe>
    </MainLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  mobileLabel: PropTypes.string,
  // navBarleftSide: PropTypes.oneOfType([
  //   PropTypes.arrayOf(PropTypes.node),
  //   PropTypes.node,
  // ]),
  sidebarStore: PropTypes.shape({
    setMobileMenuOpened: PropTypes.func.isRequired,
  }).isRequired,
};

Layout.defaultProps = {
  // navBarleftSide: null,
  mobileLabel: null,
};

export default inject('sidebarStore')(observer(Layout));
