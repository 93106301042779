import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ContentWrapper } from '@styles/globalStyles';
import {
  SelectContainer,
  Flex,
  ButtonContainer,
} from '@containers/SwitchArticleSources/style';
import Select from '@components/Select';
import Button from '@components/Button';
import Preloader from '@components/Preloader';
import useBreakpoint from '@root/hooks/useBreakpoint';

const INITIAL_LABEL = 'all';

const SwitchArticleSources = ({
  switchArticleSourcesStore: {
    isLoading,
    prepareSelectOptions,
    selectArticleSources,
    createJob,
    delayedJobId,
    delayedJobStatus,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.LG);
  const isMobileMD = matchBreakpoint(BREAKPOINTS.MD);
  const [switchFrom, setSwitchFrom] = useState(INITIAL_LABEL);
  const [switchTo, setSwitchTo] = useState(INITIAL_LABEL);

  useEffect(() => {
    const prepareLabels = async () => {
      await prepareSelectOptions();
    };
    prepareLabels();
  }, []);

  const handelSourceChange = () => {
    const getArticleLabel = (id) =>
      selectArticleSources.find((art) => art.value === id)?.label;

    if (
      switchFrom === INITIAL_LABEL ||
      switchTo === INITIAL_LABEL ||
      !switchFrom ||
      !switchTo
    ) {
      toast.error('Please complete both fields.');
      return;
    }

    if (switchFrom === switchTo) {
      toast.error("You can't change source for this same article!");
      return;
    }

    createJob({
      sourceArticleSourceId: switchFrom,
      destArticleSourceId: switchTo,
    }).then(() => {
      if (delayedJobId) {
        toast.success(
          `Sources successfully changed! [From: ${getArticleLabel(
            switchFrom,
          )}, To: ${getArticleLabel(switchTo)}]`,
        );
      }
    });
  };

  if (isLoading) {
    return <Preloader overlay />;
  }

  return (
    <ContentWrapper>
      <Flex isMobile={isMobile}>
        <SelectContainer isMobile={isMobileMD}>
          <Select
            label="From"
            placeholder="From"
            onChange={setSwitchFrom}
            value={switchFrom}
            options={selectArticleSources}
          />
        </SelectContainer>
        <SelectContainer isMobile={isMobileMD}>
          <Select
            label="To"
            placeholder="To"
            onChange={setSwitchTo}
            value={switchTo}
            options={selectArticleSources}
          />
        </SelectContainer>
        <ButtonContainer isMobile={isMobile}>
          <Button classes="no-min" onClick={handelSourceChange}>
            Change
          </Button>
        </ButtonContainer>
      </Flex>
      <Flex isMobile={isMobile}>
        {delayedJobStatus && `Status: ${delayedJobStatus}`}
      </Flex>
    </ContentWrapper>
  );
};

export default inject('switchArticleSourcesStore')(
  observer(SwitchArticleSources),
);
