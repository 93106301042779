import styled from '@emotion/styled';

export const SelectWrapper = styled.div`
  max-width: 300px;
  flex-basis: 30%;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'space-between')};
  flex-direction: column;
`;
