import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ClipboardText, ClipboardCheck } from 'tabler-icons-react';
import {
  StyledTooltip,
} from '@styles/globalStyles';
import {
  Container,
  Text,
} from '@components/CopiableText/styles';
import { copyToClipboard } from '../../utils/clipboard';

function CopiableText({
  value,
}) {
  const [clipboardChecked, setClipboardChecked] = useState(false);

  useEffect(() => {
    setClipboardChecked(false);
  }, [value]);

  const copyContents = async (event) => {
    const container = event.currentTarget;
    if (container !== null) {
      window.getSelection().selectAllChildren(container);
      await copyToClipboard(value);
      container.classList.add('done');
      setClipboardChecked(true);
    }
  };

  return (
    <Container
      onClick={copyContents}
      data-tip="Click to copy to clipboard"
      data-place="bottom"
    >
      <StyledTooltip />
      <Text>
        {value}
      </Text>
      { !clipboardChecked ? (
        <ClipboardText />
      ) : (
        <ClipboardCheck />
      )}
    </Container>
  );
}

CopiableText.propTypes = {
  value: PropTypes.string,
};

CopiableText.defaultProps = {
  value: '',
};

export default CopiableText;
