import React from 'react';
import ReactJson from 'react-json-view';
import { JsonContainer, Label } from '@components/JSONInput/styles';

const JSONInput = ({ label, value, onChange }) => (
  <>
    <Label>{label}</Label>
    <JsonContainer>
      <ReactJson
        src={value}
        onEdit={({ updated_src: newValue }) => onChange(newValue)}
        onAdd={({ updated_src: newValue }) => onChange(newValue)}
        onDelete={({ updated_src: newValue }) => onChange(newValue)}
      />
    </JsonContainer>
  </>
);

export default JSONInput;
