const darkModeTheme = {
  colors: {
    primary: '#29235C',
    secondary: '#6793FF',
    tertiary: '#544F7D',
    quaternary: '#5276CC',
    quinary: '#333436',
    mainBackground: '#191919',
    navbar: '#242527',
    sidebar: '#333436',
    disabled: '#C5C2C8',
    company: '#232323',
    companyButton: '#232323',
    companyButtonHover: '#333436',
    white: '#FFFFFF',
    content: '#232323',
    inputLabel: '#FFFFFF',
    error: '#E5005C',
    400: '#544F7D',
    900: '#080712',
    follow: '#6793FF',
    unfollow: '#E5005C',
  },
  font: {
    default: '#FFFFFF',
    secondary: '#FFFFFF',
    tertiary: '#FFFFFF',
    dark: '#FFFFFF',
  },
  control: {
    background: '#333436',
    center: '#6793FF',
  },
  opacity: {
    preloader: 0.3,
  },
  scroll: {
    default: '#544F7D',
    secondary: '#5276CC',
  },
  border: {
    default: '#C2D4FF',
    dark: '#FFFFFF',
  },
  imageFilter: {
    invert: 0,
    preloaderBrightness: 2.5,
  },
  buttons: {
    primary: {
      background: '#6793FF',
      textColor: '#FFFFFF',
      borderColor: '#6793FF',
      hover: '#85a5f2',
    },
    secondary: {
      background: '#FFFFFF',
      textColor: '#6793FF',
      borderColor: '#6793FF',
      hover: '#85a5f2',
    },
    tertiary: {
      background: '#29235C',
      textColor: '#FFFFFF',
      borderColor: '#29235C',
      hover: 'rgba(255, 255, 255, 0.75)',
    },
    quaternary: {
      background: '#EB1D66',
      textColor: '#FFFFFF',
      borderColor: '#EB1D66',
      hover: 'rgba(255, 255, 255, 0.75)',
    },
  },
  icons: {
    default: '#FFFFFF',
    hover: '#444444',
    primary: {
      color: '#6793FF',
    },
  },
  inputs: {
    primary: {
      default: '#333436',
      background: '#333436',
      borderColor: '#6793FF',
      placeholder: '#7F7B9D',
      icon: '#6793FF',
      text: '#FFFFFF',
    },
    secondary: {
      default: 'transparent',
      background: 'transparent',
      borderColor: '#6793FF',
      placeholder: '#7F7B9D',
      icon: '#6793FF',
      text: '#FFFFFF',
    },
  },
  datepicker: {
    dayHover: '#6793FFb8',
    dayHoverText: '#FFFFFF',
    active: '#6793FF',
    selected: '#6793FF',
  },
  box: {
    default: {
      background: '#242527',
    },
  },
};

export default darkModeTheme;
